import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/view/pages/Dashboard.vue')
        },
        {
          path: '/villas',
          name: 'villas',
          component: () => import('@/view/pages/villas/Villas'),
          children: [
            {
              path: 'list',
              name: 'villas-list',
              component: () => import('@/view/pages/villas/List.vue')
            },
            {
              path: 'new',
              name: 'villas-new',
              component: () => import('@/view/pages/villas/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'villas-edit',
              component: () => import('@/view/pages/villas/Edit.vue')
            },
            {
              path: 'edit/:id/translations',
              name: 'villas-edit-translations',
              component: () => import('@/view/pages/villas/Translate.vue')
            }
          ]
        },
        {
          path: '/posts',
          name: 'posts',
          component: () => import('@/view/pages/posts/Posts'),
          children: [
            {
              path: 'list',
              name: 'posts-list',
              component: () => import('@/view/pages/posts/List.vue')
            },
            {
              path: 'new',
              name: 'posts-new',
              component: () => import('@/view/pages/posts/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'posts-edit',
              component: () => import('@/view/pages/posts/Edit.vue')
            },
            {
              path: 'edit/:id/translations',
              name: 'posts-edit-translations',
              component: () => import('@/view/pages/posts/Translate.vue')
            }
          ]
        },
        {
          path: '/experiences',
          name: 'experiences',
          component: () => import('@/view/pages/experiences/Experiences'),
          children: [
            {
              path: 'list',
              name: 'experiences-list',
              component: () => import('@/view/pages/experiences/List.vue')
            },
            {
              path: 'new',
              name: 'experiences-new',
              component: () => import('@/view/pages/experiences/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'experiences-edit',
              component: () => import('@/view/pages/experiences/Edit.vue')
            },
            {
              path: 'edit/:id/translations',
              name: 'experiences-edit-translations',
              component: () => import('@/view/pages/experiences/Translate.vue')
            }
          ]
        },
        {
          path: '/regions',
          name: 'regions',
          component: () => import('@/view/pages/regions/Regions'),
          children: [
            {
              path: 'list',
              name: 'regions-list',
              component: () => import('@/view/pages/regions/List.vue')
            },
            {
              path: 'new',
              name: 'regions-new',
              component: () => import('@/view/pages/regions/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'regions-edit',
              component: () => import('@/view/pages/regions/Edit.vue')
            },
            {
              path: 'edit/:id/translations',
              name: 'regions-edit-translations',
              component: () => import('@/view/pages/regions/Translate.vue')
            }
          ]
        },
        {
          path: '/categories',
          name: 'categories',
          component: () =>
            import('@/view/pages/taxonomies/categories/Categories'),
          children: [
            {
              path: 'list',
              name: 'categories-list',
              component: () =>
                import('@/view/pages/taxonomies/categories/List.vue')
            },
            {
              path: 'new',
              name: 'categories-new',
              component: () =>
                import('@/view/pages/taxonomies/categories/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'categories-edit',
              component: () =>
                import('@/view/pages/taxonomies/categories/Edit.vue')
            },
            {
              path: 'edit/:id/translations',
              name: 'categories-edit-translations',
              component: () =>
                import('@/view/pages/taxonomies/categories/Translate.vue')
            }
          ]
        },
        {
          path: '/tags',
          name: 'tags',
          component: () => import('@/view/pages/taxonomies/tags/Tags'),
          children: [
            {
              path: 'list',
              name: 'tags-list',
              component: () => import('@/view/pages/taxonomies/tags/List.vue')
            },
            {
              path: 'new',
              name: 'tags-new',
              component: () => import('@/view/pages/taxonomies/tags/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'tags-edit',
              component: () => import('@/view/pages/taxonomies/tags/Edit.vue')
            },
            {
              path: 'edit/:id/translations',
              name: 'tags-edit-translations',
              component: () =>
                import('@/view/pages/taxonomies/tags/Translate.vue')
            }
          ]
        },
        {
          path: '/users',
          name: 'users',
          component: () => import('@/view/pages/users/Users'),
          children: [
            {
              path: 'list',
              name: 'users-list',
              component: () => import('@/view/pages/users/List.vue')
            },
            {
              path: 'new',
              name: 'users-new',
              component: () => import('@/view/pages/users/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'users-edit',
              component: () => import('@/view/pages/users/Edit.vue')
            }
          ]
        },
        {
          path: '/reservations',
          name: 'reservations',
          component: () => import('@/view/pages/reservations/Reservations'),
          children: [
            {
              path: 'list',
              name: 'reservations-list',
              component: () => import('@/view/pages/reservations/List.vue')
            },
            {
              path: 'new',
              name: 'reservations-new',
              component: () => import('@/view/pages/reservations/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'reservations-edit',
              component: () => import('@/view/pages/reservations/Edit.vue')
            }
          ]
        },
        {
          path: '/reviews',
          name: 'reviews',
          component: () => import('@/view/pages/reviews/Reviews'),
          children: [
            {
              path: 'list',
              name: 'reviews-list',
              component: () => import('@/view/pages/reviews/List.vue')
            },
            {
              path: 'new',
              name: 'reviews-new',
              component: () => import('@/view/pages/reviews/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'reviews-edit',
              component: () => import('@/view/pages/reviews/Edit.vue')
            },
            {
              path: 'edit/:id/translations',
              name: 'reviews-edit-translations',
              component: () => import('@/view/pages/reviews/Translate.vue')
            }
          ]
        },
        {
          path: '/pages',
          name: 'pages',
          component: () => import('@/view/pages/pages/Pages'),
          children: [
            {
              path: 'list',
              name: 'pages-list',
              component: () => import('@/view/pages/pages/List.vue')
            },
            {
              path: 'new',
              name: 'pages-new',
              component: () => import('@/view/pages/pages/New.vue')
            },
            {
              path: 'edit/:id',
              name: 'pages-edit',
              component: () => import('@/view/pages/pages/Edit.vue')
            },
            {
              path: 'edit/:id/translations',
              name: 'pages-edit-translations',
              component: () => import('@/view/pages/pages/Translate.vue')
            }
          ]
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/view/pages/profile/Profile'),
          children: [
            {
              path: 'overview',
              name: 'profile-overview',
              component: () => import('@/view/pages/profile/Profile-1.vue')
            }
          ]
        },
        {
          path: '/translations',
          name: 'translations',
          component: () => import('@/view/pages/translations/Translations'),
          children: [
            {
              path: 'edit',
              name: 'translations-edit',
              component: () =>
                import('@/view/pages/translations/Translations.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('@/view/pages/error/Error.vue'),
      children: [
        {
          path: 'error-1',
          name: 'error-1',
          component: () => import('@/view/pages/error/Error-1.vue')
        },
        {
          path: 'error-2',
          name: 'error-2',
          component: () => import('@/view/pages/error/Error-2.vue')
        },
        {
          path: 'error-3',
          name: 'error-3',
          component: () => import('@/view/pages/error/Error-3.vue')
        },
        {
          path: 'error-4',
          name: 'error-4',
          component: () => import('@/view/pages/error/Error-4.vue')
        },
        {
          path: 'error-5',
          name: 'error-5',
          component: () => import('@/view/pages/error/Error-5.vue')
        },
        {
          path: 'error-6',
          name: 'error-6',
          component: () => import('@/view/pages/error/Error-6.vue')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/login_pages/Login-1'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/login_pages/Login-1')
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue')
    }
  ]
})
