//import Vue from 'vue'

/**
 * Service to encapsulate translations helpers etc.
 */
const TranslationsService = {
  init(defaultLang, localesList) {
    // What can we do here? Do we initialize with list of locales + requiredFields or what?
    this.defaultLang = defaultLang
    this.localesList = localesList
  },

  getLocalesList() {
    return this.localesList
  },

  getDefaultLang() {
    return this.defaultLang
  },

  isArray(something) {
    return something && something.constructor === Array
  },

  isEmpty(something) {
    let somethingType = typeof something
    if (somethingType === 'undefined') {
      return true
    }
    if (this.isArray(something)) {
      return something.length === 0
    }
    if (somethingType === 'string') {
      return something === ''
    }
    if (somethingType === 'object') {
      return Object.keys(something).length === 0
    }
  },

  ensureTranslationsStructure(object, requiredFields) {
    // Making sure there are no undefined vars/elements when UI template is constructed
    // in cases when some translations do not exist at all yet, or some required fields end up missing etc.
    let _self = this

    this.localesList.forEach(function(lang) {
      if (!object.translations) {
        object.translations = {}
      }
      if (!object.translations[lang]) {
        object.translations[lang] = {}
        object.translations[lang].locale = lang
      }

      // If a field exists in original/default lang, and is not empty,
      // and the current iteration's language field is empty, copy the value
      // over from the original... That way translating facilities / location becomes
      // a lot easier -- tho, it may introduce other problems -- how do you delete something and keep it empty?

      // TODO: This list of fields is from villas, but other entities may need
      //  a different list... or we list all possible fields here and skip those that
      //  do not exist? how
      ;[
        'type',
        'title',
        'slug',
        'subtitle',
        'description',
        'seoMeta',
        'facilities',
        'location'
      ].forEach(function(fieldName) {
        let isEmptyInCurrentLang = _self.isEmpty(
          object.translations[lang][fieldName]
        )
        let isNotEmptyInDefaultLang = !_self.isEmpty(
          object.translations[_self.defaultLang][fieldName]
        )

        if (isEmptyInCurrentLang && isNotEmptyInDefaultLang) {
          // If original is a string, just copy string, otherwise clone object/array
          if (
            typeof object.translations[_self.defaultLang][fieldName] ===
            'string'
          ) {
            // Just copy the string
            object.translations[lang][fieldName] =
              object.translations[_self.defaultLang][fieldName]
          } else {
            // If/when numbers or other things appear, maybe differentiate further, for now
            // JSON.parse fully clones objects/arrays just fine. Attempts at using spread
            // operator or object.assign() failed miserably in some cases (most notably on Villa
            // translations editing screens with certain fields/nested components)
            object.translations[lang][fieldName] = JSON.parse(
              JSON.stringify(object.translations[_self.defaultLang][fieldName])
            )
          }
        }
      })

      // TODO: Do we even need this any more now?
      //  We do, yeah, for now at least, as some screens maye have a different
      //  list of required fields than what is handled by data copying/cloning above)
      //  Notably: content, comment (all just strings for now?)
      requiredFields.forEach(function(fieldName) {
        let fieldType = typeof object.translations[lang][fieldName]
        if (fieldType === 'undefined') {
          if (
            fieldName === 'seoMeta' ||
            fieldName === 'facilities' ||
            fieldName === 'location'
          ) {
            // These are to be set as an empty object (or array, facilities need to be an array!)
            if (_self.isEmpty(object.translations[lang][fieldName])) {
              if (fieldName === 'facilities') {
                object.translations[lang][fieldName] = []
              } else {
                object.translations[lang][fieldName] = {}
              }
            }
          } else {
            // Set as empty string
            object.translations[lang][fieldName] = ''
          }
        }
      })
    })

    return object
  }
}

export default TranslationsService
