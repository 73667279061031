const LI_TOKEN_KEY = 'li_token'

export const getToken = () => {
  return window.localStorage.getItem(LI_TOKEN_KEY)
}

export const saveToken = token => {
  window.localStorage.setItem(LI_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(LI_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken }
