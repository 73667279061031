<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex flex-row">
          <div class="flex-row-fluid">
            <notifications group="notification" position="bottom right" />
            <div v-if="isLoaded">
              <div class="card card-custom card-sticky gutter-b">
                <div
                  class="card-header bg-light border-0"
                  style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
                >
                  <b-col align-self="center">
                    <!-- these are simulating the tab controls inside the sticky header -->
                    <b-nav card-header tabs align="left">
                      <b-nav-item
                        v-for="(loopLang, loopIndex) in translationsLocales"
                        @click="langClick(loopIndex, loopLang)"
                        :title="loopLang.toUpperCase()"
                        :key="loopLang"
                        :active="tabIndex === loopIndex"
                        >{{ loopLang.toUpperCase() }}
                      </b-nav-item>
                    </b-nav>
                  </b-col>
                  <b-col class="p-0" align-self="center">
                    <div class="float-right">
                      <CardToolbar
                        @submitEvent="submit"
                        back-text="All Experiences"
                        back-url="/experiences/list"
                        edit-text="Edit Experience"
                        :edit-url="{
                          name: 'experiences-edit',
                          params: { id: $route.params.id }
                        }"
                        button-text="Save Translations"
                        :dropdown-enabled="false"
                      />
                    </div>
                  </b-col>
                </div>

                <!--begin::Body-->
                <!-- nav-wrapper-class="d-none" is what hides the actual nav tabs that are produced but <b-tab> -->
                <b-tabs
                  v-model="tabIndex"
                  card
                  nav-wrapper-class="d-none"
                  content-class="mt-5"
                >
                  <b-tab
                    no-body
                    @click="switchLang(loopLang)"
                    v-for="(loopLang, loopIndex) in translationsLocales"
                    :title="loopLang.toUpperCase()"
                    :key="loopLang"
                    :active="tabIndex === loopIndex"
                  >
                    <div class="card-body pt-0">
                      <ValidationProvider
                        name="Title"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <div class="form-group mb-8">
                          <label class="font-weight-bolder">Title</label>
                          <input
                            v-model="translations[loopLang].title"
                            type="text"
                            :class="[
                              'form-control form-control-solid form-control-lg',
                              { 'is-invalid': errors[0] }
                            ]"
                            :placeholder="
                              'Title (' + loopLang.toUpperCase() + ')'
                            "
                          />
                        </div>
                      </ValidationProvider>

                      <div class="form-group mb-8">
                        <label class="font-weight-bolder">Subtitle</label>
                        <input
                          v-model="translations[loopLang].subtitle"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="
                            'Subtitle (' + loopLang.toUpperCase() + ')'
                          "
                        />
                      </div>

                      <div class="form-group mb-8">
                        <label class="font-weight-bolder">Content</label>
                        <quill-editor
                          class="translations-rte"
                          :content="translations[loopLang].content"
                          :options="contentEditorOption"
                          @change="onEditorChange($event)"
                        />
                      </div>

                      <seo
                        :meta="translations[loopLang].seoMeta"
                        :entity-url="siteBaseUrl"
                      ></seo>
                    </div>
                    <!--end::Body-->
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import Seo from '@/view/content/components/seo/Seo'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import TranslationsService from '@/core/services/translations.service'

export default {
  components: {
    CardToolbar,
    Seo,
    'quill-editor': quillEditor
  },
  data() {
    return {
      isLoaded: false,
      siteBaseUrl: process.env.VUE_APP_ROUTE_API_URL,
      currentLang: 'en',
      defaultLang: 'en',
      translationsLocales: TranslationsService.getLocalesList(),
      tabIndex: 0,
      translations: {},
      requiredFields: ['title', 'subtitle', 'content', 'seoMeta'],
      contentEditorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean', 'link']
          ]
        }
      }
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Experiences', route: '/experiences/list' },
      { title: 'Edit Experience Translations' }
    ])

    ApiService.get(
      'experiences',
      `${this.$route.params.id}?groups[]=translations`
    ).then(({ data }) => {
      this.translations = data.translations
      TranslationsService.ensureTranslationsStructure(this, this.requiredFields)
      setTimeout(() => {
        this.isLoaded = true
      }, 500)
    })
  },
  methods: {
    onSubmit() {
      this.submit()
      ApiService.update('experiences', this.$route.params.id, {
        translations: this.translations
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Translations saved.'
        })
      })
    },
    submit() {}, // Note: must exist because of CardToolbar component?
    langClick(tabIdx, langCode) {
      this.tabIndex = tabIdx
      this.switchLang(langCode)
    },
    switchLang(lang) {
      this.currentLang = lang
    },
    onEditorChange({ html }) {
      this.translations[this.currentLang].content = html
    }
  }
}
</script>

<style lang="scss">
.translations-rte {
  .ql-editor {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 0 10px 0;
      font-weight: bold;
    }
    p {
      padding: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      font-family: Poppins, Helvetica, 'sans-serif';
    }
    ul {
      li {
        font-family: Poppins, Helvetica, 'sans-serif';
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
  .ql-editor.ql-blank {
    min-height: 350px;
  }
}
</style>
