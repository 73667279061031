<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex flex-row">
          <div class="flex-row-fluid mr-lg-8">
            <notifications group="notification" position="bottom right" />

            <div class="card card-custom card-sticky gutter-b">
              <div
                class="card-header border-0"
                style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
              >
                <div class="card-title">
                  <h3 class="card-label">
                    Add New Post
                  </h3>
                </div>
                <CardToolbar
                  @submitEvent="submit"
                  back-text="All Posts"
                  back-url="/posts/list"
                  button-text="Post"
                  action-text="Add"
                  :dropdown-enabled="false"
                />
              </div>
              <!--begin::Body-->
              <div class="card-body pt-0">
                <ValidationProvider
                  name="Title"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="form-group mb-8">
                    <label class="font-weight-bolder">Title</label>
                    <input
                      v-model="post.translations.en.title"
                      type="text"
                      :class="[
                        'form-control form-control-solid form-control-lg',
                        { 'is-invalid': errors[0] }
                      ]"
                      placeholder="Post Title"
                    />
                  </div>
                </ValidationProvider>

                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Subtitle</label>
                  <input
                    v-model="post.translations.en.subtitle"
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="Post Subtitle"
                  />
                </div>

                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Content</label>
                  <quill-editor
                    class="post-content"
                    :content="post.translations.en.content"
                    :options="contentEditorOption"
                    @change="onEditorChange($event)"
                  />
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <b-card no-body class="border-0">
                      <b-tabs pills vertical nav-wrapper-class="col-3">
                        <b-tab active>
                          <template v-slot:title>
                            <span class="nav-icon">
                              <i class="flaticon2-rocket-1"></i>
                            </span>
                            <span class="font-weight-bolder">Multimedia</span>
                          </template>
                          <b-card-text>
                            <div class="card card-custom gutter-b">
                              <div class="card-header border-0">
                                <div class="card-title">
                                  <h3 class="card-label">Multimedia</h3>
                                </div>
                              </div>
                              <div class="card-body pt-0">
                                <div class="form-group mb-2">
                                  <label class="font-weight-bolder"
                                    >Featured Image</label
                                  >

                                  <Dropzone
                                    class="dropzone dropzone-default dropzone-primary dz-clickable h-90px w-90px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0"
                                    ref="villaFeaturedImageDropzone"
                                    @vdropzone-file-added="featuredImageAdd"
                                    @vdropzone-complete="featuredImageComplete"
                                    @vdropzone-success="featuredImageSuccess"
                                    id="villaFeaturedImage"
                                    :options="featuredImageDropzoneOptions"
                                    :useCustomSlot="true"
                                  >
                                    <span class="svg-icon svg-icon-lg m-0">
                                      <inline-svg
                                        src="media/svg/icons/Design/Image.svg"
                                      />
                                    </span>
                                  </Dropzone>

                                  <div
                                    v-if="post.featuredImage.src"
                                    class="symbol symbol-90 flex-shrink-0 mr-4 cursor-pointer"
                                  >
                                    <div
                                      class="symbol-label"
                                      v-bind:style="{
                                        'background-image':
                                          'url(' +
                                          imagePath +
                                          post.featuredImage.src +
                                          ')'
                                      }"
                                    ></div>
                                  </div>
                                </div>
                                <div class="form-group mb-2">
                                  <label class="font-weight-bolder"
                                    >Image Gallery</label
                                  >

                                  <Dropzone
                                    class="dropzone dropzone-default dropzone-primary dz-clickable h-90px w-90px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0"
                                    ref="villaImageGalleryDropzone"
                                    @vdropzone-file-added="featuredImageAdd"
                                    @vdropzone-complete="featuredImageComplete"
                                    @vdropzone-success="featuredImageSuccess"
                                    id="villaImageGallery"
                                    :options="imageGalleryDropzoneOptions"
                                    :useCustomSlot="true"
                                  >
                                    <span class="svg-icon svg-icon-lg m-0">
                                      <inline-svg
                                        src="media/svg/icons/Design/Image.svg"
                                      />
                                    </span>
                                  </Dropzone>
                                </div>
                              </div>
                            </div>
                          </b-card-text>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            <span class="nav-icon">
                              <i class="flaticon2-rocket-1"></i>
                            </span>
                            <span class="font-weight-bolder">SEO</span>
                          </template>
                          <b-card-text>
                            <seo
                              :meta="post.translations.en.seoMeta"
                              :entity-url="
                                'https://www.likeistria.com/en/blog/'
                              "
                            ></seo>
                          </b-card-text>
                        </b-tab>
                      </b-tabs>
                    </b-card>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
          </div>
          <div class="flex-column offcanvas-mobile w-300px w-xl-325px">
            <div class="card card-custom gutter-b">
              <!--begin::Header-->
              <div class="card-header border-0 pt-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark"
                    >Options</span
                  >
                </h3>
              </div>
              <!--end::Header-->

              <!--begin::Body-->
              <div class="card-body pt-0">
                <div class="row">
                  <label class="col-6 col-form-label">Publish</label>
                  <div class="col-6">
                    <LiSwitch
                      @checkedEvent="updateIsPublish"
                      :is-checked="post.isPublish"
                      classes="switch-icon float-right"
                      name="postIsPublish"
                    />
                  </div>
                </div>
                <div class="row">
                  <label class="col-6 col-form-label">Featured</label>
                  <div class="col-6">
                    <LiSwitch
                      @checkedEvent="updateIsFeatured"
                      :is-checked="post.isFeatured"
                      classes="switch-icon float-right"
                      name="postIsFeatured"
                    />
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>

            <div class="card card-custom gutter-b">
              <!--begin::Header-->
              <div class="card-header border-0 pt-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark"
                    >Taxonomies</span
                  >
                </h3>
              </div>
              <!--end::Header-->

              <!--begin::Body-->
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-12">
                    <label>Category</label>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <v-select
                        class="form-control form-control--category-select"
                        placeholder="Choose category"
                        v-model="post.category"
                        :options="categories"
                        :reduce="category => category['@id']"
                        label="title"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-10">
                  <div class="col-12">
                    <label>Tags</label>
                  </div>
                  <div class="col-12">
                    <div class="form-group mb-8">
                      <v-select
                        class="form-control form-control--tag-select"
                        placeholder="Choose tags"
                        v-model="post.tags"
                        multiple
                        :options="tags"
                        :reduce="tag => tag['@id']"
                        label="title"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import Seo from '@/view/content/components/seo/Seo'
import Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'

export default {
  components: {
    'v-select': vSelect,
    LiSwitch,
    CardToolbar,
    Dropzone,
    Seo,
    'quill-editor': quillEditor
  },
  data() {
    return {
      post: {
        translations: {
          en: {
            locale: 'en',
            title: '',
            subtitle: '',
            content: '',
            seoMeta: {
              ogUrl: null,
              ogImage: null,
              ogTitle: null,
              keywords: null,
              canonical: null,
              ogSiteName: null,
              description: null,
              twitterCard: null,
              twitterImage: null,
              twitterTitle: null,
              ogDescription: null,
              ogImageSecureUrl: null,
              twitterDescription: null
            }
          }
        },
        featuredImage: {
          src: '',
          alt: ''
        },
        imageGallery: [],
        isPublish: false,
        isFeatured: false,
        category: null,
        tags: []
      },
      featuredImageDropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 1
      },
      imageGalleryDropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 50
      },
      contentEditorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean', 'link']
          ]
        }
      },
      priceRangeCalendar: {
        calendarTransition: 'slide-v',
        mode: 'range',
        selectedDate: null,
        disabledDays: null,
        availableDays: null
      },
      imagePath: process.env.VUE_APP_ROUTE_API_URL,
      isLoaded: false,
      newCategory: null,
      categories: [],
      tags: [],
      categoryPage: 1,
      tagsPage: 1
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Posts', route: '/posts/list' },
      { title: 'New Post' }
    ])

    this.getCategories(this.categoryPage).then(response => {
      this.categories = [...response.data['hydra:member']]
    })
    this.getTags(this.tagsPage).then(({ data }) => {
      this.tags = [...data['hydra:member']]
    })
  },
  methods: {
    onSubmit() {
      this.submit()
      ApiService.post('posts', this.post, {
        Headers: {
          ContentType: 'application/ld+json'
        }
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Post added.'
        })
      })
    },
    submit() {},
    updateIsPublish(checked) {
      this.post.isPublish = checked
    },
    updateIsFeatured(checked) {
      this.post.isFeatured = checked
    },
    featuredImageAdd() {},
    featuredImageSuccess(_, response) {
      this.post.featuredImage.src = '/uploads/' + response
    },
    featuredImageComplete() {},
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      this.post.translations.en.content = html
    },
    onChangeList: function({ source, destination }) {
      this.source = source
      this.destination = destination
    },
    async getCategories(page) {
      try {
        return await ApiService.get('categories', `?page=${page}`)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getTags(page) {
      try {
        return await ApiService.get('tags', `?page=${page}`)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.post-content {
  .ql-editor {
    min-height: 350px !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 0 10px 0;
      font-weight: bold;
    }
    p {
      padding: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      font-family: Poppins, Helvetica, 'sans-serif';
    }
    ul {
      li {
        font-family: Poppins, Helvetica, 'sans-serif';
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
}
.dz-preview,
.dz-file-preview {
  display: none;
}
.form-control--category-select {
  height: unset !important;

  .vs__dropdown-toggle {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .vs__dropdown-menu {
    border: 1px solid #e4e6ef;
    -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    li {
      padding: 0.65rem 1rem;

      &:hover {
        background: #f3f6f9;
        color: #3f4254;
      }
    }
  }

  .vs__open-indicator {
  }
  .vs__dropdown-toggle {
  }
  .vs__clear {
    display: none;
  }
  .vs__selected {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    margin: 0.1rem 0.4rem 0.1rem 0;
    position: relative;
    float: left /*rtl:right*/;
    color: #3f4254;
    background: #f3f6f9;
    border: 0;
    border-radius: 0.42rem;
  }
  .vs__no-options {
  }
  .vs__spinner {
  }
  .vs__deselect {
  }
  .vs__active {
  }
  .vs__dropdown-option--highlight {
    background: #ebedf3;
    color: #3f4254;
  }
}
.form-control--tag-select {
  height: unset !important;

  .vs__dropdown-toggle {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .vs__dropdown-menu {
    border: 1px solid #e4e6ef;
    -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    li {
      padding: 0.65rem 1rem;

      &:hover {
        background: #f3f6f9;
        color: #3f4254;
      }
    }
  }

  .vs__open-indicator {
  }
  .vs__dropdown-toggle {
  }
  .vs__clear {
    display: none;
  }
  .vs__selected {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    margin: 0.1rem 0.4rem 0.1rem 0;
    position: relative;
    float: left /*rtl:right*/;
    color: #3f4254;
    background: #f3f6f9;
    border: 0;
    border-radius: 0.42rem;
  }
  .vs__no-options {
  }
  .vs__spinner {
  }
  .vs__deselect {
  }
  .vs__active {
  }
  .vs__dropdown-option--highlight {
    background: #ebedf3;
    color: #3f4254;
  }
}
</style>
