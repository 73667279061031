<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="d-flex flex-row">
        <div class="flex-row-fluid mr-lg-8">
          <notifications group="notification" position="bottom right" />

          <div class="card card-custom card-sticky gutter-b">
            <div
              class="card-header border-0"
              style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
            >
              <div class="card-title">
                <h3 class="card-label">
                  Add New Region
                </h3>
              </div>
              <CardToolbar
                @submitEvent="submit"
                back-text="All Regions"
                back-url="/regions/list"
                button-text="Region"
                action-text="Add"
                :dropdownEnabled="false"
              />
            </div>
            <!--begin::Body-->
            <div class="card-body pt-0">
              <ValidationProvider
                name="Title"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Title</label>
                  <input
                    v-model="region.translations.en.title"
                    type="text"
                    :class="[
                      'form-control form-control-solid form-control-lg',
                      { 'is-invalid': errors[0] }
                    ]"
                    placeholder="Region Title"
                  />
                </div>
              </ValidationProvider>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="flex-column offcanvas-mobile w-300px w-xl-325px"></div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'

export default {
  components: {
    CardToolbar
  },
  data() {
    return {
      region: {
        translations: {
          en: {
            locale: 'en',
            title: ''
          }
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Posts', route: '/regions/list' },
      { title: 'New Region' }
    ])
  },
  methods: {
    onSubmit() {
      this.submit()
      ApiService.post('regions', this.region, {
        Headers: {
          ContentType: 'application/ld+json'
        }
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Region added'
        })

        setTimeout(() => {
          this.$router.push({ name: 'regions-list' })
        }, 200)
      })
    },
    submit() {}
  }
}
</script>
