<template>
  <div class="d-flex flex-row">
    <div class="flex-row-fluid mr-lg-8">
      <notifications group="notification" position="bottom right" />
      <div class="card card-custom card-sticky gutter-b">
        <div
          class="card-header border-0"
          style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
        >
          <div class="card-toolbar" style="flex-grow: 1;">
            <b-row style="flex-grow: 1;">
              <b-col class="col-3 col-md-2" align-self="center">
                <h3 class="card-title">
                  <span class="card-label font-weight-bolder text-dark"
                    >String Translations</span
                  >
                </h3>
              </b-col>
              <b-col class="col-5 col-md-4" align-self="center">
                <vSelect
                  class="form-control form-control--language-select"
                  placeholder="Select language"
                  @input="onSelectLanguage"
                  v-model="selectedLanguage"
                  :options="languages"
                  label="language"
                />
              </b-col>
              <b-col class="col" align-self="center">
                <button
                  @click.prevent="reload"
                  class="btn btn-light-primary font-weight-bolder font-size-sm mr-3 "
                  title="Reload"
                >
                  <span class="svg-icon svg-icon-md pl-2">
                    <inline-svg src="media/svg/icons/Media/Repeat.svg" />
                  </span>
                </button>
              </b-col>
              <b-col align-self="center">
                <button
                  @click="saveCurrentTranslation"
                  class="btn btn-primary font-weight-bolder px-10"
                  style="height: 46px; width: 100%"
                >
                  Save
                </button>
              </b-col>
            </b-row>
          </div>
        </div>
        <!--begin::Body-->
        <div class="card-body pt-0 pb-3">
          <!--begin::Table-->
          <div v-if="isLoaded" class="table-responsive">
            <b-table
              fixed
              head-row-variant="dark"
              hover
              striped
              bordered
              borderless
              outlined
              :fields="fields"
              :items="translations"
            >
              <template #cell(messagesTranslation)="data">
                <b-form-textarea
                  v-model="data.item.messagesTranslation"
                  rows="2"
                  max-rows="5"
                />
              </template>
            </b-table>
          </div>
          <!--end::Table-->
          <div v-else class="w-100 d-flex justify-content-center my-10">
            <div class="d-flex align-items-center">
              <div class="mr-2 text-muted">Loading...</div>
              <div class="spinner spinner-primary mr-10"></div>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect
  },
  data() {
    return {
      translations: [
        {
          messagesTranslationId: '',
          messagesTranslation: ''
        }
      ],
      languages: ['en', 'de', 'hr'],
      selectedLanguage: 'en',
      language: null,
      isLoaded: false,
      fields: [
        {
          key: 'messagesTranslationId',
          label: 'Key/Value',
          sortable: true
        },
        {
          key: 'messagesTranslation',
          label: 'Translation',
          sortable: true
        }
      ],
      // Strip off /v1 from api endpoint url
      apiBaseUrl: process.env.VUE_APP_API_ENDPOINT.substring(
        0,
        process.env.VUE_APP_API_ENDPOINT.lastIndexOf('/')
      )
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Translations' }])
    this.fetchTranslations()
  },
  methods: {
    url(resource) {
      return this.apiBaseUrl + resource
    },
    fetchTranslations() {
      ApiService.get(
        this.url('/yaml-translations/' + this.selectedLanguage)
      ).then(({ data }) => {
        this.translations = [...data['messagesTranslations']]
        this.isLoaded = true
      })
    },
    saveCurrentTranslation() {
      let postData = {
        languageCode: this.selectedLanguage,
        messagesTranslations: this.translations
      }
      ApiService.post(
        this.url('/yaml-translations/' + this.selectedLanguage),
        postData
      ).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Saved.'
        })
      })
    },
    reload() {
      this.translations = []
      this.isLoaded = false
      this.onSelectLanguage('en')
    },
    onSelectLanguage(lang) {
      this.selectedLanguage = lang
      this.fetchTranslations()
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
.form-control {
  &--language-select {
    height: 46px !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
