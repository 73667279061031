<template>
  <div>
    <div class="card card-custom gutter-b">
      <notifications group="notification" position="bottom right" />

      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Reviews</span>
        </h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link
            to="/reviews/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/General/Star.svg" />
            </span>
            New Review
          </router-link>
          <!--end::Button-->
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <div class="row">
          <div class="col-md-10">
            <vSelect
              class="form-control form-control--region-select"
              placeholder="Filter reviews by villa"
              @input="onSelectVilla"
              v-model="selectedVilla"
              :options="villas"
              :reduce="villa => villa['@id']"
              label="title"
            />
          </div>
          <div class="col-2">
            <button
              @click="viewAllReviews"
              class="btn btn-light-primary font-weight-bolder font-size-sm px-10"
              style="height: 46px; width: 100%"
            >
              View All
            </button>
          </div>
        </div>
        <div class="separator separator-dashed my-10"></div>
        <div v-if="isLoaded" class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center table-borderless"
          >
            <thead>
              <tr class="bg-gray-100 text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span>Villa</span>
                </th>
                <th>Comment</th>
                <th>Author</th>
                <th>createdAt</th>
                <th class="text-right" style="width: 200px">Actions</th>
              </tr>
            </thead>
            <tbody v-if="reviews.length > 0">
              <tr v-for="(review, key) in reviews" :key="key">
                <td>
                  <div v-if="review.villa && review.villa.title">
                    {{ review.villa.title }}
                  </div>
                </td>
                <td v-html="review.comment.substring(0, 100) + '...'"></td>
                <td>{{ review.author }}</td>
                <td>{{ review.createdAt | formatDate }}</td>
                <td class="pr-0 text-right">
                  <button
                    v-if="review.reservation"
                    @click="viewReservation(review)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon btn-sm mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Visible.svg" />
                    </span>
                  </button>
                  <router-link
                    :to="'/reviews/edit/' + review.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/Design/Edit.svg"
                        title="Edit"
                        aria-label="Edit"
                      />
                    </span>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'reviews-edit-translations',
                      params: { id: review.id }
                    }"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/translate.svg"
                        title="Edit Translations"
                        aria-label="Edit Translations"
                      />
                    </span>
                  </router-link>
                  <button
                    @click="removeEntity(review)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon btn-sm"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <div class="alert alert-primary mt-4" role="alert">
                No reviews for this villa...
              </div>
            </tbody>
          </table>
        </div>
        <div v-else class="w-100 d-flex justify-content-center my-10">
          <div class="d-flex align-items-center">
            <div class="mr-2 text-muted">Loading...</div>
            <div class="spinner spinner-primary mr-10"></div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal id="reservation_modal" centered hide-header hide-footer>
          <template v-if="isReservationLoaded">
            <div><strong>Villa: </strong>{{ reservation.villa.title }}</div>
            <div>
              <strong>Date From: </strong
              >{{ reservation.dateFrom | formatDate }}
            </div>
            <div>
              <strong>Date To: </strong>{{ reservation.dateTo | formatDate }}
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import moment from 'moment'
import vSelect from 'vue-select'

export default {
  filters: {
    formatDate: value => {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    strLimit: (value, size) => {
      if (!value) return ''
      value = value.toString()

      if (value.length <= size) {
        return value
      }
      return value.substr(0, size) + '...'
    }
  },
  components: {
    vSelect
  },
  data() {
    return {
      reviews: [],
      isLoaded: false,
      villas: [],
      selectedVilla: null,
      imagePath: process.env.VUE_APP_ROUTE_API_URL,
      clientURL: process.env.VUE_APP_ROUTE_API_URL,
      reservation: null,
      isReservationLoaded: false
    }
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Reviews' }])

    ApiService.get(
      'reviews',
      '?order[createdAt]=desc&isPublish=true&page=1'
    ).then(({ data }) => {
      this.reviews = [...data['hydra:member']]
      this.isLoaded = true

      ApiService.get('villas', '?order[createdAt]=desc').then(({ data }) => {
        this.villas = [...data['hydra:member']]
        this.isVillasLoaded = true
      })
    })
  },
  methods: {
    async deleteEntity(id) {
      try {
        return await ApiService.delete('reviews/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    async updateEntity(id, payload) {
      try {
        return await ApiService.update('reservations', id, payload)
      } catch (e) {
        throw new Error(e)
      }
    },
    removeEntity(r) {
      const confirmation = confirm('You want delete this review?')
      if (confirmation === false) return

      if (r.reservation) {
        this.updateEntity(
          r.reservation['@id'].replace('/api/v1/reservations/', ''),
          { isReviewed: false }
        ).then(() => {
          this.deleteEntity(r.id).then(({ status }) => {
            if (status === 204) {
              this.reviews = this.reviews.filter(review => review.id !== r.id)
              this.$notify({
                group: 'notification',
                type: 'error',
                title: 'Review deleted'
              })
            }
          })
        })
      } else {
        this.deleteEntity(r.id).then(({ status }) => {
          if (status === 204) {
            this.reviews = this.reviews.filter(review => review.id !== r.id)
            this.$notify({
              group: 'notification',
              type: 'error',
              title: 'Review deleted'
            })
          }
        })
      }
    },
    onSelectVilla(villa) {
      ApiService.get('reviews', '?villa=' + villa).then(({ data }) => {
        this.reviews = []
        this.reviews = [...data['hydra:member']]
      })
    },
    viewAllReviews() {
      ApiService.get('reviews', '?order[createdAt]=desc').then(({ data }) => {
        this.reviews = []
        this.selectedVilla = null
        this.reviews = [...data['hydra:member']]
      })
    },
    viewReservation(review) {
      this.reservation = null
      this.isReservationLoaded = false
      ApiService.get(
        'reservations',
        review.reservation['@id'].replace('/api/v1/reservations/', '')
      ).then(({ data }) => {
        this.reservation = data
        this.isReservationLoaded = true
        this.$bvModal.show('reservation_modal')
      })
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
.form-control {
  &--category-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--tag-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--region-select {
    height: 46px !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
