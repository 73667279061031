<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex flex-row">
          <div class="flex-row-fluid">
            <notifications group="notification" position="bottom right" />
            <div v-if="isLoaded">
              <div class="card card-custom card-sticky gutter-b">
                <div
                  class="card-header bg-light border-0"
                  style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
                >
                  <b-col align-self="center">
                    <!-- these are simulating the tab controls inside the sticky header -->
                    <b-nav card-header tabs align="left">
                      <b-nav-item
                        v-for="(loopLang, loopIndex) in translationsLocales"
                        @click="langClick(loopIndex, loopLang)"
                        :title="loopLang.toUpperCase()"
                        :key="loopLang"
                        :active="tabIndex === loopIndex"
                        >{{ loopLang.toUpperCase() }}
                      </b-nav-item>
                    </b-nav>
                  </b-col>
                  <b-col class="p-0" align-self="center">
                    <div class="float-right">
                      <CardToolbar
                        @submitEvent="submit"
                        back-text="All Villas"
                        back-url="/villas/list"
                        edit-text="Edit Villa"
                        :edit-url="{
                          name: 'villas-edit',
                          params: { id: $route.params.id }
                        }"
                        button-text="Save Translations"
                        :dropdown-enabled="false"
                      />
                    </div>
                  </b-col>
                </div>

                <!--begin::Body-->
                <!-- nav-wrapper-class="d-none" is what hides the actual nav tabs that are produced but <b-tab> -->
                <b-tabs
                  v-model="tabIndex"
                  card
                  nav-wrapper-class="d-none"
                  content-class="mt-5"
                >
                  <b-tab
                    no-body
                    @click="switchLang(loopLang)"
                    v-for="(loopLang, loopIndex) in translationsLocales"
                    :title="loopLang.toUpperCase()"
                    :key="loopLang"
                    :active="tabIndex === loopIndex"
                  >
                    <div class="card-body pt-0">
                      <div class="form-group mb-8">
                        <label class="font-weight-bolder">Type</label>
                        <input
                          v-model="translations[loopLang].type"
                          class="form-control form-control-solid"
                          type="text"
                          :placeholder="'Type (' + loopLang.toUpperCase() + ')'"
                        />
                      </div>
                      <ValidationProvider
                        name="Title"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <div class="form-group mb-8">
                          <label class="font-weight-bolder">Title</label>
                          <input
                            v-model="translations[loopLang].title"
                            type="text"
                            :class="[
                              'form-control form-control-solid form-control-lg',
                              { 'is-invalid': errors[0] }
                            ]"
                            :placeholder="
                              'Title (' + loopLang.toUpperCase() + ')'
                            "
                          />
                          <input
                            type="hidden"
                            v-model="translations[loopLang].type"
                          />
                        </div>
                      </ValidationProvider>

                      <div class="form-group mb-8">
                        <label class="font-weight-bolder">Subtitle</label>
                        <input
                          v-model="translations[loopLang].subtitle"
                          type="text"
                          class="form-control form-control-solid form-control-lg"
                          :placeholder="
                            'Subtitle (' + loopLang.toUpperCase() + ')'
                          "
                        />
                      </div>

                      <div class="form-group mb-8">
                        <label class="font-weight-bolder">Description</label>
                        <quill-editor
                          class="translation-rte"
                          :content="translations[loopLang].description"
                          :options="contentEditorOption"
                          @change="onEditorChange($event)"
                        />
                      </div>

                      <seo
                        :meta="translations[loopLang].seoMeta"
                        :entity-url="
                          siteBaseUrl +
                            '/' +
                            loopLang +
                            '/villas/' +
                            translations[loopLang].slug
                        "
                      ></seo>

                      <facilities
                        :facilities="translations[loopLang].facilities"
                        @addFacilitiesGroup="addFacilitiesGroup"
                        @removeFacilitiesGroup="removeFacilitiesGroup"
                        @addSubFacilitiesItem="addSubFacilitiesItem"
                        @removeSubFacilitiesItem="removeSubFacilitiesItem"
                      >
                      </facilities>

                      <!-- Location -->
                      <div class="card card-custom gutter-b">
                        <div class="card-header border-0">
                          <div class="card-title">
                            <h3 class="card-label">Location</h3>
                          </div>
                        </div>
                        <div class="card-body pt-0">
                          <div class="form-group mb-2">
                            <label class="font-weight-bolder"
                              >Location Lat</label
                            >
                            <div class="input-group">
                              <input
                                type="text"
                                v-model.number="
                                  translations[loopLang].location.lat
                                "
                                class="form-control form-control-solid"
                              />
                            </div>
                          </div>
                          <div class="form-group mb-2">
                            <label class="font-weight-bolder"
                              >Location Lng</label
                            >
                            <div class="input-group">
                              <input
                                type="text"
                                v-model.number="
                                  translations[loopLang].location.lng
                                "
                                class="form-control form-control-solid"
                              />
                            </div>
                          </div>
                          <div class="form-group mb-2">
                            <label class="font-weight-bolder">Address</label>
                            <div class="input-group">
                              <input
                                type="text"
                                v-model.number="
                                  translations[loopLang].location.address
                                "
                                placeholder="Address"
                                class="form-control form-control-solid"
                              />
                            </div>
                          </div>
                          <div class="form-group mb-2">
                            <label class="font-weight-bolder"
                              >Main Location</label
                            >
                            <div class="input-group">
                              <input
                                type="text"
                                v-model.number="
                                  translations[loopLang].location.mainLocation
                                "
                                placeholder="Main Location"
                                class="form-control form-control-solid"
                              />
                            </div>
                          </div>
                          <div
                            class="separator separator-dashed mt-8 mb-8"
                          ></div>
                          <LocationMeta
                            :meta="translations[loopLang].location.meta"
                            @addLocationMetaItem="addLocationMetaItem"
                          />
                        </div>
                      </div>
                    </div>

                    <!--end::Body-->
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import Seo from '@/view/content/components/seo/Seo'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Facilities from '@/view/content/components/facilities/Facilities'
import LocationMeta from '@/view/content/components/location-meta/LocationMeta'
import TranslationsService from '@/core/services/translations.service'

export default {
  components: {
    CardToolbar,
    Seo,
    'quill-editor': quillEditor,
    facilities: Facilities,
    LocationMeta
  },
  data() {
    return {
      isLoaded: false,
      // TODO: Move all these duplicated props/data up the chain somewhere and reuse here
      //  so we dont have to go change a million things again when a new language needs to happen
      siteBaseUrl: process.env.VUE_APP_ROUTE_API_URL,
      currentLang: 'en',
      translationsLocales: TranslationsService.getLocalesList(),
      tabIndex: 0,
      translations: {},
      requiredFields: [
        'type',
        'title',
        'slug',
        'subtitle',
        'description',
        'facilities',
        'seoMeta',
        'location'
      ],
      contentEditorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean', 'link']
          ]
        }
      }
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Villas', route: '/villas/list' },
      { title: 'Edit Villa Translations' }
    ])

    ApiService.get(
      'villas',
      `${this.$route.params.id}?groups[]=translations`
    ).then(({ data }) => {
      this.translations = data.translations
      TranslationsService.ensureTranslationsStructure(this, this.requiredFields)
      this.isLoaded = true
    })
  },
  methods: {
    onSubmit() {
      this.submit()
      ApiService.update('villas', this.$route.params.id, {
        translations: this.translations
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Translations saved.'
        })
      })
    },
    submit() {}, // Note: must exist because of CardToolbar component?
    langClick(tabIdx, langCode) {
      this.tabIndex = tabIdx
      this.switchLang(langCode)
    },
    switchLang(lang) {
      this.currentLang = lang
    },
    onEditorChange({ html }) {
      this.translations[this.currentLang].description = html
    },
    // facilities
    addFacilitiesGroup() {
      this.translations[this.currentLang].facilities.push({
        name: '',
        items: [{ content: '' }],
        description: ''
      })
    },
    removeFacilitiesGroup(index) {
      let confirmation = confirm('Delete group?')
      if (confirmation === false) return

      this.translations[this.currentLang].facilities.splice(index, 1)
    },
    addSubFacilitiesItem(index) {
      this.translations[this.currentLang].facilities[index].items.push({
        content: ''
      })
    },
    removeSubFacilitiesItem(groupIndex, itemIndex) {
      let confirmation = confirm('Delete item?')
      if (confirmation === false) return

      this.translations[this.currentLang].facilities[groupIndex].items.splice(
        itemIndex,
        1
      )
    },

    addLocationMetaItem() {
      this.translations[this.currentLang].location.meta.items.push({
        name: '',
        content: {
          name: '',
          value: ''
        }
      })
    }
  }
}
</script>

<style lang="scss">
.translation-rte {
  .ql-editor {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 0 10px 0;
      font-weight: bold;
    }
    p {
      padding: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      font-family: Poppins, Helvetica, 'sans-serif';
    }
    ul {
      li {
        font-family: Poppins, Helvetica, 'sans-serif';
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
  .ql-editor.ql-blank {
    min-height: 350px;
  }
}
</style>
