<template>
  <div>
    <div class="card card-custom gutter-b">
      <notifications group="notification" position="bottom right" />

      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark"
            >Experiences</span
          >
        </h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link
            to="/experiences/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Design/Sketch.svg" />
            </span>
            New Experience
          </router-link>
          <!--end::Button-->
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <!--begin::Table-->
        <div v-if="isLoaded" class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center table-borderless"
          >
            <thead>
              <tr class="bg-gray-100 text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Title</span>
                </th>
                <th style="min-width: 120px" class="text-right">Featured</th>
                <th style="min-width: 120px" class="text-right">Is Publish</th>
                <th style="min-width: 110px" class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(experience, key) in experiences" :key="experience.id">
                <td class="pl-0 py-2">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50 flex-shrink-0 mr-4">
                      <div
                        class="symbol-label"
                        :style="{
                          'background-image':
                            'url(' +
                            imagePath +
                            '/media/cache/resolve/50x50/' +
                            experience.featuredImage.src +
                            ')'
                        }"
                      ></div>
                    </div>
                    <div>
                      <router-link
                        :to="'/experiences/edit/' + experience.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {{ experience.title }}
                      </router-link>
                      <span class="text-muted font-weight-bold d-block">
                        <div>
                          <strong>Created: </strong
                          ><span>{{
                            experience.createdAt | moment('DD.MM.YYYY')
                          }}</span>
                        </div>
                      </span>
                    </div>
                  </div>
                </td>
                <td class="text-right">
                  <LiSwitch
                    @checkedEvent="
                      updateIsFeatured(
                        experience.isFeatured,
                        experience.id,
                        key
                      )
                    "
                    :is-checked="experience.isFeatured"
                    classes="switch-sm switch-icon float-right"
                    name="villaIsFeatured"
                  />
                </td>
                <td>
                  <LiSwitch
                    @checkedEvent="
                      updateIsPublish(experience.isPublish, experience.id, key)
                    "
                    :is-checked="experience.isPublish"
                    classes="switch-sm switch-icon switch-outline switch-success float-right"
                    name="villaIsPublish"
                  />
                </td>
                <td class="pr-0 text-right">
                  <router-link
                    :to="'/experiences/edit/' + experience.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/Design/Edit.svg" />
                    </span>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'experiences-edit-translations',
                      params: { id: experience.id }
                    }"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/translate.svg"
                        title="Edit Translations"
                        aria-label="Edit Translations"
                      />
                    </span>
                  </router-link>
                  <a
                    @click.prevent="deleteExperiences(experience.id)"
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <div v-else class="w-100 d-flex justify-content-center my-10">
          <div class="d-flex align-items-center">
            <div class="mr-2 text-muted">Loading...</div>
            <div class="spinner spinner-primary mr-10"></div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import ApiService from '@/core/services/api.service'

export default {
  components: {
    LiSwitch
  },
  data() {
    return {
      experiences: [],
      isLoaded: false,
      imagePath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Experiences' }])

    // get all experiences
    // TODO: This may start getting slow soon, need ?groups[]=admin:listing
    ApiService.get('experiences', '?order[translations.title]=asc').then(
      ({ data }) => {
        this.experiences = [...data['hydra:member']]
        this.isLoaded = true
      }
    )
  },
  methods: {
    async deleteEntity(id) {
      try {
        return await ApiService.delete('experiences/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    updateExperience(entityId, params) {
      ApiService.update('experiences', entityId, params).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Experience saved.'
        })
      })
    },
    updateIsPublish(checked, entityId, key) {
      this.experiences[key].isPublish = !checked
      this.updateExperience(entityId, {
        isPublish: this.experiences[key].isPublish
      })
    },
    updateIsFeatured(checked, entityId, key) {
      this.experiences[key].isFeatured = !checked
      this.updateExperience(entityId, {
        isFeatured: this.experiences[key].isFeatured
      })
    },
    deleteExperiences(id) {
      const confirmation = confirm('Are you sure?')
      if (confirmation === false) return

      this.deleteEntity(id).then(({ status }) => {
        if (status === 204) {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Experience deleted.'
          })
          this.experiences = this.experiences.filter(exp => exp.id !== id)
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.symbol-label-wrapper {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}
.form-control {
  &--category-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--tag-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--region-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
.h-90px {
  height: 90px !important;
  min-height: unset;
}
.w-90px {
  width: 90px !important;
}
.dz-preview {
  display: none !important;
}
</style>
