<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex flex-row">
          <div class="flex-row-fluid mr-lg-8">
            <notifications group="notification" position="bottom right" />

            <div class="card card-custom card-sticky gutter-b">
              <div
                class="card-header border-0"
                style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
              >
                <div class="card-title">
                  <h3 class="card-label">
                    Edit Review
                  </h3>
                </div>
                <CardToolbar
                  @submitEvent="submit"
                  back-text="All Reviews"
                  back-url="/reviews/list"
                  button-text="Review"
                  action-text="Save"
                  :dropdown-enabled="false"
                />
              </div>
              <!--begin::Body-->
              <div class="card-body pt-0">
                <ValidationProvider
                  name="Author"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="form-group mb-8">
                    <label class="font-weight-bolder">Author</label>
                    <input
                      v-model="review.author"
                      type="text"
                      :class="[
                        'form-control form-control-solid form-control-lg',
                        { 'is-invalid': errors[0] }
                      ]"
                      placeholder="Enter Review Author "
                    />
                  </div>
                </ValidationProvider>

                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Comment</label>
                  <quill-editor
                    class="review-comment"
                    :content="review.translations.en.comment"
                    :options="commentEditorOption"
                    @change="onEditorChange($event)"
                  />
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group mb-8">
                      <label class="font-weight-bolder">Staff</label>
                      <select
                        class="form-control"
                        v-model="review.rating.staff"
                      >
                        <option>Select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mb-8">
                      <label class="font-weight-bolder">Location</label>
                      <select
                        class="form-control"
                        v-model="review.rating.location"
                      >
                        <option>Select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mb-8">
                      <label class="font-weight-bolder">Comfort</label>
                      <select
                        class="form-control"
                        v-model="review.rating.comfort"
                      >
                        <option>Select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mb-8">
                      <label class="font-weight-bolder">Cleanliness</label>
                      <select
                        class="form-control"
                        v-model="review.rating.cleanliness"
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group mb-8">
                      <label class="font-weight-bolder">Facilities</label>
                      <select
                        class="form-control"
                        v-model="review.rating.facilities"
                      >
                        <option>Select</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="font-weight-bolder">Villa</label>
                  <vSelect
                    class="form-control form-control--region-select"
                    placeholder="Select villa"
                    v-model="review.villa"
                    :options="villas"
                    :reduce="villa => villa.id"
                    label="title"
                  />
                </div>
              </div>
              <!--end::Body-->
            </div>
          </div>
          <div class="flex-column offcanvas-mobile w-300px w-xl-325px">
            <div class="card card-custom gutter-b">
              <!--begin::Header-->
              <div class="card-header border-0 pt-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark"
                    >Options</span
                  >
                </h3>
              </div>
              <!--end::Header-->

              <!--begin::Body-->
              <div class="card-body pt-0">
                <div class="row">
                  <label class="col-6 col-form-label">Publish</label>
                  <div class="col-6">
                    <LiSwitch
                      @checkedEvent="updateIsPublish"
                      :is-checked="review.isPublish"
                      classes="switch-icon float-right"
                      name="reviewIsPublish"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label class="font-weight-bolder">Publish date</label>
                  <v-date-picker ref="calendar" v-model="review.createdAt" />
                </div>
              </div>
              <!--end::Body-->
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'

export default {
  components: {
    LiSwitch,
    CardToolbar,
    'quill-editor': quillEditor,
    'v-date-picker': DatePicker,
    vSelect
  },
  data() {
    return {
      review: {
        translations: {
          en: {
            locale: 'en',
            comment: null
          }
        },
        author: null,
        rating: {
          staff: null,
          location: null,
          comfort: null,
          cleanliness: null,
          facilities: null
        },
        villa: null,
        isPublish: false,
        createdAt: null
      },
      villas: [],
      commentEditorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean', 'link']
          ]
        }
      }
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Reviews', route: '/reviews/list' },
      { title: 'Edit Review' }
    ])

    ApiService.get(
      'reviews',
      this.$route.params.id + '?groups[]=translations'
    ).then(({ data }) => {
      this.review.translations = data.translations
      this.review.translations.en = {}
      this.review.translations.en.locale = 'en'
      this.review.translations.en.comment = data.comment
      this.review.author = data.author
      this.review.rating.staff = data.rating.staff
      this.review.rating.location = data.rating.location
      this.review.rating.comfort = data.rating.comfort
      this.review.rating.cleanliness = data.rating.cleanliness
      this.review.rating.facilities = data.rating.facilities
      this.review.villa = data.villa
      this.review.isPublish = data.isPublish
      this.review.createdAt = data.createdAt

      this.$refs.calendar.move({
        month: getMonth(new Date(data.createdAt)) + 1,
        year: getYear(new Date(data.createdAt))
      })
    })

    this.getVillas('villas', '?order[createdAt]=desc&page=1').then(
      ({ data }) => {
        this.villas = [...data['hydra:member']]
      }
    )
  },
  methods: {
    onSubmit() {
      this.submit()
      ApiService.update('reviews', this.$route.params.id, this.review).then(
        () => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Review saved'
          })

          setTimeout(() => {
            this.$router.push({ name: 'reviews-list' })
          }, 500)
        }
      )
      //this.$refs.form.submit()
    },
    submit() {},
    updateIsPublish() {
      this.review.isPublish = !this.review.isPublish
    },
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      this.review.translations.en.comment = html
    },
    async getVillas(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
.review-comment {
  .ql-editor {
    min-height: 350px !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 0 10px 0;
      font-weight: bold;
    }
    p {
      padding: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      font-family: Poppins, Helvetica, 'sans-serif';
    }
    ul {
      li {
        font-family: Poppins, Helvetica, 'sans-serif';
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
}
.form-control {
  &--category-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--tag-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--region-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
