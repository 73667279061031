var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card card-custom gutter-b"},[_c('notifications',{attrs:{"group":"notification","position":"bottom right"}}),_c('div',{staticClass:"card-header border-0 py-5"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[_c('router-link',{staticClass:"btn btn-primary font-weight-bolder",attrs:{"to":"/villas/new"}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Home/Armchair.svg"}})],1),_vm._v(" New Villa ")])],1)]),_c('div',{staticClass:"card-body pt-0 pb-3"},[(_vm.isLoaded)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-head-custom table-head-bg table-vertical-center table-borderless"},[_vm._m(1),_c('tbody',_vm._l((_vm.villas),function(villa,key){return _c('tr',{key:villa.id},[_c('td',{staticClass:"pl-0 py-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-50 flex-shrink-0 mr-4"},[_c('div',{staticClass:"symbol-label",style:({
                        'background-image':
                          'url(' +
                          _vm.appPath +
                          '/media/cache/resolve/50x50/' +
                          villa.featuredImage.src +
                          ')'
                      })})]),_c('div',[_c('router-link',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg",attrs:{"to":'/villas/edit/' + villa.id}},[_vm._v(" "+_vm._s(villa.title)+" ")]),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_c('div',[_c('strong',[_vm._v("Created: ")]),_c('span',[_vm._v(_vm._s(_vm._f("moment")(villa.createdAt,'DD.MM.YYYY')))])])])],1)])]),_c('td',{staticClass:"text-right"},[_c('LiSwitch',{attrs:{"is-checked":villa.isFeatured,"classes":"switch-sm switch-icon float-right","name":"villaIsFeatured"},on:{"checkedEvent":function($event){return _vm.updateIsFeatured(villa.isFeatured, villa.id, key)}}})],1),_c('td',[_c('LiSwitch',{attrs:{"is-checked":villa.isPublish,"classes":"switch-sm switch-icon switch-outline switch-success float-right","name":"villaIsPublish"},on:{"checkedEvent":function($event){return _vm.updateIsPublish(villa.isPublish, villa.id, key)}}})],1),_c('td',{staticClass:"pr-0 text-right"},[_c('a',{staticClass:"btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3",attrs:{"href":_vm.appPath + '/en/villas/' + villa.slug,"target":"_blank"}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Visible.svg"}})],1)]),_c('router-link',{staticClass:"btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3",attrs:{"to":'/villas/edit/' + villa.id}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Design/Edit.svg"}})],1)]),_c('router-link',{staticClass:"btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2",attrs:{"to":{
                    name: 'villas-edit-translations',
                    params: { id: villa.id }
                  }}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/translate.svg","title":"Edit Translations","aria-label":"Edit Translations"}})],1)]),_c('button',{staticClass:"btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3",on:{"click":function($event){return _vm.copyEntity(villa)}}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Duplicate.svg"}})],1)]),_c('button',{staticClass:"btn btn-icon btn-light btn-hover-light-primary btn-sm",on:{"click":function($event){return _vm.removeEntity(villa.id)}}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Trash.svg"}})],1)])],1)])}),0)])]):_c('div',{staticClass:"w-100 d-flex justify-content-center my-10"},[_vm._m(2)])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"card-title"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v("Villas")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"bg-gray-100 text-left"},[_c('th',{staticClass:"pl-7",staticStyle:{"min-width":"250px"}},[_c('span',{staticClass:"text-dark-75"},[_vm._v("Title")])]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"120px"}},[_vm._v("Featured")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"120px"}},[_vm._v("Is Publish")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"110px"}},[_vm._v("Actions")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2 text-muted"},[_vm._v("Loading...")]),_c('div',{staticClass:"spinner spinner-primary mr-10"})])}]

export { render, staticRenderFns }