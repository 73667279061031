var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.isLoaded)?_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-row-fluid mr-lg-8"},[_c('notifications',{attrs:{"group":"notification","position":"bottom right"}}),_c('div',{staticClass:"card card-custom card-sticky gutter-b"},[_c('div',{staticClass:"card-header border-0",staticStyle:{"position":"sticky","top":"119px","background-color":"#fff","z-index":"50"}},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" Edit Page ")])]),_c('CardToolbar',{attrs:{"back-text":"All Pages","back-url":"/pages/list","edit-text":"Edit Page","button-text":"Save Page"},on:{"submitEvent":_vm.submit}})],1),_c('div',{staticClass:"card-body pt-0"},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mb-8"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.page.translations.en.title),expression:"page.translations.en.title"}],class:[
                    'form-control form-control-solid form-control-lg',
                    { 'is-invalid': errors[0] }
                  ],attrs:{"type":"text","placeholder":"Page Title "},domProps:{"value":(_vm.page.translations.en.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.page.translations.en, "title", $event.target.value)}}})])]}}],null,true)}),_c('div',{staticClass:"form-group mb-8"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Slug")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.page.translations.en.slug),expression:"page.translations.en.slug"}],staticClass:"form-control form-control-solid form-control-lg",attrs:{"type":"text","placeholder":"Slug"},domProps:{"value":(_vm.page.translations.en.slug)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.page.translations.en, "slug", $event.target.value)}}})]),_c('div',{staticClass:"form-group mb-8"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Content")]),_c('quill-editor',{attrs:{"content":_vm.page.translations.en.content,"options":_vm.contentEditorOption},on:{"change":function($event){return _vm.onEditorChange($event)}}})],1),_c('seo',{attrs:{"meta":_vm.page.translations.en.seoMeta,"entity-url":'https://likeistria.com/en/pages/' + _vm.page.slug}})],1)])],1),_c('div',{staticClass:"flex-column offcanvas-mobile w-300px w-xl-325px"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header border-0 pt-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v("Options")])])]),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-6 col-form-label"},[_vm._v("Publish")]),_c('div',{staticClass:"col-6"},[_c('LiSwitch',{attrs:{"is-checked":_vm.page.isPublish,"classes":"switch-icon float-right","name":"pageIsPublish"},on:{"checkedEvent":_vm.updateIsPublish}})],1)])])])])])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }