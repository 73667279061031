<template>
  <div>
    <div class="card card-custom gutter-b">
      <notifications group="notification" position="bottom right" />

      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Tags</span>
        </h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link
            to="/tags/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
            </span>
            New Tag
          </router-link>
          <!--end::Button-->
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <!--begin::Table-->
        <div v-if="isLoaded" class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center table-borderless"
          >
            <thead>
              <tr class="bg-gray-100 text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span @click="orderByName" class="cursor-pointer">
                    <span
                      class="pt-2"
                      :class="{ 'text-primary': nameOrder.isActive }"
                    >
                      Title</span
                    >
                    <span
                      class="svg-icon svg-icon-sm  pl-2"
                      :class="{
                        'svg-icon-primary': nameOrder.isActive
                      }"
                    >
                      <inline-svg
                        v-if="nameOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th style="min-width: 110px" class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="tag in tags" :key="tag.id">
                <td class="pl-7 py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <router-link
                        :to="'/tags/edit/' + tag.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {{ tag.title }}
                      </router-link>
                    </div>
                  </div>
                </td>
                <td class="pr-7 text-right">
                  <router-link
                    :to="'/tags/edit/' + tag.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/Design/Edit.svg" />
                    </span>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'tags-edit-translations',
                      params: { id: tag.id }
                    }"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/translate.svg"
                        title="Edit Translations"
                        aria-label="Edit Translations"
                      />
                    </span>
                  </router-link>
                  <button
                    @click="removeEntity(tag.id)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <div v-else class="w-100 d-flex justify-content-center my-10">
          <div class="d-flex align-items-center">
            <div class="mr-2 text-muted">Loading...</div>
            <div class="spinner spinner-primary mr-10"></div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data() {
    return {
      tags: [],
      nameOrder: {
        query: 'asc',
        isActive: false
      },
      isLoaded: false,
      imagePath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Tags' }])

    // get all tags
    ApiService.get(
      'tags',
      `?order[translations.title]=${this.nameOrder.query}`
    ).then(({ data }) => {
      this.tags = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {
    orderByName() {
      this.nameOrder.isActive = true

      if (this.nameOrder.query === 'asc') this.nameOrder.query = 'desc'
      else this.nameOrder.query = 'asc'

      this.isLoaded = false
      this.tags = []

      ApiService.get(
        'tags',
        `?order[translations.title]=${this.nameOrder.query}&itemsPerPage=100`
      ).then(({ data }) => {
        this.tags = [...data['hydra:member']]
        this.isLoaded = true
      })
    },
    async deleteEntity(id) {
      try {
        return await ApiService.delete('tags/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removeEntity(id) {
      const confirmation = confirm('Are you sure?')
      if (confirmation === false) return

      this.deleteEntity(id).then(({ status }) => {
        if (status === 204) {
          this.tags = this.tags.filter(tag => tag.id !== id)
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Tag deleted'
          })
        }
      })
    }
  }
}
</script>
