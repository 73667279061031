<template>
  <div :key="facilitiesRerenderHack" class="card card-custom gutter-b">
    <div class="card-header border-0">
      <div class="card-title">
        <h3 class="card-label">Facilities</h3>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="form-group mb-8">
        <label class="font-weight-bolder">Facilities</label>

        <div
          v-for="(facility, groupIdx) in facilities"
          :key="groupIdx"
          class="form-group row"
        >
          <div class="col-10">
            <div class="form-group">
              <input
                class="form-control form-control-lg form-control-solid"
                type="text"
                style="font-weight: bold"
                placeholder="Group Name"
                v-model="facility.name"
              />
            </div>
          </div>

          <div class="col-lg-2" style="text-align: right;">
            <a
              @click="removeFacilitiesGroup(groupIdx)"
              href="javascript:void(0)"
              class="btn font-weight-bold btn-danger btn-icon"
            >
              <i class="la la-remove"></i>
            </a>
          </div>

          <div class="col-12">
            <div
              class="form-group row"
              v-for="(facilityItem, facilityItemIdx) in facility.items"
              :key="facilityItemIdx"
            >
              <div class="col-md-10">
                <div class="input-group">
                  <input
                    class="form-control form-control-solid"
                    type="text"
                    placeholder="Item Name"
                    v-model="facilityItem.content"
                  />
                </div>
              </div>
              <div class="col-lg-2" style="text-align: right;">
                <a
                  href="javascript:void(0)"
                  @click="removeSubFacilitiesItem(groupIdx, facilityItemIdx)"
                  class="btn font-weight-bold btn-danger btn-icon"
                >
                  <i class="la la-remove"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="col-12">
            <button
              @click="addSubFacilitiesItem(groupIdx)"
              type="button"
              class="btn btn-light-primary font-weight-bold btn-block"
            >
              Add
            </button>
          </div>

          <div class="col-12">
            <div class="separator separator-dashed mt-10 mb-4"></div>
          </div>
        </div>

        <button
          @click="addFacilitiesGroup"
          type="button"
          class="btn btn-light-primary font-weight-bold btn-block"
        >
          Add Facilities Group
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Facilities',
  props: {
    facilities: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      facilitiesRerenderHack: 0
    }
  },
  methods: {
    addFacilitiesGroup() {
      this.$emit('addFacilitiesGroup')
      this.forceRerender()
    },
    addSubFacilitiesItem(groupIndex) {
      this.$emit('addSubFacilitiesItem', groupIndex)
      this.forceRerender()
    },
    removeSubFacilitiesItem(groupIndex, itemIndex) {
      this.$emit('removeSubFacilitiesItem', groupIndex, itemIndex)
      this.forceRerender()
    },
    removeFacilitiesGroup(groupIndex) {
      this.$emit('removeFacilitiesGroup', groupIndex)
      this.forceRerender()
    },
    forceRerender() {
      this.facilitiesRerenderHack += 1
    }
  }
}
</script>
