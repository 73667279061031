<template>
  <div>
    <div class="row">
      <div
        v-for="user in users"
        :key="user.id"
        class="col-xl-3 col-lg-6 col-md-6 col-sm-6"
      >
        <!--begin::Card-->
        <div class="card card-custom gutter-b card-stretch">
          <!--begin::Body-->
          <div class="card-body pt-10">
            <!--begin::User-->
            <div class="d-flex align-items-center mb-7">
              <!--begin::Pic-->
              <router-link
                :to="'/users/edit/' + user.id"
                class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
              >
                <div class="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                  <div class="symbol symbol-circle symbol-lg-75 d-none">
                    <img src="media/users/300_10.jpg" alt="image" />
                  </div>
                  <div class="symbol symbol-lg-75 symbol-circle symbol-primary">
                    <span
                      v-if="user.personalInfo.name && user.personalInfo.surname"
                      class="symbol-label font-size-h3 font-weight-boldest"
                      >{{ user.personalInfo.name.substring(0, 1)
                      }}{{ user.personalInfo.surname.substring(0, 1) }}</span
                    >
                  </div>
                </div>
              </router-link>
              <!--end::Pic-->
              <!--begin::Title-->
              <div class="d-flex flex-column">
                <router-link
                  :to="'/users/edit/' + user.id"
                  class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
                >
                  {{ user.personalInfo.name }}
                  {{ user.personalInfo.surname }}
                </router-link>
                <span class="text-muted font-weight-bold">{{
                  user.personalInfo.job
                }}</span>
              </div>
              <!--end::Title-->
            </div>
            <!--end::User-->
            <!--begin::Desc-->
            <!--            <p class="mb-7">I distinguish three-->
            <!--              <a href="#" class="text-primary pr-1">#XRS-54PQ</a>objectives First merely first-->
            <!--              <span class="text-dark-75 font-weight-bolder pr-1">USD342/Annual</span>-->
            <!--            </p>-->
            <!--end::Desc-->
            <!--begin::Info-->
            <div class="mb-7" v-if="user.personalInfo">
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-dark-75 font-weight-bolder mr-2">Email:</span>
                <a href="#" class="text-muted text-hover-primary">{{
                  user.email
                }}</a>
              </div>
              <div
                v-if="user.personalInfo.phone"
                class="d-flex justify-content-between align-items-cente my-2"
              >
                <span class="text-dark-75 font-weight-bolder mr-2">Phone:</span>
                <a href="#" class="text-muted text-hover-primary">{{
                  user.personalInfo.phone
                }}</a>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-dark-75 font-weight-bolder mr-2"
                  >Company:</span
                >
                <span class="text-muted font-weight-bold">{{
                  user.personalInfo.company_name
                }}</span>
              </div>
            </div>
            <!--end::Info-->
            <button
              @click="toggleUserVillasModal(user.id)"
              href="#"
              class="btn btn-block btn-sm btn-light-primary font-weight-bolder text-uppercase py-4"
            >
              View Villas
            </button>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Card-->
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-modal id="user_villas_modal" centered hide-header hide-footer>
          <template>
            <div v-if="usersVillas.length > 0" class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center table-borderless"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-7">
                          <span class="text-dark-75">Title</span>
                        </th>
                        <th style="min-width: 110px" class="text-right">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(villa, key) in usersVillas" :key="key">
                        <td class="pl-0 py-2">
                          <div class="d-flex align-items-center">
                            <div class="symbol symbol-50 flex-shrink-0 mr-4">
                              <div
                                class="symbol-label"
                                :style="{
                                  'background-image':
                                    'url(' +
                                    appPath +
                                    '/media/cache/resolve/50x50/' +
                                    villa.featuredImage.src +
                                    ')'
                                }"
                              ></div>
                            </div>
                            <div>
                              <router-link
                                :to="'/villas/edit/' + villa.id"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              >
                                {{ villa.title }}
                              </router-link>
                              <span class="text-muted font-weight-bold d-block">
                                <div>
                                  <strong>Created: </strong
                                  ><span>{{
                                    villa.createdAt | moment('DD.MM.YYYY')
                                  }}</span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td class="pr-0 text-right">
                          <router-link
                            :to="'/villas/edit/' + villa.id"
                            class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Design/Edit.svg"
                              />
                            </span>
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12">
                <router-link
                  to="/villas/new"
                  class="btn btn-light-primary btn-block"
                  >Add New Villa</router-link
                >
              </div>
            </div>
            <div v-else class="row">
              <div class="col-12">
                <div class="alert alert-primary" role="alert">
                  This User currently has no villas...
                </div>
              </div>
              <div class="col-12">
                <router-link
                  to="/villas/new"
                  class="btn btn-light-primary btn-block"
                  >Add Villa</router-link
                >
              </div>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  components: {},
  data() {
    return {
      users: [],
      usersVillas: [],
      isLoaded: false,
      appPath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Users', route: '' }])

    // get all users
    ApiService.get('users').then(({ data }) => {
      this.users = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {
    toggleUserVillasModal(userID) {
      this.usersVillas = []
      ApiService.get('villas', `?owner=${userID}`).then(({ data }) => {
        this.usersVillas = [...data['hydra:member']]

        this.$bvModal.show('user_villas_modal')
      })
    }
  }
}
</script>

<style lang="scss"></style>
