var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-row-fluid mr-lg-8"},[_c('notifications',{attrs:{"group":"notification","position":"bottom right"}}),_c('div',{staticClass:"card card-custom card-sticky gutter-b"},[_c('div',{staticClass:"card-header border-0",staticStyle:{"position":"sticky","top":"119px","background-color":"#fff","z-index":"50"}},[_c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" Add New Tag "),_c('i',{staticClass:"mr-2"}),_c('small',{})])]),_c('CardToolbar',{attrs:{"back-text":"All Tags","back-url":"/tags/list","button-text":"Tag","action-text":"Add"},on:{"submitEvent":_vm.submit}})],1),_c('div',{staticClass:"card-body pt-0"},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mb-8"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tag.translations.en.title),expression:"tag.translations.en.title"}],class:[
                    'form-control form-control-solid form-control-lg',
                    { 'is-invalid': errors[0] }
                  ],attrs:{"type":"text","placeholder":"Tag Title"},domProps:{"value":(_vm.tag.translations.en.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tag.translations.en, "title", $event.target.value)}}})])]}}],null,true)})],1)])],1),_c('div',{staticClass:"flex-column offcanvas-mobile w-300px w-xl-325px"})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }