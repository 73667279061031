<template>
  <div class="card card-custom gutter-b">
    <div class="card-header border-0">
      <div class="card-title">
        <h3 class="card-label">Price Ranges</h3>
      </div>
    </div>
    <div class="card-body pt-0">
      <div
        v-for="(range, index) in priceRanges"
        :key="index"
        class="form-group row option py-3 px-0"
      >
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group mb-0">
                <div class="input-group">
                  <datePicker
                    v-model="range.start"
                    placeholder="From"
                    :config="options"
                    class="form-control"
                  >
                  </datePicker>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-0">
                <div class="input-group">
                  <datePicker
                    v-model="range.end"
                    placeholder="To"
                    :config="options"
                    class="form-control"
                  >
                  </datePicker>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-0">
                <div class="input-group">
                  <input
                    v-model="range.pricePerNight"
                    type="text"
                    placeholder="PPN"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-0">
                <select class="form-control" v-model="range.minStay">
                  <option>Min stay</option>
                  <option value="1n">1 night</option>
                  <option value="2n">2 night</option>
                  <option value="3n">3 night</option>
                  <option value="4n">4 night</option>
                  <option value="5n">5 night</option>
                  <option value="6n">6 night</option>
                  <option value="7n">7 night</option>
                  <option value="s_to_s">Saturday to saturday</option>
                </select>
              </div>
            </div>
            <div class="col-md-1">
              <LiSwitch
                @checkedEvent="toggleSpecialOffer(index)"
                :is-checked="range.isSpecialOffer"
                classes="switch-icon switch-sm float-right mt-2"
                name="villaIsPublish"
              />
            </div>
            <div class="col-md-1">
              <button
                @click="removeRange(index)"
                class="btn btn-sm font-weight-bold btn-danger btn-icon mt-1"
              >
                <i class="la la-remove"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="separator separator-dashed my-5"></div>
          <div
            v-for="(subRange, key) in range.subRanges"
            :key="key"
            class="row my-5"
          >
            <div class="col-md-3">
              <div class="form-group mb-0">
                <div class="input-group">
                  <datePicker
                    v-model="subRange.start"
                    placeholder="From"
                    :config="options"
                    class="form-control"
                  >
                  </datePicker>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-0">
                <div class="input-group">
                  <datePicker
                    v-model="subRange.end"
                    placeholder="To"
                    :config="options"
                    class="form-control"
                  >
                  </datePicker>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group mb-0">
                <div class="input-group">
                  <input
                    v-model="subRange.discount"
                    type="text"
                    placeholder="Discount"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-0">
                <select class="form-control" v-model="subRange.minStay">
                  <option>Min stay</option>
                  <option value="1n">1 night</option>
                  <option value="2n">2 night</option>
                  <option value="3n">3 night</option>
                  <option value="4n">4 night</option>
                  <option value="5n">5 night</option>
                  <option value="6n">6 night</option>
                  <option value="7n">7 night</option>
                  <option value="s_to_s">Saturday to saturday</option>
                </select>
              </div>
            </div>
            <div class="col-md-1">
              <button
                @click="removeSubRange(index, key)"
                class="btn btn-sm font-weight-bold btn-danger btn-icon mt-1"
              >
                <i class="la la-remove"></i>
              </button>
            </div>
          </div>
          <div class="separator separator-dashed my-5"></div>
          <div class="row mt-3">
            <div class="col-md-12">
              <button
                @click="addSubRange(index)"
                class="btn btn-light-primary btn-block"
              >
                Add Subrange
              </button>
            </div>
          </div>
          <div class="separator separator-dashed my-5"></div>
        </div>
      </div>
      <button
        @click="addRange"
        type="button"
        class="btn btn-light-primary font-weight-bold btn-block"
      >
        Add Range
      </button>
    </div>
  </div>
</template>

<script>
import LiSwitch from '@/view/content/components/LiSwitch'
import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'

export default {
  name: 'PriceRanges',
  components: {
    LiSwitch,
    datePicker
  },
  props: {
    priceRanges: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      options: {
        format: 'DD.MM.YYYY.',
        useCurrent: false
      },
      days: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]
    }
  },
  methods: {
    addRange() {
      this.$emit('addRange')
    },
    removeRange(rangeIndex) {
      this.$emit('removeRange', rangeIndex)
    },
    toggleSpecialOffer(rangeIndex) {
      this.$emit('toggleSpecialOffer', rangeIndex)
    },
    addSubRange(rangeIndex) {
      this.$emit('addSubRange', rangeIndex)
    },
    removeSubRange(rangeIndex, subRangeIndex) {
      this.$emit('removeSubRange', rangeIndex, subRangeIndex)
    }
  }
}
</script>
