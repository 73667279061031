<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="d-flex flex-row">
        <div class="flex-row-fluid mr-lg-8">
          <notifications group="notification" position="bottom right" />

          <div class="card card-custom card-sticky gutter-b">
            <div
              class="card-header border-0"
              style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
            >
              <div class="card-title">
                <h3 class="card-label">
                  Edit Category
                </h3>
              </div>
              <CardToolbar
                @submitEvent="submit"
                back-text="All Categories"
                back-url="/categories/list"
                button-text="Category"
                action-text="Save"
                :dropdown-enabled="false"
              />
            </div>
            <!--begin::Body-->
            <div class="card-body pt-0">
              <ValidationProvider
                name="Title"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Title</label>
                  <input
                    v-model="category.translations.en.title"
                    type="text"
                    :class="[
                      'form-control form-control-solid form-control-lg',
                      { 'is-invalid': errors[0] }
                    ]"
                    placeholder="Enter Post Title "
                  />
                </div>
              </ValidationProvider>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Subtitle</label>
                <input
                  v-model="category.translations.en.subtitle"
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  placeholder="Enter Post Subtitle"
                />
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Description</label>
                <quill-editor
                  class="category-description"
                  :content="category.translations.en.description"
                  :options="contentEditorOption"
                  @change="onEditorChange($event)"
                />
              </div>

              <div class="row">
                <div class="col-md-12">
                  <b-card no-body class="border-0">
                    <b-tabs pills vertical nav-wrapper-class="col-3">
                      <b-tab active>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-rocket-1"></i>
                          </span>
                          <span class="font-weight-bolder text-dark-75"
                            >Multimedia</span
                          >
                        </template>
                        <b-card-text>
                          <div class="card card-custom gutter-b">
                            <div class="card-header border-0">
                              <div class="card-title">
                                <h3 class="card-label">Multimedia</h3>
                              </div>
                            </div>
                            <div class="card-body pt-0">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Featured Image</label
                                >

                                <Dropzone
                                  class="dropzone dropzone-default dropzone-primary dz-clickable h-90px w-90px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0"
                                  ref="villaFeaturedImageDropzone"
                                  @vdropzone-file-added="featuredImageAdd"
                                  @vdropzone-complete="featuredImageComplete"
                                  @vdropzone-success="featuredImageSuccess"
                                  id="villaFeaturedImage"
                                  :options="featuredImageDropzoneOptions"
                                  :useCustomSlot="true"
                                >
                                  <span class="svg-icon svg-icon-lg m-0">
                                    <inline-svg
                                      src="media/svg/icons/Design/Image.svg"
                                    />
                                  </span>
                                </Dropzone>

                                <div
                                  v-if="category.featuredImage.src"
                                  class="symbol symbol-90 flex-shrink-0 mr-4 cursor-pointer"
                                >
                                  <div
                                    class="symbol-label"
                                    v-bind:style="{
                                      'background-image':
                                        'url(' +
                                        imagePath +
                                        category.featuredImage.src +
                                        ')'
                                    }"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="flex-column offcanvas-mobile w-300px w-xl-325px">
          <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Options</span
                >
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-0">
              <div class="row">
                <label class="col-6 col-form-label">EX category</label>
                <div class="col-6">
                  <LiSwitch
                    @checkedEvent="updateIsExperienceCategory"
                    :is-checked="category.isExperienceCategory"
                    classes="switch-icon float-right"
                    name="categoryIsExperienceCategory"
                  />
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ApiService from '@/core/services/api.service'

export default {
  components: {
    LiSwitch,
    CardToolbar,
    Dropzone,
    'quill-editor': quillEditor
  },
  data() {
    return {
      category: {
        translations: {
          en: {
            locale: 'en',
            title: '',
            subtitle: '',
            description: ''
          }
        },
        featuredImage: {
          src: '',
          alt: ''
        },
        isExperienceCategory: false
      },
      featuredImageDropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 1
      },
      contentEditorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean', 'link']
          ]
        }
      },
      imagePath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Categories', route: '/categories/list' },
      { title: 'Edit Category' }
    ])

    ApiService.get(
      'categories',
      this.$route.params.id + '?groups[]=translations'
    ).then(({ data }) => {
      this.category.translations = data.translations
      this.category.translations.en = {}
      this.category.translations.en.locale = 'en'
      this.category.translations.en.slug = data.slug
      this.category.translations.en.title = data.title
      this.category.translations.en.subtitle = data.subtitle
      this.category.translations.en.description = data.description
      this.category.featuredImage.src = data.featuredImage.src
      this.category.featuredImage.alt = data.featuredImage.alt
      this.category.isExperienceCategory = data.isExperienceCategory
    })
  },
  methods: {
    onSubmit() {
      ApiService.update(
        'categories',
        this.$route.params.id,
        this.category
      ).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Category saved.'
        })
      })
    },
    submit() {},
    featuredImageAdd() {},
    featuredImageSuccess(_, response) {
      this.category.featuredImage.src = '/uploads/' + response
    },
    featuredImageComplete() {},
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      this.category.translations.en.description = html
    },
    updateIsExperienceCategory(checked) {
      this.category.isExperienceCategory = checked
    }
  }
}
</script>

<style lang="scss">
.category-description {
  .ql-editor {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 0 10px 0;
      font-weight: bold;
    }
    p {
      padding: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      font-family: Poppins, Helvetica, 'sans-serif';
    }
    ul {
      li {
        font-family: Poppins, Helvetica, 'sans-serif';
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
  .ql-editor.ql-blank {
    min-height: 350px;
  }
}
</style>
