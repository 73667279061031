<template>
  <div class="form-group mb-2">
    <label class="font-weight-bolder">Meta</label>
    <div
      v-for="(metaItem, index) in meta.items"
      :key="index"
      class="form-group row"
    >
      <div class="col-md-12">
        <div class="form-group">
          <input
            class="form-control form-control-solid"
            type="text"
            placeholder="Group Name"
            v-model="metaItem.name"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <input
            class="form-control form-control-solid"
            type="text"
            placeholder="Name"
            v-model="metaItem.content.name"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <input
            class="form-control form-control-solid"
            type="text"
            placeholder="Value"
            v-model="metaItem.content.value"
          />
        </div>
      </div>
    </div>
    <button
      type="button"
      @click="addItem"
      class="btn btn-light-primary font-weight-bold btn-block"
    >
      Add Item
    </button>
    <div class="separator separator-dashed mt-8 mb-8"></div>
    <textarea
      v-model="meta.description"
      class="form-control"
      placeholder="Description"
      rows="8"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  name: 'LocationMeta',
  props: {
    meta: {
      type: Object,
      required: true
    }
  },
  methods: {
    addItem() {
      this.$emit('addLocationMetaItem')
    }
  }
}
</script>
