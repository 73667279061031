<template>
  <div class="card card-custom gutter-b">
    <div class="card-header border-0">
      <div class="card-title">
        <h3 class="card-label">Experiences</h3>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="list-box-wrapper">
        <div class="list-box-item">
          <div class="search-box">
            <input
              v-model="searchSource"
              class="form-control form-control-solid"
              type="text"
              placeholder="Search"
            />
            <div
              v-if="searchSource"
              class="clear-search"
              title="Clear Search"
              @click="searchSource = ''"
            >
              &times;
            </div>
          </div>
          <ul class="list-box">
            <li
              v-for="(item, key) in source
                .map((item, inx) => ({ inx, ...item }))
                .filter(item =>
                  item[label in item ? label : 'label']
                    .toLowerCase()
                    .includes(searchSource.toLowerCase())
                )"
              v-bind:key="key"
              :class="'list-item' + (item.selected ? ' active' : '')"
              @click="selectSource(searchSource ? item.inx : key)"
            >
              {{ item[label in item ? label : 'label'] }}
            </li>
            <li
              v-if="
                source.filter(item =>
                  item[label in item ? label : 'label']
                    .toLowerCase()
                    .includes(searchSource.toLowerCase())
                ).length == 0 && source.length
              "
              class="list-item"
            >
              No results found
            </li>
          </ul>
          <div class="bulk-action">
            <div class="select-all" @click="selectAllSource">Select All</div>
            <div class="deselect-all" @click="deSelectAllSource">None</div>
          </div>
        </div>
        <div class="actions">
          <div class="btn-action" @click="moveDestination">
            <i class="flaticon2-next"></i>
          </div>
          <div class="btn-action" @click="moveSource">
            <i class="flaticon2-back"></i>
          </div>
        </div>
        <div class="list-box-item">
          <div class="search-box">
            <input
              v-model="searchDestination"
              class="form-control form-control-solid"
              type="text"
              placeholder="Search"
            />
            <div
              v-if="searchDestination"
              class="clear-search"
              title="Clear Search"
              @click="searchDestination = ''"
            >
              &times;
            </div>
          </div>
          <ul class="list-group list-group-flush list-box">
            <li
              v-for="(item, key) in destination
                .map((item, inx) => ({ inx, ...item }))
                .filter(item =>
                  item[label in item ? label : 'label']
                    .toLowerCase()
                    .includes(searchDestination.toLowerCase())
                )"
              v-bind:key="key"
              :class="'list-item' + (item.selected ? ' active' : '')"
              @click="selectDestination(searchDestination ? item.inx : key)"
            >
              {{ item[label in item ? label : 'label'] }}
            </li>
            <li
              v-if="
                destination.filter(item =>
                  item[label in item ? label : 'label']
                    .toLowerCase()
                    .includes(searchDestination.toLowerCase())
                ).length == 0 && destination.length
              "
              class="list-item"
            >
              No results found
            </li>
          </ul>
          <div class="bulk-action">
            <div class="select-all" @click="selectAllDestination">
              Select All
            </div>
            <div class="deselect-all" @click="deSelectAllDestination">None</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import angleRight from "../assets/angle-right-solid.svg";
// import angleLeft from "../assets/angle-left-solid.svg";
// import angleDoubleLeft from "../assets/angle-double-left-solid.svg";
// import angleDoubleRight from "../assets/angle-double-right-solid.svg";
// import "../assets/style.css";

export default {
  props: {
    source: Array,
    destination: Array,
    label: String
  },
  data: function() {
    return {
      searchSource: '',
      searchDestination: ''
    }
  },
  methods: {
    moveDestination: function() {
      let selected = this.source.filter(f => f.selected)
      if (!selected.length) return
      selected = selected.map(item => ({
        ...item,
        selected: false
      }))
      let destination = [...selected, ...this.destination]
      let source = this.source.filter(f => !f.selected)
      let type = 'moveDestination'
      this.searchSource = ''
      this.searchDestination = ''
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    },
    moveSource: function() {
      let selected = this.destination.filter(f => f.selected)
      if (!selected.length) return
      selected = selected.map(item => ({
        ...item,
        selected: false
      }))
      let source = [...selected, ...this.source]
      let destination = this.destination.filter(f => !f.selected)
      let type = 'moveSource'
      this.searchSource = ''
      this.searchDestination = ''
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    },
    moveAllDestination: function() {
      let destination = [
        ...this.source.map(item => ({ ...item, selected: false })),
        ...this.destination
      ]
      let source = []
      let type = 'moveAllDestination'
      this.searchSource = ''
      this.searchDestination = ''
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    },
    moveAllSource: function() {
      let source = [
        ...this.destination.map(item => ({ ...item, selected: false })),
        ...this.source
      ]
      let destination = []
      let type = 'moveAllSource'
      this.searchSource = ''
      this.searchDestination = ''
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    },
    selectDestination: function(key) {
      let source = this.source
      let destination = this.destination.map((i, k) => {
        if (k === key) {
          i.selected = !i.selected
        }
        return i
      })
      let type = 'selectDestination'
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    },
    selectSource: function(key) {
      let destination = this.destination
      let source = this.source.map((i, k) => {
        if (k === key) {
          i.selected = !i.selected
        }
        return i
      })
      let type = 'selectSource'
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    },
    selectAllSource: function() {
      let source = this.source.map(item => ({ ...item, selected: true }))
      let destination = this.destination
      let type = 'selectAllSource'
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    },
    deSelectAllSource: function() {
      let source = this.source.map(item => ({ ...item, selected: false }))
      let destination = this.destination
      let type = 'deSelectAllSource'
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    },
    selectAllDestination: function() {
      let destination = this.destination.map(item => ({
        ...item,
        selected: true
      }))
      let source = this.source
      let type = 'selectAllDestination'
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    },
    deSelectAllDestination: function() {
      let destination = this.destination.map(item => ({
        ...item,
        selected: false
      }))
      let source = this.source
      let type = 'deSelectAllDestination'
      this.$emit('onChangeList', {
        source,
        destination,
        type
      })
    }
  }
}
</script>

<style lang="scss">
.list-box-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  & > div {
    flex: 1;
  }
  .list-box-item {
    //border: solid 1px #cccccc;
    //border-radius: 3px;
    .search-box {
      //border-bottom: solid 1px #cccccc;
      position: relative;
      input {
        border: none;
        width: 100%;
        padding: 0.5rem 1rem;
      }
      .clear-search {
        position: absolute;
        padding: 0.5rem;
        right: 0;
        top: 0;
        cursor: pointer;
        font-weight: bold;
        color: #e74c3c;
      }
    }
    .list-box {
      height: 250px;
      overflow: auto;
      list-style: none;
      padding: 0;
      margin: 0;
      border: solid 1px #cccccc;
      .list-item {
        padding: 0.5rem 1rem;
        border-bottom: solid 1px #cccccc;
        cursor: pointer;
        // &:last-child {
        //   border: none;
        // }
        &.active {
          background-color: #eeeeee;
        }
      }
    }
    .bulk-action {
      display: flex;
      border-top: solid 1px #cccccc;
      text-align: center;
      .select-all {
        width: 100%;
        padding: 0.8rem;
        background-color: #3699ff;
        color: #fff;
      }
      .deselect-all {
        width: 100%;
        padding: 0.8rem;
        background-color: #6c757d;
        color: #fff;
      }
    }
  }
  .actions {
    flex-grow: 0;
    padding: 0 1rem;
    .btn-action {
      margin-bottom: 0.5rem;
    }
  }
}
.btn-action {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: block;
  width: 100%;
  color: #fff;
  background-color: #3699ff;
  border-color: #3699ff;
  cursor: pointer;
  svg {
    vertical-align: middle;
  }
}
</style>
