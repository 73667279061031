import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/core/services/store'
import ApiService from '@/core/services/api.service'
import ErrorService from '@/core/services/error.service'
import TranslationsService from '@/core/services/translations.service'
import { VERIFY_AUTH } from '@/core/services/store/auth.module'
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ValidationObserver } from 'vee-validate'

Vue.config.productionTip = false
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.use(require('vue-moment'))

// Global 3rd party plugins
import 'popper.js'
import 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'
window.PerfectScrollbar = PerfectScrollbar
import ClipboardJS from 'clipboard'
window.ClipboardJS = ClipboardJS

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n'
import '@/core/plugins/portal-vue'
import '@/core/plugins/bootstrap-vue'
import '@/core/plugins/perfect-scrollbar'
import '@/core/plugins/highlight-js'
import '@/core/plugins/inline-svg'
import '@/core/plugins/apexcharts'
import '@/core/plugins/treeselect'
import '@/core/plugins/metronic'
import '@mdi/font/css/materialdesignicons.css'
import '@/core/plugins/formvalidation'
import Notifications from 'vue-notification'

Vue.use(Notifications)

// Handle all Vue errors
Vue.config.errorHandler = error => ErrorService.onError(error)

// Setup TranslationsService
TranslationsService.init('en', ['en', 'de', 'hr'])

// API service init
ApiService.init()

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next)

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG)

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

new Vue({
  router,
  store,
  i18n,
  TranslationsService,
  render: h => h(App)
}).$mount('#app')
