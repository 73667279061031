<template>
  <div class="card card-custom gutter-b">
    <div class="card-header border-0">
      <div class="card-title">
        <h3 class="card-label">SEO</h3>
      </div>
    </div>
    <div class="card-body pt-0">
      <ul class="nav nav-pills" id="seo-tabs" role="tablist">
        <li class="nav-item" @click.prevent="toggleTab('tab-google')">
          <a
            class="nav-link active"
            id="seo-tab-google"
            data-toggle="tab"
            href="#tab-google"
          >
            <span class="nav-icon">
              <i class="socicon-google"></i>
            </span>
            <span class="nav-text"
              >Google
              <span
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-content="Search engines in general...(google, yahoo, bing, etc...)"
                >(*)</span
              >
            </span>
          </a>
        </li>
        <li class="nav-item" @click.prevent="toggleTab('tab-facebook')">
          <a
            class="nav-link"
            id="seo-tab-facebook"
            data-toggle="tab"
            href="#tab-facebook"
            aria-controls="profile"
          >
            <span class="nav-icon">
              <i class="socicon-facebook mr-5"></i>
            </span>
            <span class="nav-text">Facebook</span>
          </a>
        </li>
        <li class="nav-item" @click.prevent="toggleTab('tab-twitter')">
          <a
            class="nav-link"
            id="seo-tab-twitter"
            data-toggle="tab"
            href="#tab-twitter"
            aria-controls="profile"
          >
            <span class="nav-icon">
              <i class="socicon-twitter mr-5"></i>
            </span>
            <span class="nav-text">Twitter</span>
          </a>
        </li>
      </ul>

      <div class="tab-content mt-5" id="seo-tabs-content">
        <div
          class="js-tab-google tab-pane fade active show"
          id="tab-google"
          role="tabpanel"
          aria-labelledby="seo-tab-google"
        >
          <div class="form-group">
            <label for="meta-google-og-title">Title</label>
            <input
              v-model="meta.ogTitle"
              :disabled="this.$props.disabled"
              id="meta-google-og-title"
              class="form-control form-control-solid"
              type="text"
              placeholder="ogTitle"
            />
          </div>
          <div class="form-group">
            <label for="meta-google-description">Description</label>
            <textarea
              v-model="meta.description"
              :disabled="this.$props.disabled"
              id="meta-google-description"
              class="form-control form-control-solid"
              rows="3"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="meta-google-keywords">Keywords</label>
            <input
              v-model="meta.keywords"
              :disabled="this.$props.disabled"
              id="meta-google-keywords"
              class="form-control form-control-solid"
              type="text"
              placeholder="Keywords"
            />
          </div>
          <div id="google" class="metadata-group__display is-active">
            <h6 class="metadata-group__title"><span>Google</span></h6>
            <div class="card-seo-google">
              <span class="card-seo-google__title">{{ meta.ogTitle }}</span>
              <div class="card-seo-google__url">
                <span class="card-seo-google__url-title">{{ entityUrl }}</span>
                <span class="card-seo-google__url-arrow"></span>
              </div>
              <span class="card-seo-google__description">{{
                meta.description
              }}</span>
            </div>
          </div>
        </div>
        <div
          class="js-tab-facebook tab-pane fade"
          id="tab-facebook"
          role="tabpanel"
          aria-labelledby="seo-tab-facebook"
        >
          <div id="facebook" class="metadata-group__display is-active">
            <h6 class="metadata-group__title"><span>Facebook</span></h6>
            <div class="card-seo-facebook">
              <div
                class="card-seo-facebook__image"
                style="background-image:none"
              ></div>
              <div class="card-seo-facebook__text">
                <span class="card-seo-facebook__link">likeistria.com</span>
                <div class="card-seo-facebook__content">
                  <div style="margin-top:5px">
                    <div class="card-seo-facebook__title">
                      Like Istria — Preview, Edit and Generate
                    </div>
                  </div>
                  <span class="card-seo-facebook__description"
                    >With Meta Tags you can edit and experiment with your
                    content then preview how your webpage will look on Google,
                    Facebook, Twitter and more!</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="js-tab-twitter tab-pane fade"
          id="tab-twitter"
          role="tabpanel"
          aria-labelledby="seo-tab-twitter"
        >
          Twitter
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeoComponent',
  props: {
    meta: {
      type: Object,
      required: true
    },
    entityUrl: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      tabClasses: {
        active: 'active',
        show: 'show',
        google: '.js-tab-google',
        facebook: '.js-tab-facebook',
        twitter: '.js-tab-twitter'
      },
      tabIds: {
        google: 'seo-tab-google',
        facebook: 'seo-tab-facebook',
        twitter: 'seo-tab-twitter'
      }
    }
  },
  methods: {
    toggleTab(tab) {
      if (tab === 'tab-google') {
        document
          .getElementById(this.tabIds.facebook)
          .classList.remove(this.tabClasses.active)
        document
          .getElementById(this.tabIds.twitter)
          .classList.remove(this.tabClasses.active)

        document
          .getElementById(this.tabIds.google)
          .classList.add(this.tabClasses.active)

        document
          .querySelector(this.tabClasses.facebook)
          .classList.remove(this.tabClasses.active)
        document
          .querySelector(this.tabClasses.facebook)
          .classList.remove(this.tabClasses.show)
        document
          .querySelector(this.tabClasses.twitter)
          .classList.remove(this.tabClasses.active)
        document
          .querySelector(this.tabClasses.twitter)
          .classList.remove(this.tabClasses.show)

        document
          .querySelector(this.tabClasses.google)
          .classList.add(this.tabClasses.active)
        document
          .querySelector(this.tabClasses.google)
          .classList.add(this.tabClasses.show)
      }

      if (tab === 'tab-facebook') {
        document
          .getElementById(this.tabIds.google)
          .classList.remove(this.tabClasses.active)
        document
          .getElementById(this.tabIds.twitter)
          .classList.remove(this.tabClasses.active)

        document
          .getElementById(this.tabIds.facebook)
          .classList.add(this.tabClasses.active)

        document
          .querySelector(this.tabClasses.google)
          .classList.remove(this.tabClasses.active)
        document
          .querySelector(this.tabClasses.google)
          .classList.remove(this.tabClasses.show)
        document
          .querySelector(this.tabClasses.twitter)
          .classList.remove(this.tabClasses.active)
        document
          .querySelector(this.tabClasses.twitter)
          .classList.remove(this.tabClasses.show)

        document
          .querySelector(this.tabClasses.facebook)
          .classList.add(this.tabClasses.active)
        document
          .querySelector(this.tabClasses.facebook)
          .classList.add(this.tabClasses.show)
      }

      if (tab === 'tab-twitter') {
        document
          .getElementById(this.tabIds.google)
          .classList.remove(this.tabClasses.active)
        document
          .getElementById(this.tabIds.facebook)
          .classList.remove(this.tabClasses.active)

        document
          .getElementById(this.tabIds.twitter)
          .classList.add(this.tabClasses.active)

        document
          .querySelector(this.tabClasses.google)
          .classList.remove(this.tabClasses.active)
        document
          .querySelector(this.tabClasses.google)
          .classList.remove(this.tabClasses.show)
        document
          .querySelector(this.tabClasses.facebook)
          .classList.remove(this.tabClasses.active)
        document
          .querySelector(this.tabClasses.facebook)
          .classList.remove(this.tabClasses.active)

        document
          .querySelector(this.tabClasses.twitter)
          .classList.add(this.tabClasses.active)
        document
          .querySelector(this.tabClasses.twitter)
          .classList.add(this.tabClasses.show)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.metadata-group {
  &__display {
    display: none;
    position: relative;
  }

  &__display.is-active {
    display: block;
  }
}
.index-metadata__content h6 {
  display: flex;
  margin-bottom: 0.5em;
}
h6.metadata-group__title {
  position: relative;
  margin: 1.75em 0 1em;
}
h6.metadata-group__title:before {
  content: '';
  position: absolute;
  top: 10px;
  right: 0;
  width: 100%;
  height: 1px;
  background: #e0e7f1;
}
h6.metadata-group__title span {
  position: relative;
  background: #fff;
  padding-right: 12px;
}
.card-seo-google {
  position: relative;
  display: block;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
}
.card-seo-google__title {
  display: block;
  letter-spacing: normal;
  color: #1a0dab;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-seo-google__url-title {
  position: relative;
  top: -2px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: normal;
  color: #006621;
}
.card-seo-google__url-arrow {
  display: none;
  vertical-align: middle;
  margin-top: -4px;
  margin-left: 3px;
  border-width: 5px 4px 0 4px;
  border-style: solid;
  border-color: #006621 transparent;
}
.card-seo-google__url {
  display: block;
  height: 17px;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
  padding-bottom: 22px;
  margin-bottom: 6px;
}

.card-seo-google__description {
  color: #545454;
  font-size: 13px;
  line-height: 1.4;
  word-wrap: break-word;
}

// facebook
.index-metadata__content h4 {
  display: flex;
  margin-bottom: 0.5em;
}
h4.metadata-group__title {
  position: relative;
  margin: 1.75em 0 1em;
}
.card-seo-facebook {
  cursor: pointer;
  position: relative;
  display: block;
  background-color: #ffffff;
  overflow: hidden;
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular',
    sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
.card-seo-facebook__image {
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top: 1px solid #dadde1;
  border-bottom: 1px solid #dadde1;
}
.card-seo-facebook__image {
  border: 1px solid #dadde1;
  width: 500px;
  height: 261px;
}
.card-seo-facebook__text {
  padding: 10px 12px;
  color: #1d2129;
  background: #f2f3f5;
  width: 500px;
  border: 1px solid #dadde1;
  border-top-style: none;
}
.card-seo-facebook__link {
  border-collapse: separate;
  color: #606770;
  direction: ltr;
  display: block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  height: 11px;
  line-height: 11px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: left;
  text-overflow: ellipsis;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  width: 501px;
  word-wrap: break-word;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
  -webkit-font-smoothing: antialiased;
}
.card-seo-facebook__content {
  border-collapse: separate;
  color: #4b4f56;
  direction: ltr;
  display: block;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  height: 46px;
  line-height: 14.4px;
  max-height: 46px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: left;
  user-select: none;
  word-wrap: break-word;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
  -webkit-font-smoothing: antialiased;
}
.card-seo-facebook__title {
  margin-top: 5px;
  border-collapse: separate;
  color: #1d2129;
  cursor: pointer;
  direction: ltr;
  display: inline;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  letter-spacing: normal;
  line-height: 18px;
  text-align: left;
  text-decoration-color: #1d2129;
  text-decoration-line: none;
  text-decoration-style: solid;
  transition-delay: 0s;
  transition-duration: 0.1s;
  transition-property: color;
  transition-timing-function: ease-in-out;
  user-select: none;
  white-space: normal;
  width: auto;
  word-wrap: break-word;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
  -webkit-font-smoothing: antialiased;
}
.card-seo-facebook__description {
  border-collapse: separate;
  color: #606770;
  direction: ltr;
  display: -webkit-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 18px;
  line-height: 20px;
  margin-top: 3px;
  max-height: 80px;
  overflow-x: hidden;
  overflow-y: hidden;
  text-align: left;
  text-overflow: ellipsis;
  user-select: none;
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
  -webkit-box-orient: vertical;
  -webkit-font-smoothing: antialiased;
  -webkit-line-clamp: 1;
}

// tw
.card-seo-twitter {
  cursor: pointer;
  position: relative;
  display: block;
  background-color: #ffffff;
  border-style: solid;
  border-color: #e1e8ed;
  border-width: 1px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu,
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  width: 506px;
  border-radius: 0.42857em;
  margin-left: 0;
  overflow: hidden;
}

.card-seo-twitter__image {
  background-size: cover;
  background-color: #e1e8ed;
  background-repeat: no-repeat;
  background-position: center;
  border-style: solid;
  border-color: inherit;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  overflow: hidden;
  width: 504px;
  height: 252px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card-seo-twitter__text {
  padding: 0.75em 1em;
}
.card-seo-twitter__title {
  display: block;
  margin: 0 0 0.15em;
  letter-spacing: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.3em;
  max-height: 1.3em;
}
.card-seo-twitter__description {
  height: 2.6em;
  max-height: 2.6em;
  margin-top: 0.32333em;
  line-height: 1.3em;
  letter-spacing: normal;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}
.card-seo-twitter__link {
  display: block;
  text-transform: lowercase;
  line-height: 1.3125;
  letter-spacing: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.32333em;
  color: #8899a6;
}
</style>
