<template>
  <div>
    <div class="card card-custom gutter-b">
      <notifications group="notification" position="bottom right" />

      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark"
            >Reservations</span
          >
        </h3>
        <div class="card-toolbar">
          <button
            @click.prevent="reload"
            class="btn btn-light-primary font-weight-bolder font-size-sm mr-3 "
          >
            <span class="svg-icon svg-icon-md pl-2">
              <inline-svg src="media/svg/icons/Media/Repeat.svg" />
            </span>
          </button>
          <router-link
            to="/reservations/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg
                src="media/svg/icons/Communication/Clipboard-list.svg"
              />
            </span>
            New Reservation
          </router-link>
        </div>
      </div>
      <div class="card-body pt-0 pb-3">
        <div class="row">
          <div class="col-md-10">
            <vSelect
              class="form-control form-control--region-select"
              placeholder="Filter reservations by villa"
              @input="onSelectVilla"
              v-model="selectedVilla"
              :options="villas"
              :reduce="villa => villa['@id']"
              label="title"
            />
          </div>
          <div class="col-2">
            <button
              @click="viewAllVillas"
              class="btn btn-light-primary font-weight-bolder font-size-sm px-10"
              style="height: 46px; width: 100%"
            >
              View All
            </button>
          </div>
        </div>
        <div class="separator separator-dashed my-10"></div>
        <!--begin::Table-->
        <div v-if="isLoaded" class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center table-borderless"
          >
            <thead>
              <tr class="bg-gray-100 text-left">
                <th style="min-width: 100px" class="pl-7">
                  <span>Premise</span>
                </th>
                <th>From/To</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Guests</th>
                <th class="text-right">Price</th>
                <th class="text-right">Status</th>
                <th style="min-width: 110px" class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="reservation in reservations"
                :key="reservation.id"
                :class="{
                  is_finished:
                    (matchStatus(reservation.status, 4, '9') &&
                      matchStatus(reservation.status, 5, '9')) ||
                    matchStatus(reservation.status, 5, '8'),
                  is_new: matchStatus(reservation.status, 4, '0'),
                  is_waiting_remaining:
                    matchStatus(reservation.status, 4, '9') &&
                    matchStatus(reservation.status, 5, '0'),
                  is_cancelled:
                    matchStatus(reservation.status, 4, '1') ||
                    matchStatus(reservation.status, 5, '1'),
                  is_near_deadline:
                    matchStatus(reservation.status, 4, '4') ||
                    matchStatus(reservation.status, 5, '4')
                }"
              >
                <td>
                  <div class="d-flex align-items-center">
                    <div>
                      <router-link
                        :to="'/reservations/edit/' + reservation.id"
                        class="text-colored font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ reservation.villa.title }}</router-link
                      >
                      <div class="text-muted">
                        <span>Created: </span>
                        <span>{{ reservation.createdAt | formatDate }}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>{{ reservation.dateFrom | formatDate }}</div>
                  <div>{{ reservation.dateTo | formatDate }}</div>
                </td>
                <td>
                  <div class="d-flex">
                    <span
                      v-if="
                        matchStatus(reservation.status, 0, '1') ||
                          matchStatus(reservation.status, 0, '2')
                      "
                      class="pr-1"
                    >
                      <div>
                        <span v-if="reservation.personalDetail">{{
                          reservation.personalDetail.name
                        }}</span>
                      </div>
                      <div>
                        {{ reservation.personalDetail.surname }} ({{
                          reservation.personalDetail.country
                        }})
                      </div>
                    </span>
                    <span class="pr-1"
                      ><span v-if="reservation.meta">{{
                        reservation.meta.specialRequest
                      }}</span></span
                    >
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <span
                      v-if="
                        matchStatus(reservation.status, 0, '1') ||
                          matchStatus(reservation.status, 0, '2')
                      "
                      class="pr-1"
                      ><div v-if="reservation.personalDetail">
                        {{ reservation.personalDetail.email }}
                      </div>
                      <div v-if="reservation.personalDetail">
                        {{ reservation.personalDetail.mobile }}
                      </div></span
                    >
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <span
                      v-if="
                        matchStatus(reservation.status, 0, '1') ||
                          matchStatus(reservation.status, 0, '2')
                      "
                      class="pr-1"
                      ><span v-if="reservation.meta">{{
                        reservation.meta.guests.adults
                      }}</span
                      >+<span v-if="reservation.meta">{{
                        reservation.meta.guests.children
                      }}</span
                      >+<span v-if="reservation.meta">{{
                        reservation.meta.guests.infants
                      }}</span
                      >+<span v-if="reservation.meta">{{
                        reservation.meta.guests.pets
                      }}</span></span
                    >
                  </div>
                </td>
                <td class="text-right price-rundown">
                  <div
                    v-if="
                      matchStatus(reservation.status, 0, '1') ||
                        matchStatus(reservation.status, 0, '2')
                    "
                  >
                    <div
                      class="col-downpayment"
                      :class="{
                        is_paid: matchStatus(reservation.status, 2, '1'),
                        is_deadline_missed: matchStatus(
                          reservation.status,
                          4,
                          '1'
                        ),
                        is_bank_transfer: matchStatus(
                          reservation.status,
                          0,
                          '1'
                        ),
                        is_credit_card: matchStatus(reservation.status, 0, '2')
                      }"
                    >
                      <span v-if="reservation.paymentOption" class="is_price"
                        >{{
                          currencyFormat(
                            reservation.paymentOption.price.downPayment
                          )
                        }}€</span
                      >
                    </div>
                    <div class="col-insurance">
                      <span
                        v-if="reservation.paymentOption"
                        class="is_price"
                        title=""
                        >{{ reservation.paymentOption.insurance.type }}
                        {{
                          currencyFormat(
                            reservation.paymentOption.insurance.value
                          )
                        }}€</span
                      >
                    </div>
                    <div
                      class="col-remainingpayment"
                      :class="{
                        is_paid: matchStatus(reservation.status, 3, '1'),
                        is_deadline_missed: matchStatus(
                          reservation.status,
                          5,
                          '1'
                        ),
                        is_bank_transfer: matchStatus(
                          reservation.status,
                          1,
                          '1'
                        ),
                        is_credit_card: matchStatus(reservation.status, 1, '2')
                      }"
                    >
                      <span v-if="reservation.paymentOption" class="is_price"
                        >{{
                          currencyFormat(
                            reservation.paymentOption.price.remainingPayment
                          )
                        }}€</span
                      >
                    </div>
                    <div>
                      <strong v-if="reservation.paymentOption" class="is_price"
                        >{{
                          currencyFormat(reservation.paymentOption.price.total)
                        }}€</strong
                      >
                    </div>
                  </div>
                  <span
                    v-if="
                      matchStatus(reservation.status, 0, '4') ||
                        matchStatus(reservation.status, 1, '4')
                    "
                    class="label label-lg label-rounded label-primary ml-2"
                    >A</span
                  >
                  <span
                    v-if="
                      matchStatus(reservation.status, 0, '3') ||
                        matchStatus(reservation.status, 1, '3')
                    "
                    class="label label-lg label-rounded label-primary ml-2"
                    >O</span
                  >
                </td>
                <td class="text-right">
                  <span
                    class="label label-lg label-light-success label-inline"
                    >{{ reservation.status }}</span
                  >
                </td>
                <td class="pr-0 text-right">
                  <router-link
                    :to="'/reservations/edit/' + reservation.id"
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/Design/Edit.svg" />
                    </span>
                  </router-link>
                  <button
                    @click.prevent="removeEntity(reservation.id)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <div v-else class="w-100 d-flex justify-content-center my-10">
          <div class="d-flex align-items-center">
            <div class="mr-2 text-muted">Loading...</div>
            <div class="spinner spinner-primary mr-10"></div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal id="reservation_modal" centered hide-header hide-footer>
          <template>
            <div v-if="isReservationLoaded">
              <div>
                <span class="font-weight-bold">ID: </span>{{ reservation.id }}
              </div>
              <div>
                <span class="font-weight-bold">From: </span
                >{{ reservation.dateFrom }}
              </div>
              <div>
                <span class="font-weight-bold">To: </span
                >{{ reservation.dateTo }}
              </div>
              <div>
                <span class="font-weight-bold">Status: </span
                >{{ reservation.status }}
              </div>
              <hr />
              <div><span class="font-weight-bold">Personal detail: </span></div>
              <div>
                <span class="font-weight-bold">-- Name: </span
                >{{ reservation.personalDetail.name }}
              </div>
              <div>
                <span class="font-weight-bold">-- Surname: </span
                >{{ reservation.personalDetail.surname }}
              </div>
              <div>
                <span class="font-weight-bold">-- Email: </span
                >{{ reservation.personalDetail.email }}
              </div>
              <div>
                <span class="font-weight-bold">-- Mobile: </span
                >{{ reservation.personalDetail.mobile }}
              </div>
              <div>
                <span class="font-weight-bold">-- Country: </span
                >{{ reservation.personalDetail.country }}
              </div>
              <hr />
              <div><span class="font-weight-bold">Payment option: </span></div>
              <div>
                <span v-if="reservation.paymentOption" class="font-weight-bold"
                  >-- Down payment: </span
                >{{ reservation.paymentOption.price.downPayment }}
              </div>
              <div>
                <span v-if="reservation.paymentOption" class="font-weight-bold"
                  >-- Remaining payment: </span
                >{{ reservation.paymentOption.price.remainingPayment }}
              </div>
              <div>
                <span v-if="reservation.paymentOption" class="font-weight-bold"
                  >-- Total: </span
                >{{ reservation.paymentOption.price.total }}
              </div>
              <hr />
              <div><span class="font-weight-bold">Meta: </span></div>
              <div>
                <span v-if="reservation.meta" class="font-weight-bold"
                  >-- Adults: </span
                >{{ reservation.meta.guests.adults }}
              </div>
              <div>
                <span v-if="reservation.meta" class="font-weight-bold"
                  >-- Children: </span
                >{{ reservation.meta.guests.children }}
              </div>
              <div>
                <span v-if="reservation.meta" class="font-weight-bold"
                  >-- Infants: </span
                >{{ reservation.meta.guests.infants }}
              </div>
              <div>
                <span v-if="reservation.meta" class="font-weight-bold"
                  >-- Pets: </span
                >{{ reservation.meta.guests.pets }}
              </div>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'

export default {
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY.')
      }
    }
  },
  components: {
    vSelect
  },
  data() {
    return {
      reservations: [],
      villas: [],
      selectedVilla: null,
      reservation: null,
      isLoaded: false,
      isReservationLoaded: false,
      isVillasLoaded: false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Reservations' }])

    ApiService.get('reservations', '?order[createdAt]=desc').then(
      ({ data }) => {
        this.reservations = [...data['hydra:member']]
        this.isLoaded = true

        ApiService.get('villas', '?order[createdAt]=desc').then(({ data }) => {
          this.villas = [...data['hydra:member']]
          this.isVillasLoaded = true
        })
      }
    )
  },
  methods: {
    async deleteEntity(id) {
      try {
        return await ApiService.delete('reservations/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removeEntity(id) {
      const confirmation = confirm(
        'Are you sure you wish to delete this reservation?'
      )
      if (confirmation === false) return

      this.deleteEntity(id).then(({ status }) => {
        if (status === 204) {
          this.reservations = this.reservations.filter(
            reservation => reservation.id !== id
          )
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Reservation deleted'
          })
        }
      })
    },
    toggleViewReservationModal(reservationId) {
      ApiService.get('reservations', reservationId).then(({ data }) => {
        this.reservation = data
        this.isReservationLoaded = true

        this.$bvModal.show('reservation_modal')
      })
    },
    matchStatus(status, part, value) {
      const splitStatus = status.split('')
      if (splitStatus[part] === value) return true
    },
    currencyFormat(num) {
      return num
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    reload() {
      this.reservations = []
      this.isLoaded = false

      ApiService.get('reservations', '?order[createdAt]=desc').then(
        ({ data }) => {
          this.reservations = [...data['hydra:member']]
          this.isLoaded = true
        }
      )
    },
    onSelectVilla(villa) {
      ApiService.get(
        'reservations',
        `?villa=${villa}&order[createdAt]=desc`
      ).then(({ data }) => {
        this.reservations = []
        this.reservations = [...data['hydra:member']]
      })
    },
    viewAllVillas() {
      ApiService.get('reservations', '?order[createdAt]=desc').then(
        ({ data }) => {
          this.reservations = []
          this.selectedVilla = null
          this.reservations = [...data['hydra:member']]
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
.form-control {
  &--category-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--tag-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--region-select {
    height: 46px !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
