<template>
  <div>
    <div class="card card-custom card-sticky" id="kt_page_sticky_card">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Edit New User</h3>
        </div>
        <CardToolbar
          @submitEvent="onSubmit"
          back-text="All Users"
          back-url="/users/list"
          button-text="User"
          action-text="Edit"
          :dropdown-enabled="false"
        />
      </div>
      <div class="card-body">
        <form class="form" id="user_form">
          <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-xl-8">
              <div class="my-5">
                <div class="form-group row">
                  <label class="col-3">Email</label>
                  <div class="col-9">
                    <input
                      v-model="user.email"
                      class="form-control form-control-solid"
                      type="text"
                      placeholder="Enter user email"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3">Password</label>
                  <div class="col-9">
                    <input
                      v-model="user.plainPassword"
                      class="form-control form-control-solid"
                      type="password"
                      placeholder="Enter user password"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3">Role</label>
                  <div class="col-9">
                    <select
                      class="form-control form-control-solid"
                      @change="changeUserRole($event)"
                    >
                      <option>Client</option>
                      <option>Admin</option>
                      <option>Super Admin</option>
                    </select>
                  </div>
                </div>
                <div class="separator separator-dashed my-10"></div>
                <h5 class="text-dark font-weight-bold mb-10 mt-5">
                  User's Personal Info
                </h5>
                <div class="form-group row">
                  <label class="col-3">Name</label>
                  <div class="col-9">
                    <input
                      v-model="user.personalInfo.name"
                      class="form-control form-control-solid"
                      type="text"
                      placeholder="Enter name"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3">Surname</label>
                  <div class="col-9">
                    <input
                      v-model="user.personalInfo.surname"
                      class="form-control form-control-solid"
                      type="text"
                      placeholder="Enter surname"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3">Phone</label>
                  <div class="col-9">
                    <input
                      v-model="user.personalInfo.phone"
                      class="form-control form-control-solid"
                      type="text"
                      placeholder="Enter phone"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3">OIB</label>
                  <div class="col-9">
                    <input
                      v-model="user.personalInfo.oib"
                      class="form-control form-control-solid"
                      type="text"
                      placeholder="Enter OIB"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3">IBAN</label>
                  <div class="col-9">
                    <input
                      v-model="user.personalInfo.iban"
                      class="form-control form-control-solid"
                      type="text"
                      placeholder="Enter IBAN"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3">Address</label>
                  <div class="col-9">
                    <input
                      v-model="user.personalInfo.address"
                      class="form-control form-control-solid"
                      type="text"
                      placeholder="Enter address"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3">Company</label>
                  <div class="col-9">
                    <input
                      v-model="user.personalInfo.company_name"
                      class="form-control form-control-solid"
                      type="text"
                      placeholder="Enter company"
                    />
                  </div>
                </div>
                <div class="separator separator-dashed my-10"></div>
                <div class="form-group row">
                  <div class="col-12">
                    <button
                      @click="deleteUser(user.id)"
                      class="btn btn-light-danger btn-block"
                    >
                      Delete user
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-2"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'

export default {
  components: {
    CardToolbar
  },
  data() {
    return {
      user: {
        id: null,
        email: null,
        roles: ['ROLE_CLIENT', 'ROLE_USER'],
        personalInfo: {
          job: '',
          name: '',
          email: '',
          phone: '',
          oib: '',
          iban: '',
          address: '',
          photo: '',
          surname: '',
          company_name: '',
          company_site: ''
        },
        accountInfo: {
          email: '',
          language: '',
          username: '',
          time_zone: '',
          verification: '',
          communication: {
            sms: false,
            email: false,
            phone: false
          }
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Users', route: '/users/list' },
      { title: 'Edit User' }
    ])

    ApiService.get('users', this.$route.params.id).then(({ data }) => {
      this.user.email = data.email
      this.user.id = data.id
      this.user.personalInfo.job = data.personalInfo.job
      this.user.personalInfo.name = data.personalInfo.name
      this.user.personalInfo.email = data.personalInfo.email
      this.user.personalInfo.phone = data.personalInfo.phone
      this.user.personalInfo.oib = data.personalInfo.oib
      this.user.personalInfo.iban = data.personalInfo.iban
      this.user.personalInfo.address = data.personalInfo.address
      this.user.personalInfo.photo = data.personalInfo.photo
      this.user.personalInfo.surname = data.personalInfo.surname
      this.user.personalInfo.company_name = data.personalInfo.company_name
      this.user.personalInfo.company_site = data.personalInfo.company_site
      this.user.accountInfo.email = data.accountInfo.email
      this.user.accountInfo.language = data.accountInfo.language
      this.user.accountInfo.username = data.accountInfo.username
      this.user.accountInfo.time_zone = data.accountInfo.time_zone
      this.user.accountInfo.verification = data.accountInfo.verification
      this.user.accountInfo.communication.sms =
        data.accountInfo.communication.sms
      this.user.accountInfo.communication.email =
        data.accountInfo.communication.email
      this.user.accountInfo.communication.phone =
        data.accountInfo.communication.phone
    })
  },
  methods: {
    onSubmit() {
      ApiService.update('users', this.$route.params.id, this.user).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'User saved'
        })
        setTimeout(() => {
          this.$router.push({ name: 'users-list' })
        }, 500)
      })
    },
    changeUserRole(event) {
      switch (event.target.value) {
        case 'Client':
          this.user.roles = ['ROLE_CLIENT', 'ROLE_USER']
          break
        case 'Admin':
          this.user.roles = ['ROLE_ADMIN', 'ROLE_USER']
          break
        case 'Super Admin':
          this.user.roles = ['ROLE_SUPER_ADMIN', 'ROLE_USER']
          break
      }
    },
    deleteUser(id) {
      const confirmation = confirm('You want delete this user?')
      if (confirmation === false) return

      ApiService.delete('users/' + id).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'User deleted'
        })
        setTimeout(() => {
          this.$router.push({ name: 'users-list' })
        }, 500)
      })
    }
  }
}
</script>

<style lang="scss"></style>
