<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form v-if="isLoaded" ref="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="d-flex flex-row">
        <div class="flex-row-fluid mr-lg-8">
          <notifications group="notification" position="bottom right" />

          <div class="card card-custom card-sticky gutter-b">
            <div
              class="card-header border-0"
              style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
            >
              <div class="card-title">
                <h3 class="card-label">
                  Edit Page
                </h3>
              </div>
              <CardToolbar
                @submitEvent="submit"
                back-text="All Pages"
                back-url="/pages/list"
                edit-text="Edit Page"
                button-text="Save Page"
              />
            </div>
            <!--begin::Body-->
            <div class="card-body pt-0">
              <ValidationProvider
                name="Title"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Title</label>
                  <input
                    v-model="page.translations.en.title"
                    type="text"
                    :class="[
                      'form-control form-control-solid form-control-lg',
                      { 'is-invalid': errors[0] }
                    ]"
                    placeholder="Page Title "
                  />
                </div>
              </ValidationProvider>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Slug</label>
                <input
                  v-model="page.translations.en.slug"
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  placeholder="Slug"
                />
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Content</label>
                <quill-editor
                  :content="page.translations.en.content"
                  :options="contentEditorOption"
                  @change="onEditorChange($event)"
                />
              </div>

              <seo
                :meta="page.translations.en.seoMeta"
                :entity-url="'https://likeistria.com/en/pages/' + page.slug"
              ></seo>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="flex-column offcanvas-mobile w-300px w-xl-325px">
          <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Options</span
                >
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-0">
              <div class="row">
                <label class="col-6 col-form-label">Publish</label>
                <div class="col-6">
                  <LiSwitch
                    @checkedEvent="updateIsPublish"
                    :is-checked="page.isPublish"
                    classes="switch-icon float-right"
                    name="pageIsPublish"
                  />
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import Seo from '@/view/content/components/seo/Seo'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ApiService from '@/core/services/api.service'

export default {
  components: {
    LiSwitch,
    CardToolbar,
    Seo,
    'quill-editor': quillEditor
  },
  data() {
    return {
      page: {
        isPublish: false,
        translations: {
          en: {
            locale: 'en',
            slug: '',
            title: '',
            content: '',
            seoMeta: {
              ogUrl: null,
              ogImage: null,
              ogTitle: null,
              keywords: null,
              canonical: null,
              ogSiteName: null,
              description: null,
              twitterCard: null,
              twitterImage: null,
              twitterTitle: null,
              ogDescription: null,
              ogImageSecureUrl: null,
              twitterDescription: null
            }
          }
        }
      },
      contentEditorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean', 'link']
          ]
        }
      },
      isLoaded: false
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Pages', route: '/pages/list' },
      { title: 'Edit Page' }
    ])

    ApiService.get(
      'pages',
      this.$route.params.id + '?groups[]=translations'
    ).then(({ data }) => {
      // console.log(data)
      this.page = data
      this.page.translations = data.translations
      this.page.translations.en = {}
      this.page.translations.en.locale = 'en'
      this.page.translations.en.slug = data.slug
      this.page.translations.en.title = data.title
      this.page.translations.en.content = data.content
      this.page.translations.en.seoMeta = data.seoMeta
      this.isLoaded = true
    })
  },
  methods: {
    onSubmit() {},
    submit() {
      // Auto slugify on the server when slug field is empty
      if (!this.page.translations.en.slug) {
        delete this.page.translations.en.slug
      }
      ApiService.update('pages', this.$route.params.id, this.page).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Page saved.'
        })
      })
    },
    updateIsPublish(checked) {
      this.page.isPublish = checked
    },
    complete() {},
    onEditorChange({ html }) {
      this.page.translations.en.content = html
    }
  }
}
</script>

<style lang="scss"></style>
