<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
        <div class="d-flex flex-row">
          <div class="flex-row-fluid">
            <notifications group="notification" position="bottom right" />
            <div v-if="isLoaded">
              <div class="card card-custom card-sticky gutter-b">
                <div
                  class="card-header bg-light border-0"
                  style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
                >
                  <b-col align-self="center">
                    <!-- these are simulating the tab controls inside the sticky header -->
                    <b-nav card-header tabs align="left">
                      <b-nav-item
                        v-for="(loopLang, loopIndex) in translationsLocales"
                        @click="langClick(loopIndex, loopLang)"
                        :title="loopLang.toUpperCase()"
                        :key="loopLang"
                        :active="tabIndex === loopIndex"
                        >{{ loopLang.toUpperCase() }}
                      </b-nav-item>
                    </b-nav>
                  </b-col>
                  <b-col class="p-0" align-self="center">
                    <div class="float-right">
                      <CardToolbar
                        @submitEvent="submit"
                        back-text="All Regions"
                        back-url="/regions/list"
                        edit-text="Edit Region"
                        :edit-url="{
                          name: 'regions-edit',
                          params: { id: $route.params.id }
                        }"
                        button-text="Save Translations"
                        :dropdown-enabled="false"
                      />
                    </div>
                  </b-col>
                </div>

                <!--begin::Body-->
                <!-- nav-wrapper-class="d-none" is what hides the actual nav tabs that are produced but <b-tab> -->
                <b-tabs
                  v-model="tabIndex"
                  card
                  nav-wrapper-class="d-none"
                  content-class="mt-5"
                >
                  <b-tab
                    no-body
                    @click="switchLang(loopLang)"
                    v-for="(loopLang, loopIndex) in translationsLocales"
                    :title="loopLang.toUpperCase()"
                    :key="loopLang"
                    :active="tabIndex === loopIndex"
                  >
                    <div class="card-body pt-0">
                      <ValidationProvider
                        name="Title"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <div class="form-group mb-8">
                          <label class="font-weight-bolder">Title</label>
                          <input
                            v-model="translations[loopLang].title"
                            type="text"
                            :class="[
                              'form-control form-control-solid form-control-lg',
                              { 'is-invalid': errors[0] }
                            ]"
                            :placeholder="
                              'Title (' + loopLang.toUpperCase() + ')'
                            "
                          />
                        </div>
                      </ValidationProvider>
                    </div>
                    <!--end::Body-->
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import TranslationsService from '@/core/services/translations.service'

export default {
  components: {
    CardToolbar
  },
  data() {
    return {
      isLoaded: false,
      // TODO: Move all these somewhere to global Vue instance and use same values everywhere
      siteBaseUrl: process.env.VUE_APP_ROUTE_API_URL,
      currentLang: 'en',
      defaultLang: 'en',
      translationsLocales: TranslationsService.getLocalesList(),
      tabIndex: 0,
      translations: {},
      requiredFields: ['title']
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Regions', route: '/regions/list' },
      { title: 'Edit Region Translations' }
    ])

    ApiService.get(
      'regions',
      `${this.$route.params.id}?groups[]=translations`
    ).then(({ data }) => {
      this.translations = data.translations
      TranslationsService.ensureTranslationsStructure(this, this.requiredFields)
      setTimeout(() => {
        this.isLoaded = true
      }, 500)
    })
  },
  methods: {
    onSubmit() {
      this.submit()
      ApiService.update('regions', this.$route.params.id, {
        translations: this.translations
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Translations saved.'
        })
      })
    },
    submit() {}, // Note: must exist because of CardToolbar component?
    langClick(tabIdx, langCode) {
      this.tabIndex = tabIdx
      this.switchLang(langCode)
    },
    switchLang(lang) {
      this.currentLang = lang
    }
  }
}
</script>
