<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="d-flex flex-row">
        <div class="flex-row-fluid mr-lg-8">
          <notifications group="notification" position="bottom right" />
          <div class="card card-custom card-sticky gutter-b">
            <div
              class="card-header border-0"
              style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
            >
              <div class="card-title">
                <h3 class="card-label">
                  Add New Villa
                </h3>
              </div>
              <CardToolbar
                @submitEvent="submit"
                back-text="All Villas"
                back-url="/villas/list"
                button-text="Villa"
                action-text="New"
                :dropdown-enabled="false"
              />
            </div>
            <!--begin::Body-->
            <div class="card-body pt-0">
              <div class="form-group mb-8">
                <label class="font-weight-bolder">Type</label>
                <input
                  v-model="villa.translations.en.type"
                  class="form-control form-control-solid"
                  type="text"
                  placeholder="Enter type"
                />
              </div>

              <ValidationProvider
                name="Title"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Title</label>
                  <input
                    v-model="villa.translations.en.title"
                    type="text"
                    :class="[
                      'form-control form-control-solid form-control-lg',
                      { 'is-invalid': errors[0] }
                    ]"
                    placeholder="Villa Title"
                  />
                </div>
              </ValidationProvider>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Subtitle</label>
                <input
                  v-model="villa.translations.en.subtitle"
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  placeholder="Villa Subtitle"
                />
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Description</label>
                <quill-editor
                  class="villa-description"
                  :content="villa.translations.en.description"
                  :options="contentEditorOption"
                  @change="onEditorChange($event)"
                />
              </div>

              <div class="row">
                <div class="col-md-12">
                  <b-card no-body class="border-0">
                    <b-tabs pills vertical nav-wrapper-class="col-3">
                      <b-tab active>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-expand"></i>
                          </span>
                          <span class="font-weight-bolder">Multimedia</span>
                        </template>
                        <b-card-text>
                          <div class="card card-custom gutter-b">
                            <div class="card-header border-0">
                              <div class="card-title">
                                <h3 class="card-label">Multimedia</h3>
                              </div>
                            </div>
                            <div class="card-body pt-0">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Featured Image</label
                                >

                                <Dropzone
                                  class="dropzone dropzone-default dropzone-primary dz-clickable h-90px w-90px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0"
                                  ref="villaFeaturedImageDropzone"
                                  @vdropzone-file-added="featuredImageAdd"
                                  @vdropzone-complete="featuredImageComplete"
                                  @vdropzone-success="featuredImageSuccess"
                                  id="villaFeaturedImage"
                                  :options="featuredImageDropzoneOptions"
                                  :useCustomSlot="true"
                                >
                                  <span class="svg-icon svg-icon-lg m-0">
                                    <inline-svg
                                      src="media/svg/icons/Design/Image.svg"
                                    />
                                  </span>
                                </Dropzone>

                                <div
                                  v-if="villa.featuredImage.src"
                                  class="symbol symbol-90 flex-shrink-0 mr-4 cursor-pointer"
                                >
                                  <div
                                    class="symbol-label"
                                    v-bind:style="{
                                      'background-image':
                                        'url(' +
                                        imagePath +
                                        villa.featuredImage.src +
                                        ')'
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Image Gallery</label
                                >

                                <Dropzone
                                  class="dropzone dropzone-default dropzone-primary dz-clickable h-90px w-90px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0"
                                  ref="villaImageGalleryDropzone"
                                  @vdropzone-file-added="featuredImageAdd"
                                  @vdropzone-complete="featuredImageComplete"
                                  @vdropzone-success="imageGallerySuccess"
                                  id="villaImageGallery"
                                  :options="imageGalleryDropzoneOptions"
                                  :useCustomSlot="true"
                                >
                                  <span class="svg-icon svg-icon-lg m-0">
                                    <inline-svg
                                      src="media/svg/icons/Design/Image.svg"
                                    />
                                  </span>
                                </Dropzone>

                                <draggable
                                  class="symbol-label-wrapper"
                                  v-model="villa.imageGallery"
                                  group="people"
                                  @start="drag = true"
                                  @end="drag = false"
                                >
                                  <div
                                    v-for="(image, index) in villa.imageGallery"
                                    :key="image + index"
                                    class="symbol symbol-label h-100px w-100px mr-3 mb-3 cursor-pointer"
                                    v-bind:style="{
                                      'background-image':
                                        'url(' +
                                        imagePath +
                                        '/media/cache/resolve/100x100' +
                                        image +
                                        ')'
                                    }"
                                  >
                                    <span
                                      @click="removeImageFromGallery(image)"
                                      class="gallery-image-remove mt-1 mr-2"
                                      ><i
                                        class="flaticon-close flaticon-close--gallery-image"
                                      ></i
                                    ></span>
                                  </div>
                                </draggable>
                                <button
                                  v-if="villa.imageGallery.length > 0"
                                  @click="removeAllImages"
                                  class="btn btn-light-primary btn-block font-weight-bolder"
                                >
                                  Remove all images
                                </button>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-layers-1"></i>
                          </span>
                          <span class="font-weight-bolder">Meta</span>
                        </template>
                        <b-card-text>
                          <div class="card card-custom gutter-b">
                            <div class="card-header border-0">
                              <div class="card-title">
                                <h3 class="card-label">Meta</h3>
                              </div>
                            </div>
                            <div class="card-body pt-0">
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Deposit</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="villa.meta.deposit"
                                      type="text"
                                      class="form-control"
                                      placeholder="Deposit"
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Insurance</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="villa.meta.insurance"
                                      type="text"
                                      class="form-control"
                                      placeholder="Insurance"
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                              <div
                                class="separator separator-dashed my-10"
                              ></div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Number of Guests</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="villa.meta.persons"
                                      type="text"
                                      class="form-control"
                                      placeholder="Max number of guests"
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Number of Bedrooms</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="villa.meta.bedrooms"
                                      type="text"
                                      class="form-control"
                                      placeholder="Number of bedrooms"
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Number of Bathrooms</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="villa.meta.bathrooms"
                                      type="text"
                                      class="form-control"
                                      placeholder="Number of bathrooms"
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Free text to enter</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="villa.meta.else"
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter free text..."
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Is Villa pet friendly?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updateIsPetFriendly"
                                    :is-checked="villa.meta.is_pet_friendly"
                                    classes="switch-icon float-left"
                                    name="villaIsPetFriendly"
                                  />
                                </div>
                              </div>
                              <div
                                v-if="villa.meta.is_pet_friendly"
                                class="form-group row"
                              >
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Pet price?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <input
                                    v-model="villa.meta.pet_price"
                                    type="text"
                                    class="form-control"
                                    placeholder="Price per one pet"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Heated pool?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updateHeatedPool"
                                    :is-checked="villa.meta.heated_pool"
                                    classes="switch-icon float-left"
                                    name="villaHeatedPool"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >BBQ?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updateBbq"
                                    :is-checked="villa.meta.bbq"
                                    classes="switch-icon float-left"
                                    name="villaBbq"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Fenced?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updateFenced"
                                    :is-checked="villa.meta.fenced"
                                    classes="switch-icon float-left"
                                    name="villaFenced"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Children playground?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updateChildrenPlayground"
                                    :is-checked="villa.meta.children_playground"
                                    classes="switch-icon float-left"
                                    name="villaChildrenPlayground"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Private parking?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updatePrivateParking"
                                    :is-checked="villa.meta.private_parking"
                                    classes="switch-icon float-left"
                                    name="villaPrivateParking"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >GYM?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updateGym"
                                    :is-checked="villa.meta.gym"
                                    classes="switch-icon float-left"
                                    name="villaGym"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Sports?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updateSports"
                                    :is-checked="villa.meta.sports"
                                    classes="switch-icon float-left"
                                    name="villaSports"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Sauna?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updateSauna"
                                    :is-checked="villa.meta.sauna"
                                    classes="switch-icon float-left"
                                    name="villaSauna"
                                  />
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Bicycles?</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <LiSwitch
                                    @checkedEvent="updateBicycles"
                                    :is-checked="villa.meta.bicycles"
                                    classes="switch-icon float-left"
                                    name="villaBicycles"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-indent-dots"></i>
                          </span>
                          <span class="font-weight-bolder">Facilities</span>
                        </template>
                        <b-card-text>
                          <facilities
                            :facilities="villa.translations.en.facilities"
                            @addFacilitiesGroup="addFacilitiesGroup"
                            @removeFacilitiesGroup="removeFacilitiesGroup"
                            @addSubFacilitiesItem="addSubFacilitiesItem"
                            @removeSubFacilitiesItem="removeSubFacilitiesItem"
                          >
                          </facilities>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon-price-tag"></i>
                          </span>
                          <span class="font-weight-bolder">Price Ranges</span>
                        </template>
                        <b-card-text>
                          <div class="card card-custom gutter-b">
                            <div class="card-body">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Min Week Price</label
                                >
                                <div class="input-group">
                                  <input
                                    v-model.number="villa.minWeekPrice"
                                    type="text"
                                    class="form-control"
                                    placeholder="Min Week Price"
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text">
                                      <i class="flaticon-price-tag"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <price-ranges
                            :price-ranges="villa.priceRanges"
                            @addRange="addPriceRange"
                            @removeRange="removePriceRange"
                            @addSubRange="addPriceRangeSubRange"
                            @removeSubRange="removeSubRange"
                            @toggleSpecialOffer="togglePriceRangeSpecialOffer"
                          ></price-ranges>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-location"></i>
                          </span>
                          <span class="font-weight-bolder">Location</span>
                        </template>
                        <b-card-text>
                          <div class="card card-custom gutter-b">
                            <div class="card-header border-0">
                              <div class="card-title">
                                <h3 class="card-label">Region & Location</h3>
                              </div>
                            </div>
                            <div class="card-body pt-0">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Regions</label
                                >
                                <vSelect
                                  class="form-control form-control--region-select"
                                  placeholder="Select villa region"
                                  v-model="villa.regions"
                                  multiple
                                  :options="regions"
                                  :reduce="region => region['@id']"
                                  label="title"
                                />
                              </div>
                              <b-button
                                @click="showModal"
                                class="btn btn-light-primary font-weight-bold btn-block mt-4"
                                >Add Region</b-button
                              >
                              <b-modal
                                id="add-region-modal"
                                centered
                                hide-header
                                hide-footer
                              >
                                <template>
                                  <div class="form-group mb-2">
                                    <div class="input-group">
                                      <input
                                        id="regionName"
                                        type="text"
                                        v-model="newRegion"
                                        placeholder="Region name"
                                        class="form-control"
                                      />
                                    </div>
                                  </div>
                                  <button
                                    @click="addRegion"
                                    class="btn btn-light-primary font-weight-bold btn-block mt-4"
                                  >
                                    Add
                                  </button>
                                </template>
                              </b-modal>
                              <div
                                class="separator separator-dashed mt-8 mb-8"
                              ></div>
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Location Lat</label
                                >
                                <div class="input-group">
                                  <input
                                    type="text"
                                    v-model.number="
                                      villa.translations.en.location.lat
                                    "
                                    class="form-control form-control-solid"
                                  />
                                </div>
                              </div>
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Location Lng</label
                                >
                                <div class="input-group">
                                  <input
                                    type="text"
                                    v-model.number="
                                      villa.translations.en.location.lng
                                    "
                                    class="form-control form-control-solid"
                                  />
                                </div>
                              </div>
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Address</label
                                >
                                <div class="input-group">
                                  <input
                                    type="text"
                                    v-model.number="
                                      villa.translations.en.location.address
                                    "
                                    placeholder="Address"
                                    class="form-control form-control-solid"
                                  />
                                </div>
                              </div>
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Main Location</label
                                >
                                <div class="input-group">
                                  <input
                                    type="text"
                                    v-model.number="
                                      villa.translations.en.location
                                        .mainLocation
                                    "
                                    placeholder="Main Location"
                                    class="form-control form-control-solid"
                                  />
                                </div>
                              </div>
                              <div
                                class="separator separator-dashed mt-8 mb-8"
                              ></div>
                              <LocationMeta
                                :meta="villa.translations.en.location.meta"
                                @addLocationMetaItem="addLocationMetaItem"
                              />
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-calendar-5"></i>
                          </span>
                          <span class="font-weight-bolder">Reservations</span>
                        </template>
                        <b-card-text>Tab Contents Reservations</b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-rocket-1"></i>
                          </span>
                          <span class="font-weight-bolder">Experiences</span>
                        </template>
                        <b-card-text>
                          <dual-list-box
                            :source="experiences"
                            :destination="villa.experiences"
                            label="title"
                            @onChangeList="onChangeList"
                          >
                          </dual-list-box>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-browser-2"></i>
                          </span>
                          <span class="font-weight-bolder">Reviews</span>
                        </template>
                        <b-card-text>
                          <dual-list-box
                            :source="reviews"
                            :destination="villa.reviews"
                            label="author"
                            @onChangeList="onReviewsChangeList"
                          >
                          </dual-list-box>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-search-1"></i>
                          </span>
                          <span class="font-weight-bolder">SEO</span>
                        </template>
                        <b-card-text>
                          <seo
                            :meta="villa.translations.en.seoMeta"
                            :entity-url="'https://likeistria.com/en/villas/'"
                          ></seo>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-user-outline-symbol"></i>
                          </span>
                          <span class="font-weight-bolder">Owner</span>
                        </template>
                        <b-card-text>
                          <div class="row">
                            <div class="col-12">
                              <div class="form-group mb-5">
                                <label class="font-weight-bolder">Owner</label>
                                <div class="input-group">
                                  <vSelect
                                    class="form-control form-control--category-select"
                                    v-model="villa.owner"
                                    placeholder="Select owner"
                                    :options="users"
                                    :reduce="user => user['@id']"
                                    label="email"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Contact phone</label
                                >
                                <div class="input-group">
                                  <input
                                    type="text"
                                    v-model.number="villa.meta.contact_phone"
                                    placeholder="Enter Contact phone"
                                    class="form-control form-control-solid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="flex-column offcanvas-mobile w-300px w-xl-325px">
          <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Options</span
                >
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-0">
              <div class="row">
                <label class="col-6 col-form-label">Publish</label>
                <div class="col-6">
                  <LiSwitch
                    @checkedEvent="updateIsPublish"
                    :is-checked="villa.isPublish"
                    classes="switch-icon float-right"
                    name="villaIsPublish"
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-6 col-form-label">Featured</label>
                <div class="col-6">
                  <LiSwitch
                    @checkedEvent="updateIsFeatured"
                    :is-checked="villa.isFeatured"
                    classes="switch-icon float-right"
                    name="villaIsFeatured"
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-6 col-form-label">Exclusive</label>
                <div class="col-6">
                  <LiSwitch
                    @checkedEvent="updateIsExclusive"
                    :is-checked="villa.isExclusive"
                    classes="switch-icon float-right"
                    name="villaIsExclusive"
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-6 col-form-label">In HHero</label>
                <div class="col-6">
                  <LiSwitch
                    @checkedEvent="updateInHomepageHero"
                    :is-checked="villa.inHomepageHero"
                    classes="switch-icon float-right"
                    name="villaInHomepageHero"
                  />
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import Seo from '@/view/content/components/seo/Seo'
import Facilities from '@/view/content/components/facilities/Facilities'
import DualListBox from '@/view/content/components/dual-list-box/DualListBox'
import Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ApiService from '@/core/services/api.service'
import draggable from 'vuedraggable'
import PriceRanges from '@/view/content/components/price-ranges/PriceRanges'
import vSelect from 'vue-select'
import LocationMeta from '@/view/content/components/location-meta/LocationMeta'

export default {
  components: {
    vSelect,
    LiSwitch,
    CardToolbar,
    Dropzone,
    Seo,
    'quill-editor': quillEditor,
    'price-ranges': PriceRanges,
    facilities: Facilities,
    'dual-list-box': DualListBox,
    draggable,
    LocationMeta
  },
  data() {
    return {
      villa: {
        translations: {
          en: {
            locale: 'en',
            type: null,
            title: null,
            subtitle: null,
            description: null,
            facilities: [
              { name: 'Dining', items: [{ content: '' }], description: '' },
              { name: 'Cooking', items: [{ content: '' }], description: '' },
              { name: 'Sleeping', items: [{ content: '' }], description: '' },
              { name: 'Having fun', items: [{ content: '' }], description: '' },
              {
                name: 'Good to know',
                items: [{ content: '' }],
                description: ''
              }
            ],
            location: {
              lat: null,
              lng: null,
              address: null,
              mainLocation: null,
              meta: {
                items: [],
                description: null
              }
            },
            seoMeta: {
              ogUrl: null,
              ogImage: null,
              ogTitle: null,
              keywords: null,
              canonical: null,
              ogSiteName: null,
              description: null,
              twitterCard: null,
              twitterImage: null,
              twitterTitle: null,
              ogDescription: null,
              ogImageSecureUrl: null,
              twitterDescription: null
            }
          }
        },
        featuredImage: {
          src: null,
          alt: null
        },
        imageGallery: [],
        // These 3 need to be saved as separate fields/columns or the entity is not
        // visible on frontend listings due to PropertyFilter conditions in ajax query
        persons: null,
        bedrooms: null,
        bathrooms: null,
        meta: {
          persons: '',
          bedrooms: '',
          bathrooms: '',
          else: '',
          is_pet_friendly: false,
          pet_price: '',
          heated_pool: false,
          bbq: false,
          fenced: false,
          children_playground: false,
          private_parking: false,
          gym: false,
          sports: false,
          sauna: false,
          bicycles: false,
          deposit: '',
          insurance: '',
          contact_phone: ''
        },
        isPublish: false,
        isFeatured: false,
        isExclusive: false,
        inHomepageHero: false,
        priceRanges: [],
        minWeekPrice: null,
        experiences: [],
        regions: [],
        reviews: [],
        owner: null
      },
      regions: [],
      experiences: [],
      reviews: [],
      users: [],
      featuredImageDropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 1
      },
      imageGalleryDropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 50
      },
      contentEditorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean', 'link']
          ]
        }
      },
      priceRangeCalendar: {
        calendarTransition: 'slide-v',
        mode: 'range',
        selectedDate: null,
        disabledDays: null,
        availableDays: null
      },
      imagePath: process.env.VUE_APP_ROUTE_API_URL,
      newRegion: null
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Villas', route: '/villas/list' },
      { title: 'New Villa' }
    ])

    ApiService.get('experiences').then(response => {
      this.experiences = [...response.data['hydra:member']]
    })

    ApiService.get('regions?itemsPerPage=100').then(({ data }) => {
      this.regions = [...data['hydra:member']]
    })

    this.getReviews('reviews', '').then(({ data }) => {
      this.reviews = [...data['hydra:member']]
    })

    this.getUsers('users', '').then(({ data }) => {
      this.users = [...data['hydra:member']]
    })
  },
  methods: {
    onSubmit() {
      // console.log(this.villa)

      this.submit()

      // These need to be duplicated into separate fields otherwise the Villa
      // does not show on the frontend due to PropertyFilter conditions (and make sure they are saved as ints)
      let persons = parseInt(this.villa.meta.persons, 10)
      let bedrooms = parseInt(this.villa.meta.bedrooms, 10)
      let bathrooms = parseInt(this.villa.meta.bathrooms, 10)
      this.villa.persons = persons
      this.villa.bedrooms = bedrooms
      this.villa.bathrooms = bathrooms
      this.villa.meta.persons = persons
      this.villa.meta.bedrooms = bedrooms
      this.villa.meta.bathrooms = bathrooms

      ApiService.post('villas', this.villa, {
        Headers: {
          ContentType: 'application/ld+json'
        }
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Villa added'
        })

        setTimeout(() => {
          this.$router.push({ name: 'villas-list' })
        }, 500)
      })
      //this.$refs.form.submit()
    },
    submit() {},
    updateIsPublish(checked) {
      this.villa.isPublish = checked
    },
    updateIsFeatured(checked) {
      this.villa.isFeatured = checked
    },
    updateInHero(checked) {
      this.villa.inHero = checked
    },
    updateIsExclusive(checked) {
      this.villa.isExclusive = checked
    },
    updateInHomepageHero(checked) {
      this.villa.inHomepageHero = checked
    },
    updateIsPetFriendly(checked) {
      this.villa.meta.is_pet_friendly = checked
    },
    updateHeatedPool(checked) {
      this.villa.meta.heated_pool = checked
    },
    updateBbq(checked) {
      this.villa.meta.bbq = checked
    },
    updateFenced(checked) {
      this.villa.meta.fenced = checked
    },
    updateChildrenPlayground(checked) {
      this.villa.meta.children_playground = checked
    },
    updatePrivateParking(checked) {
      this.villa.meta.private_parking = checked
    },
    updateGym(checked) {
      this.villa.meta.gym = checked
    },
    updateSports(checked) {
      this.villa.meta.sports = checked
    },
    updateSauna(checked) {
      this.villa.meta.sauna = checked
    },
    updateBicycles(checked) {
      this.villa.meta.bicycles = checked
    },

    featuredImageAdd() {},
    featuredImageSuccess(_, response) {
      this.villa.featuredImage.src = '/uploads/' + response
    },
    imageGallerySuccess(_, response) {
      this.villa.imageGallery.push('/uploads/' + response)
    },
    featuredImageComplete() {},

    removeImageFromGallery(image) {
      let confirmation = confirm('Delete image?')
      if (confirmation === false) return

      this.villa.imageGallery = this.villa.imageGallery.filter(
        gImage => gImage !== image
      )
    },

    // facilities
    addFacilitiesGroup() {
      this.villa.translations.en.facilities.push({
        name: '',
        items: [{ content: '' }],
        description: ''
      })
    },
    removeFacilitiesGroup(index) {
      let confirmation = confirm('Delete group?')

      if (confirmation === false) return

      this.villa.translations.en.facilities.splice(index, 1)
    },
    addSubFacilitiesItem(index) {
      this.villa.translations.en.facilities[index].items.push({
        content: ''
      })
    },
    removeSubFacilitiesItem(groupIndex, itemIndex) {
      let confirmation = confirm('Delete item?')

      if (confirmation === false) return

      this.villa.translations.en.facilities[groupIndex].items.splice(
        itemIndex,
        1
      )
    },

    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      // console.log('editor change!', quill, html, text)
      this.villa.translations.en.description = html
    },

    // toggleCardToolbarDropDown() {
    //   CardToolbarDropdown.toggleDropdown(false);
    // }

    onChangeList: function({ source, destination }) {
      this.experiences = source
      this.villa.experiences = destination
    },

    // reviews
    onReviewsChangeList: function({ source, destination }) {
      this.reviews = source
      this.villa.reviews = destination
    },

    // price ranges
    addPriceRange() {
      // console.log('addPriceRange')
      this.villa.priceRanges.push({
        start: '',
        end: '',
        pricePerNight: '',
        minStay: '',
        isSpecialOffer: false,
        subRanges: []
      })
    },
    removePriceRange(rangeIndex) {
      const confirmation = confirm('Delete range?')
      if (confirmation === false) return

      this.villa.priceRanges.splice(rangeIndex, 1)
    },
    togglePriceRangeSpecialOffer(rangeIndex) {
      this.villa.priceRanges[rangeIndex].isSpecialOffer = !this.villa
        .priceRanges[rangeIndex].isSpecialOffer
    },
    addPriceRangeSubRange(rangeIndex) {
      this.villa.priceRanges[rangeIndex].subRanges.push({
        start: '',
        end: '',
        discount: '',
        minStay: ''
      })
    },
    removeSubRange(rangeIndex, subRangeIndex) {
      const confirmation = confirm('Delete sub range?')
      if (confirmation === false) return

      this.villa.priceRanges[rangeIndex].subRanges.splice(subRangeIndex, 1)
    },

    // show modal callback
    showModal() {
      this.$bvModal.show('add-region-modal')
      setTimeout(() => {
        document.getElementById('regionName').focus()
      }, 0)
    },

    // region
    addRegion() {
      ApiService.post('regions', { title: this.newRegion }).then(({ data }) => {
        this.villa.regions.push(data)
        this.$bvModal.hide('add-region-modal')
        this.newRegion = ''

        ApiService.get('regions?itemsPerPage=100').then(({ data }) => {
          this.regions = [...data['hydra:member']]
        })
      })
    },

    addLocationMetaItem() {
      this.villa.translations.en.location.meta.items.push({
        name: '',
        content: {
          name: '',
          value: ''
        }
      })
    },
    removeAllImages() {
      const confirmation = confirm('Remove all images?')
      if (confirmation === false) return

      this.villa.imageGallery = []
    },

    async getReviews(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getUsers(endpoint, filter) {
      try {
        return await ApiService.get(endpoint, filter)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.gallery-image-remove {
  float: right;
}
.flaticon-close--gallery-image {
  color: red;
}
.villa-content {
  .ql-editor {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 0 10px 0;
      font-weight: bold;
    }
    p {
      padding: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      font-family: Poppins, Helvetica, 'sans-serif';
    }
    ul {
      li {
        font-family: Poppins, Helvetica, 'sans-serif';
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
  .ql-editor.ql-blank {
    min-height: 350px;
  }
}
.symbol-label-wrapper {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}
.h-90px {
  height: 90px !important;
  min-height: unset;
}
.w-90px {
  width: 90px !important;
}
.villa-description {
  .ql-editor {
    min-height: 350px !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 0 10px 0;
      font-weight: bold;
    }
    p {
      padding: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      font-family: Poppins, Helvetica, 'sans-serif';
    }
    ul {
      li {
        font-family: Poppins, Helvetica, 'sans-serif';
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
}
.form-control {
  &--category-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--tag-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--region-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
.dz-preview {
  display: none !important;
}
</style>
