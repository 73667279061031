var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-row-fluid"},[_c('notifications',{attrs:{"group":"notification","position":"bottom right"}}),(_vm.isLoaded)?_c('div',[_c('div',{staticClass:"card card-custom card-sticky gutter-b"},[_c('div',{staticClass:"card-header bg-light border-0",staticStyle:{"position":"sticky","top":"119px","background-color":"#fff","z-index":"50"}},[_c('b-col',{attrs:{"align-self":"center"}},[_c('b-nav',{attrs:{"card-header":"","tabs":"","align":"left"}},_vm._l((_vm.translationsLocales),function(loopLang,loopIndex){return _c('b-nav-item',{key:loopLang,attrs:{"title":loopLang.toUpperCase(),"active":_vm.tabIndex === loopIndex},on:{"click":function($event){return _vm.langClick(loopIndex, loopLang)}}},[_vm._v(_vm._s(loopLang.toUpperCase())+" ")])}),1)],1),_c('b-col',{staticClass:"p-0",attrs:{"align-self":"center"}},[_c('div',{staticClass:"float-right"},[_c('CardToolbar',{attrs:{"back-text":"All Categories","back-url":"/categories/list","edit-text":"Edit Category","edit-url":{
                        name: 'categories-edit',
                        params: { id: _vm.$route.params.id }
                      },"button-text":"Save Translations","dropdown-enabled":false},on:{"submitEvent":_vm.submit}})],1)])],1),_c('b-tabs',{attrs:{"card":"","nav-wrapper-class":"d-none","content-class":"mt-5"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.translationsLocales),function(loopLang,loopIndex){return _c('b-tab',{key:loopLang,attrs:{"no-body":"","title":loopLang.toUpperCase(),"active":_vm.tabIndex === loopIndex},on:{"click":function($event){return _vm.switchLang(loopLang)}}},[_c('div',{staticClass:"card-body pt-0"},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{staticClass:"form-group mb-8"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.translations[loopLang].title),expression:"translations[loopLang].title"}],class:[
                            'form-control form-control-solid form-control-lg',
                            { 'is-invalid': errors[0] }
                          ],attrs:{"type":"text","placeholder":'Title (' + loopLang.toUpperCase() + ')'},domProps:{"value":(_vm.translations[loopLang].title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.translations[loopLang], "title", $event.target.value)}}})])]}}],null,true)}),_c('div',{staticClass:"form-group mb-8"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Subtitle")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.translations[loopLang].subtitle),expression:"translations[loopLang].subtitle"}],staticClass:"form-control form-control-solid form-control-lg",attrs:{"type":"text","placeholder":'Subtitle (' + loopLang.toUpperCase() + ')'},domProps:{"value":(_vm.translations[loopLang].subtitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.translations[loopLang], "subtitle", $event.target.value)}}})]),_c('div',{staticClass:"form-group mb-8"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v("Description")]),_c('quill-editor',{staticClass:"translation-rte",attrs:{"content":_vm.translations[loopLang].description,"options":_vm.contentEditorOption},on:{"change":function($event){return _vm.onEditorChange($event)}}})],1)],1)])}),1)],1)]):_vm._e()],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }