<template>
  <router-view></router-view>
</template>

<style lang="scss">
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~animate.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';
@import 'assets/sass/style.vue';
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module'

export default {
  name: 'LikeIstria',
  mounted() {
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG)
  }
}
</script>
