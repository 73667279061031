<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row"></div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'dashboard',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Dashboard' }])
  }
}
</script>
