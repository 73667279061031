<template>
  <div>
    <div class="card card-custom gutter-b">
      <notifications group="notification" position="bottom right" />

      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Posts</span>
        </h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link
            to="/posts/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
            </span>
            New Post
          </router-link>
          <!--end::Button-->
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <!--begin::Table-->
        <div v-if="isLoaded" class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center table-borderless"
          >
            <thead>
              <tr class="bg-gray-100 text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Title</span>
                </th>
                <th style="min-width: 120px" class="text-right">Featured</th>
                <th style="min-width: 120px" class="text-right">Is Publish</th>
                <th style="min-width: 110px" class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(post, key) in posts" :key="post.id">
                <td class="pl-0 py-2">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50 flex-shrink-0 mr-4">
                      <div
                        class="symbol-label"
                        :style="{
                          'background-image':
                            'url(' +
                            imagePath +
                            '/media/cache/resolve/50x50/' +
                            post.featuredImage.src +
                            ')'
                        }"
                      ></div>
                    </div>
                    <div>
                      <router-link
                        :to="'/posts/edit/' + post.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {{ post.title }}
                      </router-link>
                      <span class="text-muted font-weight-bold d-block">
                        <div>
                          <strong>Created: </strong
                          ><span>{{
                            post.createdAt | moment('DD.MM.YYYY')
                          }}</span>
                        </div>
                      </span>
                    </div>
                  </div>
                </td>
                <td class="text-right">
                  <LiSwitch
                    @checkedEvent="
                      updateIsFeatured(post.isFeatured, post.id, key)
                    "
                    :is-checked="post.isFeatured"
                    classes="switch-sm switch-icon float-right"
                    name="villaIsFeatured"
                  />
                </td>
                <td>
                  <LiSwitch
                    @checkedEvent="
                      updateIsPublish(post.isPublish, post.id, key)
                    "
                    :is-checked="post.isPublish"
                    classes="switch-sm switch-icon switch-outline switch-success float-right"
                    name="villaIsPublish"
                  />
                </td>
                <td class="pr-0 text-right">
                  <a
                    :href="clientURL + '/en/blog/' + post.slug"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                    target="_blank"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/General/Visible.svg"
                        title="View"
                        aria-label="View"
                      />
                    </span>
                  </a>
                  <router-link
                    :to="'/posts/edit/' + post.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/Design/Edit.svg"
                        title="Edit"
                        aria-label="Edit"
                      />
                    </span>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'posts-edit-translations',
                      params: { id: post.id }
                    }"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/translate.svg"
                        title="Edit Translations"
                        aria-label="Edit Translations"
                      />
                    </span>
                  </router-link>
                  <a
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <div v-else class="w-100 d-flex justify-content-center my-10">
          <div class="d-flex align-items-center">
            <div class="mr-2 text-muted">Loading...</div>
            <div class="spinner spinner-primary mr-10"></div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import ApiService from '@/core/services/api.service'

export default {
  components: {
    LiSwitch
  },
  data() {
    return {
      posts: [],
      isLoaded: false,
      imagePath: process.env.VUE_APP_ROUTE_API_URL,
      clientURL: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Posts' }])

    // get all posts
    ApiService.get(
      'posts',
      '?order[createdAt]=desc&isPublish=true&page=1'
    ).then(({ data }) => {
      this.posts = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {
    updateIsPublish(checked, entityId, key) {
      this.posts[key].isPublish = !checked

      ApiService.update('posts', entityId, {
        isPublish: this.posts[key].isPublish
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Post saved.'
        })
      })
    },
    updateIsFeatured(checked, entityId, key) {
      this.posts[key].isFeatured = !checked

      ApiService.update('posts', entityId, {
        isFeatured: this.posts[key].isFeatured
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Post saved.'
        })
      })
    }
  }
}
</script>
