<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="d-flex flex-row">
        <div class="flex-row-fluid mr-lg-8">
          <notifications group="notification" position="bottom right" />

          <div class="card card-custom card-sticky gutter-b">
            <div
              class="card-header border-0"
              style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
            >
              <div class="card-title">
                <h3 class="card-label">
                  Edit Region
                </h3>
              </div>
              <CardToolbar
                @submitEvent="submit"
                back-text="All Regions"
                back-url="/regions/list"
                button-text="Region"
                action-text="Save"
                :dropdownEnabled="false"
              />
            </div>
            <!--begin::Body-->
            <div class="card-body pt-0">
              <ValidationProvider
                name="Title"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Title</label>
                  <input
                    v-model="region.translations.en.title"
                    type="text"
                    :class="[
                      'form-control form-control-solid form-control-lg',
                      { 'is-invalid': errors[0] }
                    ]"
                    placeholder="Region Title "
                  />
                </div>
              </ValidationProvider>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="flex-column offcanvas-mobile w-300px w-xl-325px"></div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import ApiService from '@/core/services/api.service'

export default {
  components: {
    CardToolbar
  },
  data() {
    return {
      region: {
        translations: {
          en: {
            locale: 'en',
            title: null
          }
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Regions', route: '/regions/list' },
      { title: 'Edit Region' }
    ])

    ApiService.get(
      'regions',
      this.$route.params.id + '?groups[]=translations'
    ).then(({ data }) => {
      this.region.translations = data.translations
      this.region.translations.en = {}
      this.region.translations.en.locale = 'en'
      this.region.translations.en.title = data.title
    })
  },
  methods: {
    onSubmit() {
      this.submit()
      ApiService.update('regions', this.$route.params.id, this.region).then(
        () => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Region saved'
          })
        }
      )
    },
    submit() {}
  }
}
</script>
