import Vue from 'vue'

/**
 * Service to catch and display errors
 */
const ErrorService = {
  init() {
    // this.initWindowErrorHandler();
  },

  log(level, error) {
    if (process.env.NODE_ENV !== 'production') {
      console[level](error)
    }
    this.displayErrorAlert(error)
  },

  onError(error) {
    this.log('error', error)
    const response = error.response
    if (response && response.status >= 400 && response.status < 405) {
      return false
    }
  },

  onWarn(error) {
    this.log('warn', error)
  },

  onInfo(error) {
    this.log('info', error)
  },

  onDebug(error) {
    this.log('debug', error)
    const response = error.response
    if (response && response.status >= 400 && response.status < 405) {
      // Can be handled differently
      return false
    }
  },

  initWindowErrorHandler() {
    const scope = this
    window.onerror = (message, url, lineNo, columnNo, error) => {
      console.log(error, 'test')
      if (error) {
        scope.onError(error)
        console.log(message, url, lineNo, columnNo, error)
      }
    }
  },

  displayErrorAlert(message) {
    Vue.notify({
      group: 'notification',
      type: 'error',
      title: 'An error occured!',
      text: message
    })
  }
}

export default ErrorService
