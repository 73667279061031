<template>
  <div>
    <div class="card card-custom gutter-b">
      <notifications group="notification" position="bottom right" />

      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Villas</span>
        </h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link
            to="/villas/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Home/Armchair.svg" />
            </span>
            New Villa
          </router-link>
          <!--end::Button-->
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <!--begin::Table-->
        <div v-if="isLoaded" class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center table-borderless"
          >
            <thead>
              <tr class="bg-gray-100 text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Title</span>
                </th>
                <th style="min-width: 120px" class="text-right">Featured</th>
                <th style="min-width: 120px" class="text-right">Is Publish</th>
                <th style="min-width: 110px" class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(villa, key) in villas" :key="villa.id">
                <td class="pl-0 py-2">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-50 flex-shrink-0 mr-4">
                      <div
                        class="symbol-label"
                        :style="{
                          'background-image':
                            'url(' +
                            appPath +
                            '/media/cache/resolve/50x50/' +
                            villa.featuredImage.src +
                            ')'
                        }"
                      ></div>
                    </div>
                    <div>
                      <router-link
                        :to="'/villas/edit/' + villa.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {{ villa.title }}
                      </router-link>
                      <span class="text-muted font-weight-bold d-block">
                        <div>
                          <strong>Created: </strong
                          ><span>{{
                            villa.createdAt | moment('DD.MM.YYYY')
                          }}</span>
                        </div>
                      </span>
                    </div>
                  </div>
                </td>
                <td class="text-right">
                  <LiSwitch
                    @checkedEvent="
                      updateIsFeatured(villa.isFeatured, villa.id, key)
                    "
                    :is-checked="villa.isFeatured"
                    classes="switch-sm switch-icon float-right"
                    name="villaIsFeatured"
                  />
                </td>
                <td>
                  <LiSwitch
                    @checkedEvent="
                      updateIsPublish(villa.isPublish, villa.id, key)
                    "
                    :is-checked="villa.isPublish"
                    classes="switch-sm switch-icon switch-outline switch-success float-right"
                    name="villaIsPublish"
                  />
                </td>
                <td class="pr-0 text-right">
                  <a
                    :href="appPath + '/en/villas/' + villa.slug"
                    class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                    target="_blank"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Visible.svg" />
                    </span>
                  </a>
                  <router-link
                    :to="'/villas/edit/' + villa.id"
                    class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/Design/Edit.svg" />
                    </span>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'villas-edit-translations',
                      params: { id: villa.id }
                    }"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/translate.svg"
                        title="Edit Translations"
                        aria-label="Edit Translations"
                      />
                    </span>
                  </router-link>
                  <button
                    @click="copyEntity(villa)"
                    class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Duplicate.svg" />
                    </span>
                  </button>
                  <button
                    @click="removeEntity(villa.id)"
                    class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <div v-else class="w-100 d-flex justify-content-center my-10">
          <div class="d-flex align-items-center">
            <div class="mr-2 text-muted">Loading...</div>
            <div class="spinner spinner-primary mr-10"></div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import ApiService from '@/core/services/api.service'

export default {
  components: {
    LiSwitch
  },
  data() {
    return {
      villas: [],
      isLoaded: false,
      // TODO: Move these into groups[]=admin:list or something and do the @Groups on backend so it's easier to handle
      propertiesFetchList:
        '&properties[]=id&properties[]=slug&properties[]=title&properties[]=createdAt&properties[]=isFeatured&properties[]=isPublish&properties[]=inHero&properties[]=featuredImage',
      appPath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Villas' }])

    ApiService.get(
      'villas',
      '?order[translations.title]=asc' + this.propertiesFetchList
    ).then(({ data }) => {
      this.villas = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {
    updateIsPublish(checked, entityId, key) {
      this.villas[key].isPublish = !checked

      ApiService.update('villas', entityId, {
        isPublish: this.villas[key].isPublish
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Villa saved.'
        })
      })
    },
    updateIsFeatured(checked, entityId, key) {
      this.villas[key].isFeatured = !checked

      ApiService.update('villas', entityId, {
        isFeatured: this.villas[key].isFeatured
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Villa saved.'
        })
      })
    },
    updateInHero(checked, entityId, key) {
      // console.log('updateInHero => ', checked)

      ApiService.update('villas', entityId, {
        inHero: this.villas[key].inHero
      }).then(() => {
        // console.log(response)
      })
    },
    copyEntity(entity) {
      const confirmation = confirm(
        'Are you sure you wish to create a copy of this villa?'
      )
      if (confirmation === false) return

      // Fetch all details of entity for copying, since
      // we've removed fetching everything on list since it's slow
      ApiService.get('villas', entity.id + '?groups[]=translations').then(
        ({ data }) => {
          entity = data

          delete entity['@type']
          delete entity['@id']
          delete entity.id
          delete entity.slug
          delete entity.createdAt
          delete entity.updatedAt
          delete entity.reviews
          delete entity.experiences
          delete entity.reservations

          entity.isPublish = false
          entity.isFeatured = false
          entity.isExclusive = false
          entity.inHomepageHero = false

          entity.translations = data.translations
          entity.translations.en = {}
          entity.translations.en.locale = 'en'
          entity.translations.en.type = entity.type
          entity.translations.en.subtitle = entity.subtitle
          entity.translations.en.description = entity.description
          entity.translations.en.facilities = entity.facilities
          entity.translations.en.seoMeta = entity.seoMeta
          entity.translations.en.location = entity.location
          entity.translations.en.title = entity.title + ' Copy'

          // Must ensure copies do not have same title/slug as existing content or things may break on frontend
          if (entity.translations.de) {
            entity.translations.de.title = entity.translations.de.title
              ? entity.translations.de.title + ' Copy'
              : entity.translations.en.title
            entity.translations.de.slug = entity.translations.de.slug
              ? entity.translations.de.slug + '-copy'
              : entity.translations.en.slug + '-copy'
          }
          if (entity.translations.hr) {
            entity.translations.hr.title = entity.translations.hr.title
              ? entity.translations.hr.title + ' Copy'
              : entity.translations.hr.title
            entity.translations.hr.slug = entity.translations.de.slug
              ? entity.translations.hr.slug + '-copy'
              : entity.translations.en.slug + '-copy'
          }

          // Must remove these or they trigger mayhem
          delete entity.type
          delete entity.title
          delete entity.subtitle
          delete entity.seoMeta
          delete entity.facilities
          delete entity.location

          ApiService.post('villas', entity).then(() => {
            this.isLoaded = false
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Villa copied successfully.'
            })
            ApiService.get(
              'villas',
              '?order[createdAt]=desc' + this.propertiesFetchList
            ).then(({ data }) => {
              this.villas = [...data['hydra:member']]
              this.isLoaded = true
            })
          })
        }
      )
    },
    async deleteEntity(id) {
      try {
        return await ApiService.delete('villas/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removeEntity(id) {
      const confirmation = confirm(
        'Are you sure you wish to delete this villa?'
      )
      if (confirmation === false) return

      this.deleteEntity(id).then(({ status }) => {
        if (status === 204) {
          this.villas = this.villas.filter(product => product.id !== id)
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Villa deleted.'
          })
        }
      })
    }
  }
}
</script>
