<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div class="row">
      <div class="col-12">
        <div class="card card-custom">
          <div class="card-header"></div>
          <div class="card-body">
            <form class="form">
              <div class="row">
                <div class="col-xl-1"></div>
                <div class="col-xl-10">
                  <div class="my-5">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="font-weight-bolder">Villa</label>
                          <vSelect
                            class="form-control form-control--region-select"
                            placeholder="Select villa"
                            v-model="reservation.villa"
                            @input="onSelectedVilla"
                            :options="villas"
                            :reduce="villa => villa['@id']"
                            label="title"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <v-date-picker
                          style="width: 100%;"
                          ref="picker"
                          v-model="range"
                          :step="1"
                          :masks="masks"
                          :columns="$screens({ default: 2, lg: 3 })"
                          is-range
                          color="gray"
                          :first-day-of-week="2"
                          :min-date="new Date()"
                          :attributes="days"
                          @input="onRangeSelect"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group mt-4">
                          <label for="reservation-special-request">Note</label>
                          <textarea
                            v-model="reservation.meta.specialRequest"
                            id="reservation-special-request"
                            class="form-control"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="separator separator-dashed my-10"></div>
                  <div class="my-52">
                    <button
                      v-if="isVillaSelect && isRangeSelect"
                      @click.prevent="onSubmit"
                      class="btn btn-light-primary btn-block text-uppercase font-weight-bold"
                    >
                      Book
                    </button>
                  </div>
                </div>
                <div class="col-xl-2"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { addDays } from 'date-fns'

export default {
  components: {
    vSelect,
    'v-date-picker': DatePicker
  },
  data() {
    return {
      reservation: {
        dateFrom: null,
        dateTo: null,
        villa: null,
        personalDetail: {
          name: null,
          surname: null,
          email: null,
          mobile: null,
          country: null
        },
        paymentOption: {
          price: {
            total: null,
            advancePayment: null,
            remainingPayment: null
          },
          payload: null,
          paymentType: null
        },
        status: '441199', // owner 331199
        isBooked: true,
        meta: {
          guests: {
            pets: null,
            adults: null,
            infants: null,
            children: null
          },
          specialRequest: null
        },
        isRemindLinkSend: true
      },
      villas: [],
      villaId: null,
      days: [],
      availableDays: [],
      reservedDays: [],
      range: {
        start: null,
        end: null
      },
      masks: {
        input: 'YYYY-MM-DD h:mm A'
      },
      isLoaded: false,
      isVillaSelect: false,
      isRangeSelect: false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Reservations', route: '/Reservations/list' },
      { title: 'New Reservation' }
    ])

    ApiService.get('villas').then(({ data }) => {
      this.villas = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {
    onSelectedVilla(payload) {
      this.villaId = payload.replace('/api/v1/villas/', '')
      ApiService.get('villas', payload.replace('/api/v1/villas/', '')).then(
        ({ data }) => {
          this.range.start = null
          this.range.end = null
          this.days = []

          if (data.reservations.length) {
            data.reservations.forEach(reservation => {
              const dateFrom = addDays(new Date(reservation.dateFrom), +1)
              const dateTo = addDays(new Date(reservation.dateTo), -1)

              this.days.push({
                dates: {
                  start: dateFrom,
                  end: dateTo
                },
                highlight: {
                  color: 'red',
                  fillMode: 'light',
                  class: 'li-reserved',
                  contentClass: 'li-content-reserved'
                }
              })
            })
          }

          this.isVillaSelect = true
        }
      )
    },
    onRangeSelect(payload) {
      this.reservation.dateFrom = payload.start
      this.reservation.dateTo = payload.end

      this.isRangeSelect = true
    },
    onSubmit() {
      ApiService.post('reservations', this.reservation).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Reservation added'
        })
        setTimeout(() => {
          this.$router.push({ name: 'reservations-list' })
        }, 1000)
      })
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
.form-control {
  &--category-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--tag-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--region-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}

.li-disable,
.li-content-disable {
  color: #cbd5e0;
  pointer-events: none;
}
.li-reserved,
.li-content-disable {
  pointer-events: none;
}
.li-reserved,
.li-content-reserved {
  pointer-events: none;
}
.vc-day-content.is-disabled {
  color: #cbd5e0 !important;
}
</style>
