<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form ref="form" @submit.prevent="handleSubmit(onSubmit)">
      <div class="d-flex flex-row">
        <div class="flex-row-fluid mr-lg-8">
          <notifications group="notification" position="bottom right" />

          <div
            class="card card-custom card-sticky gutter-b"
            id="kt_page_sticky_card"
          >
            <div
              class="card-header border-0"
              style="position: sticky; top: 119px; background-color: #fff; z-index: 50;"
            >
              <div class="card-title">
                <h3 class="card-label">
                  Add New Experience
                </h3>
              </div>
              <CardToolbar
                @submitEvent="submit"
                back-text="All Experiences"
                back-url="/experiences/list"
                button-text="Experience"
                action-text="Add"
                :dropdown-enabled="false"
              />
            </div>
            <!--begin::Body-->
            <div class="card-body pt-0">
              <ValidationProvider
                name="Title"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Title</label>
                  <input
                    v-model="experience.translations.en.title"
                    type="text"
                    :class="[
                      'form-control form-control-solid form-control-lg',
                      { 'is-invalid': errors[0] }
                    ]"
                    placeholder="Experience title "
                  />
                </div>
              </ValidationProvider>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Subtitle</label>
                <input
                  v-model="experience.translations.en.subtitle"
                  type="text"
                  class="form-control form-control-solid form-control-lg"
                  placeholder="Experience subtitle"
                />
              </div>

              <div class="form-group mb-8">
                <label class="font-weight-bolder">Content</label>
                <quill-editor
                  class="experience-content"
                  :content="experience.translations.en.content"
                  :options="contentEditorOption"
                  @change="onEditorChange($event)"
                />
              </div>

              <div class="row">
                <div class="col-md-12">
                  <b-card no-body class="border-0">
                    <b-tabs pills vertical nav-wrapper-class="col-3">
                      <b-tab active>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-location"></i>
                          </span>
                          <span class="font-weight-bolder">Multimedia</span>
                        </template>
                        <b-card-text>
                          <div class="card card-custom gutter-b">
                            <div class="card-header border-0">
                              <div class="card-title">
                                <h3 class="card-label">Multimedia</h3>
                              </div>
                            </div>
                            <div class="card-body pt-0">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Featured Image</label
                                >

                                <Dropzone
                                  class="dropzone dropzone-default dropzone-primary dz-clickable h-90px w-90px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0"
                                  ref="villaFeaturedImageDropzone"
                                  @vdropzone-file-added="featuredImageAdd"
                                  @vdropzone-complete="featuredImageComplete"
                                  @vdropzone-success="featuredImageSuccess"
                                  id="experienceFeaturedImage"
                                  :options="featuredImageDropzoneOptions"
                                  :useCustomSlot="true"
                                >
                                  <span class="svg-icon svg-icon-lg m-0">
                                    <inline-svg
                                      src="media/svg/icons/Design/Image.svg"
                                    />
                                  </span>
                                </Dropzone>

                                <div
                                  v-if="experience.featuredImage.src"
                                  class="symbol symbol-90 flex-shrink-0 mr-4 cursor-pointer"
                                >
                                  <div
                                    class="symbol-label"
                                    v-bind:style="{
                                      'background-image':
                                        'url(' +
                                        imagePath +
                                        experience.featuredImage.src +
                                        ')'
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Image Gallery</label
                                >

                                <Dropzone
                                  class="dropzone dropzone-default dropzone-primary dz-clickable h-90px w-90px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0"
                                  ref="villaImageGalleryDropzone"
                                  @vdropzone-file-added="featuredImageAdd"
                                  @vdropzone-complete="featuredImageComplete"
                                  @vdropzone-success="imageGallerySuccess"
                                  id="experienceImageGallery"
                                  :options="imageGalleryDropzoneOptions"
                                  :useCustomSlot="true"
                                >
                                  <span class="svg-icon svg-icon-lg m-0">
                                    <inline-svg
                                      src="media/svg/icons/Design/Image.svg"
                                    />
                                  </span>
                                </Dropzone>

                                <draggable
                                  class="symbol-label-wrapper"
                                  v-model="experience.imageGallery"
                                  group="people"
                                  @start="drag = true"
                                  @end="drag = false"
                                >
                                  <div
                                    v-for="(image,
                                    index) in experience.imageGallery"
                                    :key="image + index"
                                    class="symbol-label h-90px w-90px mr-3 mb-3 cursor-pointer"
                                    v-bind:style="{
                                      'background-image':
                                        'url(' +
                                        imagePath +
                                        '/media/cache/resolve/90x90' +
                                        image +
                                        ')'
                                    }"
                                  >
                                    <span
                                      @click="removeImageFromGallery(image)"
                                      class="gallery-image-remove"
                                      ><i
                                        class="flaticon-close flaticon-close--gallery-image"
                                      ></i
                                    ></span>
                                  </div>
                                </draggable>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-layers-1"></i>
                          </span>
                          <span class="font-weight-bolder">Meta</span>
                        </template>
                        <b-card-text>
                          <div class="card card-custom gutter-b">
                            <div class="card-header border-0">
                              <div class="card-title">
                                <h3 class="card-label">Meta</h3>
                              </div>
                            </div>
                            <div class="card-body pt-0">
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Company</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="experience.meta.company"
                                      type="text"
                                      class="form-control"
                                      placeholder="Company"
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Tel</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="experience.meta.tel"
                                      type="text"
                                      class="form-control"
                                      placeholder="Tel"
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Email</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="experience.meta.email"
                                      type="text"
                                      class="form-control"
                                      placeholder="Email"
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  class="col-form-label text-right col-lg-3 col-sm-12"
                                  >Web</label
                                >
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="flaticon-price-tag"></i>
                                      </span>
                                    </div>
                                    <input
                                      v-model="experience.meta.web"
                                      type="text"
                                      class="form-control"
                                      placeholder="Web"
                                    />
                                  </div>
                                  <div
                                    class="fv-plugins-message-container"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-location"></i>
                          </span>
                          <span class="font-weight-bolder">Location</span>
                        </template>
                        <b-card-text>
                          <div class="card card-custom gutter-b">
                            <div class="card-header border-0">
                              <div class="card-title">
                                <h3 class="card-label">Region & Location</h3>
                              </div>
                            </div>
                            <div class="card-body pt-0">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Regions</label
                                >
                                <vSelect
                                  class="form-control form-control--region-select"
                                  placeholder="Select experience region"
                                  v-model="experience.regions"
                                  multiple
                                  :options="regions"
                                  :reduce="region => region['@id']"
                                  label="title"
                                />
                                <b-button
                                  @click="showModal"
                                  class="btn btn-light-primary font-weight-bold btn-block mt-4"
                                  >Add Region</b-button
                                >
                                <b-modal
                                  id="add-region-modal"
                                  centered
                                  hide-header
                                  hide-footer
                                >
                                  <template>
                                    <div class="form-group mb-2">
                                      <div class="input-group">
                                        <input
                                          id="regionName"
                                          type="text"
                                          v-model="newRegion"
                                          placeholder="Region name"
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                    <button
                                      @click="addRegion"
                                      class="btn btn-light-primary font-weight-bold btn-block mt-4"
                                    >
                                      Add
                                    </button>
                                  </template>
                                </b-modal>
                                <div
                                  class="separator separator-dashed mt-8 mb-8"
                                ></div>
                              </div>
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Location Lat</label
                                >
                                <div class="input-group">
                                  <input
                                    type="text"
                                    v-model.number="experience.location.lat"
                                    class="form-control"
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text">
                                      <i class="flaticon-price-tag"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder"
                                  >Location Lng</label
                                >
                                <div class="input-group">
                                  <input
                                    type="text"
                                    v-model.number="experience.location.lng"
                                    class="form-control"
                                  />
                                  <div class="input-group-append">
                                    <span class="input-group-text">
                                      <i class="flaticon-price-tag"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-tab>

                      <b-tab>
                        <template v-slot:title>
                          <span class="nav-icon">
                            <i class="flaticon2-layers-1"></i>
                          </span>
                          <span class="font-weight-bolder">SEO</span>
                        </template>
                        <b-card-text>
                          <seo
                            :meta="experience.translations.en.seoMeta"
                            :entity-url="'https://www.likeistria.com/'"
                          ></seo>
                        </b-card-text>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="flex-column offcanvas-mobile w-300px w-xl-325px">
          <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Options</span
                >
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-0">
              <div class="row">
                <label class="col-6 col-form-label">Publish</label>
                <div class="col-6">
                  <LiSwitch
                    @checkedEvent="updateIsPublish"
                    :is-checked="experience.isPublish"
                    classes="switch-icon float-right"
                    name="villaIsPublish"
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-6 col-form-label">Featured</label>
                <div class="col-6">
                  <LiSwitch
                    @checkedEvent="updateIsFeatured"
                    :is-checked="experience.isFeatured"
                    classes="switch-icon float-right"
                    name="villaIsFeatured"
                  />
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
          <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 pt-5">
              <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark"
                  >Taxonomies</span
                >
              </h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-12">
                  <label>Category</label>
                </div>

                <div class="col-12">
                  <ValidationProvider
                    name="Category"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <div :class="['form-group', { 'is-invalid': errors[0] }]">
                      <vSelect
                        class="form-control form-control--category-select"
                        v-model="experience.category"
                        placeholder="Choose category"
                        :options="categories"
                        :reduce="category => category['@id']"
                        label="title"
                      />
                      <span v-if="errors[0]" class="text-danger pt-4"
                        >Select category</span
                      >
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-12"></div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label>Tags</label>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <vSelect
                      class="form-control form-control--tag-select"
                      placeholder="Choose tags"
                      v-model="experience.tags"
                      multiple
                      :options="tags"
                      :reduce="tag => tag['@id']"
                      label="title"
                    />
                    <b-button
                      @click="addTagsModal"
                      class="btn btn-light-primary font-weight-bold dropdown-toggle btn-block mt-4"
                      >New Tag</b-button
                    >
                    <b-modal
                      id="add-tag-modal"
                      centered
                      hide-header
                      hide-footer
                    >
                      <template>
                        <div class="form-group mb-2">
                          <div class="input-group">
                            <input
                              id="tagTitle"
                              type="text"
                              v-model="newTag"
                              placeholder="Tag title"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <button
                          @click="addTag"
                          class="btn btn-light-primary font-weight-bold btn-block mt-4"
                        >
                          Add
                        </button>
                      </template>
                    </b-modal>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import Seo from '@/view/content/components/seo/Seo'
import Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import ApiService from '@/core/services/api.service'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  components: {
    vSelect,
    LiSwitch,
    CardToolbar,
    Dropzone,
    Seo,
    'quill-editor': quillEditor,
    draggable
  },
  data() {
    return {
      experience: {
        translations: {
          en: {
            locale: 'en',
            title: '',
            subtitle: '',
            content: '',
            seoMeta: {
              ogUrl: null,
              ogImage: null,
              ogTitle: null,
              keywords: null,
              canonical: null,
              ogSiteName: null,
              description: null,
              twitterCard: null,
              twitterImage: null,
              twitterTitle: null,
              ogDescription: null,
              ogImageSecureUrl: null,
              twitterDescription: null
            }
          }
        },
        featuredImage: {
          src: '',
          alt: ''
        },
        imageGallery: [],
        meta: {
          company: null,
          tel: null,
          email: null,
          web: null
        },
        location: {
          lat: null,
          lng: null
        },
        isPublish: false,
        isFeatured: false,
        category: null,
        tags: [],
        regions: []
      },
      featuredImageDropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 1
      },
      imageGalleryDropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 50
      },
      contentEditorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean', 'link']
          ]
        }
      },
      imagePath: process.env.VUE_APP_ROUTE_API_URL,
      newRegion: null,
      newTag: null,
      isLoaded: false,
      newCategory: null,
      categories: [],
      tags: [],
      regions: [],
      categoryPage: 1,
      tagsPage: 1
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Experiences', route: '/experiences/list' },
      { title: 'New Experience' }
    ])

    this.getCategories(this.categoryPage).then(response => {
      this.categories = [...response.data['hydra:member']]
    })
    this.getTags(this.tagsPage).then(({ data }) => {
      this.tags = [...data['hydra:member']]
    })
    ApiService.get('regions', '?itemsPerPage=100').then(({ data }) => {
      this.regions = [...data['hydra:member']]
    })
  },
  methods: {
    onSubmit() {
      // Auto slugify on the server when slug field is empty
      if (!this.experience.translations.en.slug) {
        delete this.experience.translations.en.slug
      }
      ApiService.post('experiences', this.experience, {
        Headers: {
          ContentType: 'application/ld+json'
        }
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Experience added.'
        })

        setTimeout(() => {
          this.$router.push({ name: 'experiences-list' })
        }, 500)
      })
      //this.$refs.form.submit()
    },
    submit() {},
    updateIsPublish(checked) {
      this.experience.isPublish = checked
    },
    updateIsFeatured(checked) {
      this.experience.isFeatured = checked
    },
    featuredImageAdd() {},
    featuredImageSuccess(_, response) {
      this.experience.featuredImage.src = '/uploads/' + response
    },
    imageGallerySuccess(_, response) {
      this.experience.imageGallery.push('/uploads/' + response)
    },
    featuredImageComplete() {},

    removeImageFromGallery(image) {
      let confirmation = confirm('Delete image?')
      if (confirmation === false) return

      this.experience.imageGallery = this.experience.imageGallery.filter(
        gImage => gImage !== image
      )
    },

    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      this.experience.translations.en.content = html
    },

    onChangeList: function({ source, destination }) {
      this.source = source
      this.destination = destination
    },
    async getCategories(page) {
      try {
        return await ApiService.get(
          'categories',
          `?isExperienceCategory=true&page=${page}`
        )
      } catch (e) {
        throw new Error(e)
      }
    },
    async getTags(page) {
      try {
        return await ApiService.get('tags', `?page=${page}`)
      } catch (e) {
        throw new Error(e)
      }
    },
    // show modal callback
    showModal() {
      this.$bvModal.show('add-region-modal')
      setTimeout(() => {
        document.getElementById('regionName').focus()
      }, 0)
    },

    // region
    addRegion() {
      let postRegion = {
        translations: {
          en: {
            locale: 'en',
            title: this.newRegion
          }
        }
      }
      ApiService.post('regions', postRegion).then(({ data }) => {
        this.villa.regions.push(data)
        this.$bvModal.hide('add-region-modal')
        this.newRegion = ''

        ApiService.get('regions', '?itemsPerPage=100').then(({ data }) => {
          this.regions = [...data['hydra:member']]
        })
      })
    },
    addTagsModal() {
      this.$bvModal.show('add-tag-modal')
      setTimeout(() => {
        document.getElementById('tagTitle').focus()
      }, 0)
    },
    addTag() {
      let postTag = {
        translations: {
          en: {
            locale: 'en',
            title: this.newTag
          }
        }
      }
      ApiService.post('tags', postTag).then(({ data }) => {
        this.tags.push(data)
        this.experience.tags.push(data['@id'])
        this.$bvModal.hide('add-tags-modal')
        this.newTag = ''

        ApiService.get('tags').then(({ data }) => {
          this.tags = [...data['hydra:member']]

          this.$bvModal.hide('add-tag-modal')
        })
      })
    }
  }
}
</script>

<style lang="scss">
.experience-content {
  .ql-editor {
    min-height: 350px !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0 0 10px 0;
      font-weight: bold;
    }
    p {
      padding: 0 0 10px 0;
      font-size: 14px;
      line-height: 24px;
      font-family: Poppins, Helvetica, 'sans-serif';
    }
    ul {
      li {
        font-family: Poppins, Helvetica, 'sans-serif';
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }
}
.dz-preview,
.dz-file-preview {
  display: none;
}
.form-control {
  &--category-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
  &--tag-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
      box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left /*rtl:right*/;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
