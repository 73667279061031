<template>
  <div>
    <div class="card card-custom gutter-b">
      <notifications group="notification" position="bottom right" />

      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Pages</span>
        </h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link
            to="/pages/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Shopping/Box1.svg" />
            </span>
            New Page
          </router-link>
          <!--end::Button-->
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        <!--begin::Table-->
        <div v-if="isLoaded" class="table-responsive">
          <table
            class="table table-head-custom table-head-bg table-vertical-center table-borderless"
          >
            <thead>
              <tr class="bg-gray-100 text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Title</span>
                </th>
                <th style="min-width: 120px" class="text-right">Is Publish</th>
                <th style="min-width: 110px" class="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(page, key) in pages" :key="page.id">
                <td class="pl-0 py-2">
                  <div class="d-flex align-items-center">
                    <div>
                      <router-link
                        :to="'/pages/edit/' + page.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {{ page.title }}
                      </router-link>
                      <span class="text-muted font-weight-bold d-block">
                        <strong>Created: </strong
                        ><span>{{
                          page.createdAt | moment('DD.MM.YYYY.')
                        }}</span>
                        &nbsp;<strong>Updated: </strong
                        ><span>{{
                          page.updatedAt | moment('DD.MM.YYYY. HH:mm:ss')
                        }}</span>
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <LiSwitch
                    @checkedEvent="
                      updateIsPublish(page.isPublish, page.id, key)
                    "
                    :is-checked="page.isPublish"
                    classes="switch-sm switch-icon switch-outline switch-success float-right"
                    name="pageIsPublish"
                  />
                </td>
                <td class="pr-0 text-right">
                  <a
                    :href="clientURL + '/en/pages/' + page.slug"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                    target="_blank"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/General/Visible.svg"
                        title="View"
                        aria-label="View"
                      />
                    </span>
                  </a>
                  <router-link
                    :to="'/pages/edit/' + page.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/Design/Edit.svg"
                        title="Edit"
                        aria-label="Edit"
                      />
                    </span>
                  </router-link>
                  <router-link
                    :to="{
                      name: 'pages-edit-translations',
                      params: { id: page.id }
                    }"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/translate.svg"
                        title="Edit Translations"
                        aria-label="Edit Translations"
                      />
                    </span>
                  </router-link>
                  <button
                    @click="removeEntity(page.id)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
        <div v-else class="w-100 d-flex justify-content-center my-10">
          <div class="d-flex align-items-center">
            <div class="mr-2 text-muted">Loading...</div>
            <div class="spinner spinner-primary mr-10"></div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import LiSwitch from '@/view/content/components/LiSwitch'
import ApiService from '@/core/services/api.service'

export default {
  components: {
    LiSwitch
  },
  data() {
    return {
      pages: [],
      isLoaded: false,
      clientURL: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Pages' }])

    // Get all pages
    ApiService.get('pages', '?order[createdAt]=desc&page=1').then(
      ({ data }) => {
        this.pages = [...data['hydra:member']]
        this.isLoaded = true
      }
    )
  },
  methods: {
    async deleteEntity(id) {
      try {
        return await ApiService.delete('pages/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    updateIsPublish(checked, entityId, key) {
      this.pages[key].isPublish = !checked

      ApiService.update('pages', entityId, {
        isPublish: this.pages[key].isPublish
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Page saved.'
        })
      })
    },
    removeEntity(id) {
      const confirmation = confirm('Are you sure you wish to delete the Page?')
      if (confirmation === false) return

      this.deleteEntity(id).then(({ status }) => {
        if (status === 204) {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Page deleted.'
          })
          this.pages = this.pages.filter(page => page.id !== id)
        }
      })
    }
  }
}
</script>
