<template>
  <div v-if="isLoaded">
    <div class="card card-custom gutter-b">
      <notifications group="notification" position="bottom right" />
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark"
            >Reservation status</span
          >
        </h3>
      </div>
      <div class="card-body pt-0 pb-3">
        <form class="form">
          <div class="row">
            <div class="col">
              <div class="table-responsive">
                <table
                  class="table table-head-custom table-head-bg table-vertical-center table-borderless"
                >
                  <thead>
                    <tr class="bg-gray-100 text-left">
                      <th></th>
                      <th>Downpayment</th>
                      <th>Remaining balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="reservation.paymentOption.price.total">
                      <td>
                        <strong>
                          {{
                            currencyFormat(
                              reservation.paymentOption.price.total
                            )
                          }}€
                        </strong>
                      </td>
                      <td>
                        {{
                          currencyFormat(
                            reservation.paymentOption.price.downPayment
                          )
                        }}€<br />
                        {{ reservation.paymentOption.insurance.type }}
                        {{
                          currencyFormat(
                            reservation.paymentOption.insurance.value
                          )
                        }}€
                      </td>
                      <td>
                        {{
                          currencyFormat(
                            reservation.paymentOption.price.remainingPayment
                          )
                        }}€
                      </td>
                    </tr>
                    <tr>
                      <td>Type</td>
                      <td>
                        <select class="form-control" v-model="downPaymentType">
                          <option value="1">Bank Transfer</option>
                          <option value="2">WSpay</option>
                          <option value="3">Owner Booking</option>
                          <option value="4">Admin Booking</option>
                        </select>
                      </td>
                      <td>
                        <select class="form-control" v-model="remainingType">
                          <option value="0">Not Set</option>
                          <option value="1">Bank Transfer</option>
                          <option value="2">WSpay</option>
                          <option value="3">Owner Booking</option>
                          <option value="4">Admin Booking</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Payment</td>
                      <td>
                        <select class="form-control" v-model="downPaymentPaid">
                          <option value="0">Not Settled</option>
                          <option value="1">Settled</option>
                        </select>
                      </td>
                      <td>
                        <select class="form-control" v-model="remainingPaid">
                          <option value="0">Not Settled</option>
                          <option value="1">Settled</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>
                        <select
                          class="form-control"
                          v-model="downPaymentStatus"
                        >
                          <option value="0">Started</option>
                          <option value="1">Cancelled</option>
                          <option value="4">Deadline Missed</option>
                          <option value="5">Bank Transfer Initiated</option>
                          <option value="8" disabled>Paid in Full</option>
                          <option value="9">Finished</option>
                        </select>
                      </td>
                      <td>
                        <select class="form-control" v-model="remainingStatus">
                          <option value="0">Started</option>
                          <option value="1">Cancelled</option>
                          <option value="4">Deadline Missed</option>
                          <option value="5">Bank Transfer Initiated</option>
                          <option value="6">Reminder sent</option>
                          <option value="8" disabled>Paid in Full</option>
                          <option value="9">Finished</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="mt-5 float-right">
                  <button
                    @click="changeStatus"
                    class="btn btn-block btn-primary font-weight-bold text-uppercase"
                  >
                    Change status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark"
            >Customer details</span
          >
        </h3>
      </div>
      <div class="card-body pt-0 pb-3">
        <form class="form">
          <div class="row">
            <div class="col">
              <div>
                <div class="form-group row">
                  <label class="col-2">First Name</label>
                  <div class="col-10">
                    <input
                      v-model="reservation.personalDetail.name"
                      class="form-control form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-2">Last Name</label>
                  <div class="col-10">
                    <input
                      v-model="reservation.personalDetail.surname"
                      class="form-control form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-2">Email</label>
                  <div class="col-10">
                    <input
                      v-model="reservation.personalDetail.email"
                      class="form-control form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-2">Phone</label>
                  <div class="col-10">
                    <input
                      v-model="reservation.personalDetail.mobile"
                      class="form-control form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-2">Country</label>
                  <div class="col-10">
                    <input
                      v-model="reservation.personalDetail.country"
                      class="form-control form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-2">Note</label>
                  <div class="col-10">
                    <input
                      v-model="reservation.meta.specialRequest"
                      class="form-control form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="mt-5 float-right">
                  <button
                    @click.prevent="changeData"
                    class="btn btn-block btn-primary font-weight-bold text-uppercase"
                  >
                    Change Data
                  </button>
                </div>
              </div>
            </div>

            <!-- <div class="mt-5">
                {{ reservation }}
              </div> -->
          </div>
        </form>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark"
            >Edit reservations dates</span
          >
        </h3>
      </div>
      <div class="card-body pt-0 pb-3">
        <form class="form">
          <div class="row">
            <div class="col-12">
              <v-date-picker
                style="width: 100%;"
                ref="picker"
                v-model="range"
                :step="1"
                :masks="masks"
                :columns="$screens({ default: 3, lg: 4 })"
                is-range
                color="gray"
                :first-day-of-week="2"
                :min-date="new Date()"
                :attributes="days"
              />
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <button
                @click.prevent="changeDates"
                class="btn btn-block btn-primary font-weight-bold text-uppercase"
              >
                Change dates
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { addDays } from 'date-fns'

export default {
  components: {
    'v-date-picker': DatePicker
  },
  data() {
    return {
      downPaymentType: null,
      remainingType: null,
      downPaymentPaid: null,
      remainingPaid: null,
      downPaymentStatus: null,
      remainingStatus: null,
      reservation: null,
      isLoaded: false,
      range: {
        start: null,
        end: null
      },
      masks: {
        input: 'YYYY-MM-DD h:mm A'
      },
      days: []
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Reservations', route: '/reservations/list' },
      { title: 'Edit Reservation' }
    ])

    ApiService.get('reservations', this.$route.params.id).then(({ data }) => {
      this.reservation = data
      this.isLoaded = true
      this.downPaymentType = this.selectStatus(0)
      this.remainingType = this.selectStatus(1)
      this.downPaymentPaid = this.selectStatus(2)
      this.remainingPaid = this.selectStatus(3)
      this.downPaymentStatus = this.selectStatus(4)
      this.remainingStatus = this.selectStatus(5)

      ApiService.get(
        'villas',
        data.villa['@id'].replace('/api/v1/villas/', '')
      ).then(({ data }) => {
        this.days = []

        if (data.reservations.length) {
          data.reservations.forEach(reservation => {
            if (reservation['@id'] === this.reservation['@id']) {
              const dateFrom = addDays(new Date(reservation.dateFrom), +1)
              const dateTo = addDays(new Date(reservation.dateTo), -1)

              this.days.push({
                dates: {
                  start: dateFrom,
                  end: dateTo
                },
                highlight: {
                  color: 'blue',
                  fillMode: 'light'
                }
              })
            } else {
              const dateFrom = addDays(new Date(reservation.dateFrom), +1)
              const dateTo = addDays(new Date(reservation.dateTo), -1)

              this.days.push({
                dates: {
                  start: dateFrom,
                  end: dateTo
                },
                highlight: {
                  color: 'red',
                  fillMode: 'light',
                  class: 'li-reserved',
                  contentClass: 'li-content-reserved'
                }
              })
            }
          })
        }
      })
    })
  },
  methods: {
    selectStatus(part) {
      const splitStatus = this.reservation.status.split('')

      return splitStatus[part]
    },
    currencyFormat(num) {
      return num
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    changeStatus() {
      const status =
        this.downPaymentType +
        this.remainingType +
        this.downPaymentPaid +
        this.remainingPaid +
        this.downPaymentStatus +
        this.remainingStatus

      ApiService.update('reservations', this.$route.params.id, {
        status: status
      }).then(({ status }) => {
        if (status === 200) {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Edit success'
          })

          setTimeout(() => {
            this.$router.push({ name: 'reservations-list' })
          }, 500)
        }
      })
    },
    changeData() {
      ApiService.update('reservations', this.$route.params.id, {
        personalDetail: {
          name: this.reservation.personalDetail.name,
          surname: this.reservation.personalDetail.surname,
          email: this.reservation.personalDetail.email,
          mobile: this.reservation.personalDetail.mobile,
          country: this.reservation.personalDetail.country
        },
        meta: {
          guests: this.reservation.meta.guests,
          specialRequest: this.reservation.meta.specialRequest
        }
      }).then(({ status }) => {
        if (status === 200) {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Edit success'
          })

          setTimeout(() => {
            this.$router.push({ name: 'reservations-list' })
          }, 500)
        }
      })
    },
    changeDates() {
      ApiService.update('reservations', this.$route.params.id, {
        dateFrom: this.range.start,
        dateTo: this.range.end
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Success'
        })
        setTimeout(() => {
          this.$router.push({ name: 'reservations-list' })
        }, 500)
      })
    }
  }
}
</script>

<style>
.li-disable,
.li-content-disable {
  color: #cbd5e0;
  pointer-events: none;
}
.li-reserved,
.li-content-disable {
  pointer-events: none;
}
.li-reserved,
.li-content-reserved {
  pointer-events: none;
}
.vc-day-content.is-disabled {
  color: #cbd5e0 !important;
}
</style>
